import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormLabel,
    Flex,
    Button,
    Input,
    useToast,
  } from "@chakra-ui/react";
import uploadService from '../../../api/uploadService';
import { BeatLoader } from 'react-spinners';
import LoadingOverlay from '../../../reusable-components/LoadingOverlay/LoadingOverlay';

const RenameModal = (props) => {
    const { isOpen, onClose, selectedImage,handleReFetch } = props;
    const [reName, setReName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState("")
    const [fileExtension, setFileExtension] = useState("");
    const toast = useToast();
 
    useEffect(() => {
        if (selectedImage && selectedImage[0]?.original_image_name) {
            setReName(selectedImage[0].original_image_name);
            setId(selectedImage[0].id)
            const fullName = selectedImage[0].original_image_name;
            const nameWithoutExtension = fullName.replace(/\.[^/.]+$/, "");
            const extension = fullName.match(/\.[^/.]+$/)[0]; // Get the extension
            setReName(nameWithoutExtension);
            setFileExtension(extension);
        }
    }, [selectedImage]);

    const handleRename = (e) => {
       e.preventDefault();
       setIsLoading(true); 
       onClose()
    }

    const handleRenameImageName = async () => {
        try{
            setIsLoading(true);
            const updatedName = `${reName}${fileExtension}`;
            let payload = {
                id,
                original_image_name: updatedName
            }

            const response = await uploadService.reRameImgName(payload)
             if(response.status === 200){
                toast({
                    title: `${response.data.message}`,
                    position: "top",
                    status: "success",
                    duration: 3000,
                    variant: "left-accent",
                    isClosable: true,
                  })
                handleReFetch()
             }
        }catch(err){
          toast({
            title: `${err.response.data.detail}`,
            position: "top",
            status: "error",
            duration: 3000,
            variant: "left-accent",
            isClosable: true,
          })
            console.log("err:", err)
        }finally{
            setIsLoading(false);
            onClose()
        }
    }

    const handleCloseModal = () => {
        setReName("")
        onClose()
    }

    if(isLoading) {
        return <LoadingOverlay message="Updating..."/>
    }

  return (
    <div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Rename
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleRename}>
            <FormLabel>Name</FormLabel>
            <Input type="text" value={reName} onChange={(e) => setReName(e.target.value)}/>
            <Flex alignItems={"flex-end"} justifyContent={"flex-end"} gap={"10px"} mt={4}>
            <Button disabled={isLoading} onClick={handleRenameImageName} size={"sm"} id="btn" mr={3}>
              {isLoading ? <BeatLoader color='#FFFFFF' /> : "Submit"}
            </Button>
            <Button size={"sm"} variant={"outline"} colorScheme={"red"} onClick={handleCloseModal}>Cancel</Button>
            </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default RenameModal
