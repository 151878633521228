import React, { useContext, useEffect, useState } from "react";
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  FormControl,
  FormLabel,
  DrawerFooter,
  Stack,
  Input,
  Button,
  Text,
  useToast,
  RadioGroup,
  Radio,
  Box,
  Flex,
} from "@chakra-ui/react";
import taxonomyService from "../../../api/taxonomyService";
import { MultiSelect } from "react-multi-select-component";
import MultiSelectStyle from "../../../assets/styles/CreateDepartment.module.css";
import uploadService from "../../../api/uploadService";
import { useNavigate } from "react-router-dom";
import { interceptorsSetup } from "../../../api/intreceptor";
import { CentralizeStore } from "../../../contexts/CentralizeStoreProvider";
import TagSearch from "./TagSearch";

const Filter = (props) => {
  const { isFilterDrawerOpen, onFilterDrawerClose, setUploadedFile } = props;
  const btnRef = React.useRef();
  const [allDomain, setAllDomain] = useState([]);
  const [isLoadingDomain, setIsLoadingDomain] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterType, setFilterType] = useState("MetaData")
  const {IsFilteredExistTrue, setFilterState, filterState} = useContext(CentralizeStore)
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  useEffect(() => {
    getAllDomains();
  }, []);

  const getAllDomains = async () => {
    try {
      setIsLoadingDomain(true);
      const response = await taxonomyService.getDomain();
      setAllDomain(response.taxnonmies);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setIsLoadingDomain(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDomainChange = (selected) => {
    setFilterState((prevState) => ({
      ...prevState,
      selectedDomains: selected,
    }));
  };

  const handleScopeChange = (selected) => {
    setFilterState((prevState) => ({
      ...prevState,
      selectedScopes: selected,
    }));
  };

  const handleStatusChange = (selected) => {
    setFilterState((prevState) => ({
      ...prevState,
      selectedStatus: selected,
    }));
  };

  const applyFilter = async () => {
    const payload = {
      fromDate: filterState.fromDate
        ? new Date(filterState.fromDate).toISOString().split("T")[0]
        : "",
      toDate: filterState.toDate
        ? new Date(filterState.toDate).toISOString().split("T")[0]
        : "",
      domain: filterState.selectedDomains.map((domain) => domain.value),
      scope: filterState.selectedScopes.map((scope) => Number(scope.value)),
      status: filterState.selectedStatus.map((status) => Number(status.value)),
    };

    try {
      setIsFiltering(true);
      const filters = {
        email: localStorage.getItem("email"),
        filters: payload,
        role: localStorage.getItem("role")
      };
      const response = await uploadService.getUploadedImages(filters);
      setUploadedFile(response?.data?.image_data);
      IsFilteredExistTrue()
    } catch (err) {
      console.log("Error while filtering:", err);
    } finally {
      setIsFiltering(false);
    }
  }

  // useEffect(() => {
  //   applyFilter();
  // }, [page]);

  const handleApply = async (e) => {
    e.preventDefault();

    await applyFilter();

    // const payload = {
    //   fromDate: filterState.fromDate
    //     ? new Date(filterState.fromDate).toISOString().split("T")[0]
    //     : "",
    //   toDate: filterState.toDate
    //     ? new Date(filterState.toDate).toISOString().split("T")[0]
    //     : "",
    //   domain: filterState.selectedDomains.map((domain) => domain.value),
    //   scope: filterState.selectedScopes.map((scope) => Number(scope.value)),
    //   status: filterState.selectedStatus.map((status) => Number(status.value)),
    // };

    // try {
    //   setIsFiltering(true);
    //   const filters = {
    //     email: localStorage.getItem("email"),
    //     filters: payload,
    //     role: localStorage.getItem("role"),
    //     page : page,
    //     limit:limit
    //   };
    //   const response = await uploadService.getUploadedImages(filters);
    //   setUploadedFile(response?.data?.image_data);
    //   setTotalPages(Math.ceil(response.data.total_length / limit));
    //   IsFilteredExistTrue()
    // } catch (err) {
    //   console.log("Error while filtering:", err);
    // } finally {
    //   setIsFiltering(false);
    // }
  };

  const handleReset = async () => {
    setFilterState({
      fromDate: "",
      toDate: "",
      selectedDomains: [],
      selectedScopes: [],
      selectedStatus: [],
    });

    const payload = {
      fromDate: "",
      toDate: "",
      domain: [],
      scope: [],
      status: [],
    };

    try {
      const filters = {
        email: localStorage.getItem("email"),
        filters: payload,
        role: localStorage.getItem("role")
      };
      const response = await uploadService.getUploadedImages(filters);
      setUploadedFile(response?.data?.image_data);
    } catch (err) {
      console.log("Error while resetting filters:", err);
    }
    onFilterDrawerClose();
  };

  const domainOptions = allDomain?.map((domain) => ({
    label: domain.domain_name,
    value: domain.domain_name,
  }));

  return (
    <div>
      <Drawer isOpen={isFilterDrawerOpen} placement="left" onClose={onFilterDrawerClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"whitesmoke"} />
          <DrawerHeader id='drawer'>
            <Text m={"0px"}>Filters</Text>
          </DrawerHeader>
          <Flex padding={"5px 5px 5px 19px"}>
            <RadioGroup onChange={setFilterType} value={filterType}>
              <Stack direction="row" padding={"5px 0px"}>
                <span className="text-fw">Search : </span>
                <Radio value="TagSearch">TagSearch</Radio>
                <Radio value="MetaData">MetaData</Radio>
              </Stack>
            </RadioGroup>
            </Flex>

        {filterType === "MetaData" &&
          <DrawerBody>
            <form onSubmit={handleApply}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>From Date</FormLabel>
                  <Input
                    type="date"
                    name="fromDate"
                    value={filterState.fromDate}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>To Date</FormLabel>
                  <Input
                    type="date"
                    name="toDate"
                    value={filterState.toDate}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Domain</FormLabel>
                  <MultiSelect
                    options={domainOptions}
                    value={filterState.selectedDomains}
                    onChange={handleDomainChange}
                    labelledBy="Select Domain"
                    className={MultiSelectStyle.multi_selecet_input}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <MultiSelect
                    options={[
                      { label: "Tag Generated", value: "1" },
                      { label: "Tag Not Generated", value: "0" },
                      { label: "Tag Reviewed", value: "3" },
                      { label: "Tag Not Reviewed", value: "2" },
                      { label: "Favorite", value: "4" },
                      { label: "Not Favorite", value: "5" },
                    ]}
                    value={filterState.selectedStatus}
                    onChange={handleStatusChange}
                    labelledBy="Select Status"
                    className={MultiSelectStyle.multi_selecet_input}
                  />
                </FormControl>
              </Stack>
            </form>
            <Box display={"flex"} padding={"10px"} justifyContent={"flex-end"} gap={"7px"} width={"100%"} height={"auto"} position={"absolute"} right={0} bottom={"0"} backgroundColor={"#FFF"}>
            <Button size={"sm"} variant="outline" colorScheme={"red"} onClick={handleReset}>Reset</Button>
            <Button size={"sm"} isDisabled={isFiltering} colorScheme="blue" variant={"outline"} type="submit" onClick={handleApply}>
              {isFiltering ? "Loading..." : "Apply"}
            </Button>
            </Box>
         </DrawerBody>}
         {filterType === "TagSearch" && <DrawerBody><TagSearch setUploadedFile={setUploadedFile}/></DrawerBody>}
          {/* <DrawerBody>
            
            <form onSubmit={handleApply}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>From Date</FormLabel>
                  <Input
                    type="date"
                    name="fromDate"
                    value={filterState.fromDate}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>To Date</FormLabel>
                  <Input
                    type="date"
                    name="toDate"
                    value={filterState.toDate}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Domain</FormLabel>
                  <MultiSelect
                    options={domainOptions}
                    value={filterState.selectedDomains}
                    onChange={handleDomainChange}
                    labelledBy="Select Domain"
                    className={MultiSelectStyle.multi_selecet_input}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <MultiSelect
                    options={[
                      { label: "Tag Generated", value: "1" },
                      { label: "Tag Not Generated", value: "0" },
                      { label: "Tag Reviewed", value: "3" },
                      { label: "Tag Not Reviewed", value: "2" },
                    ]}
                    value={filterState.selectedStatus}
                    onChange={handleStatusChange}
                    labelledBy="Select Status"
                    className={MultiSelectStyle.multi_selecet_input}
                  />
                </FormControl>
              </Stack>
            </form>
          </DrawerBody>
          <DrawerFooter display={"flex"} alignItems={"flex-end"} gap={"7px"} width={"100%"} height={"auto"} position={"sticky"} left={"0"} bottom={"0"} backgroundColor={"#FFF"}>
            <Button size={"sm"} variant="outline" colorScheme={"red"} onClick={handleReset}>Reset</Button>
            <Button size={"sm"} isDisabled={isFiltering} colorScheme="blue" variant={"outline"} type="submit" onClick={handleApply}>
              {isFiltering ? "Loading..." : "Apply"}
            </Button>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
      
    </div>
  );
};

export default Filter;