import { axiosInstance } from "./intreceptor";

class searchService {
  tagSearch = (payload) => {
    return axiosInstance
      .post("/search/tag_search", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export default new searchService();
