import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "./ImageCarousel.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Box, Image } from "@chakra-ui/react";

function ImageCarousel(props) {
  const { allImages,setSelectedRadioButton, setCurrentImageIndex,currImg } = props;
  const sliderRef = useRef(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(currImg);
  const slidesToShow = Math.min(allImages.length, 3);

  const settings = {
    focusOnSelect: true,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 3,
    vertical: true,
    verticalSwiping: true,
  };

  const handleSelectCurrentImage = (index) => {
    setSelectedImageIndex(index);
    setCurrentImageIndex(index);
    setSelectedRadioButton("Model_Prediction");
    
  };

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    const sliderElement = sliderRef.current?.innerSlider?.list;
    if (sliderElement) {
      sliderElement.addEventListener("wheel", handleWheel);
      return () => sliderElement.removeEventListener("wheel", handleWheel);
    }
  }, []);

  useEffect(() => {
    setSelectedImageIndex(currImg);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currImg);
    }
  }, [currImg]);

  return (
    <div className="carousel-container" style={{ "--slides-to-show": slidesToShow } as React.CSSProperties}>
      <button className="paging-button prev-button" onClick={() => sliderRef.current.slickPrev()}>
        <IoIosArrowUp />
      </button>
      <div className="slider-wrapper">
        <Slider ref={sliderRef} {...settings}>
          {allImages.length > 0 &&
            allImages.map((el, i) => (
              <Box key={i} className="carouselImagesCon" onClick={() => handleSelectCurrentImage(i)}  border={selectedImageIndex === i ? "2px solid #FC0589" : "none"} filter={selectedImageIndex === i ? "none" : "grayscale(60%)"}>
                <Image src={el.processed_image_url} alt={el.original_image_name} />
              </Box>
            ))}
        </Slider>
      </div>
      <button className="paging-button next-button" onClick={() => sliderRef.current.slickNext()}>
        <IoIosArrowDown />
      </button>
    </div>
  );
}

export default ImageCarousel;
