import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Flex, Progress, Stack, Text } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import Styles from "./DropZone.module.css";
import { RiDragDropLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { IoSearch } from "react-icons/io5";
import UploadedImg from "./UploadedImg";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../../contexts/overlay/OverlayContextProvider";
import uploadService from "../../api/uploadService";
import { CentralizeStore } from "../../contexts/CentralizeStoreProvider";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import useCredit from "../../hooks/useCredit";

interface DropZoneProps {
  setIsDropZoneOpen: (open: boolean) => void;
  searchText: string,
  setSearchText: any,
  isSearchTextValid: boolean,
  uploadedImg: any,
  setUploadedImg: any;
  isEnterVal : boolean;
  setIsEnterVal : any;
}

const DropZone: React.FC<DropZoneProps> = (props) => {
  const { setIsDropZoneOpen,searchText,isSearchTextValid,setSearchText,uploadedImg, setUploadedImg, isEnterVal,setIsEnterVal} = props;
  // const [uploadedImgReview, setUploadedImgReview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(40);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const {toggleOverlay,handleSetBlurFalse} = useContext(OverlayContext)
  const {setSearchRes, setSearchedText, setUploadedImage,setIsTagSearch,setIsSearchLoading} = useContext(CentralizeStore)
  const navigate = useNavigate();
  const { setCreditBalance } = useCredit();

  const isSearchText = !isSearchTextValid;
  const isImgUploaded = !uploadedImg
  const isDisabled = isSearchText && isImgUploaded;

  useEffect(() => {
    if(searchText.length > 0 && isEnterVal) {
      handleSearch()
    }
  }, [searchText, isEnterVal])
  
  const handleRedirect = () => {
    setIsDropZoneOpen(false);
    navigate("/layout/search-result");
    toggleOverlay()
  };


  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setSelectedFiles(acceptedFiles);
    const reader = new FileReader();

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setUploadProgress(progress);
      }
    };

    reader.onloadstart = () => {
      setIsLoading(true);
      setUploadProgress(0);
    };

    reader.onload = () => {
      setUploadedImg(reader.result as string);
      setUploadFailed(false);
      setIsLoading(false);
    };

    reader.onerror = () => {
      setUploadFailed(true);
      setIsLoading(false);
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [], // Accept all image types
    },
    multiple: false,
    disabled: !!uploadedImg,
  });

  const handleSearch = async () => {
    setIsEnterVal(false);
    setSearchRes([])
    setIsTagSearch(false)
    const email = localStorage.getItem("email")
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('search_image', file);
    });
    formData.append('email', email);
    formData.append('search_text', searchText);
    
    if(uploadedImg == null) {
      setUploadedImage("")
    }

    try {
      setIsLoading(true);
      setIsSearchLoading(true)
      const response = await uploadService.searchImage(formData);
      if(response.data.data) {
      setSearchRes(response.data.data.search_result);
      setSearchedText(response.data.data.text_response)
      handleSetBlurFalse()
      setUploadedImage(uploadedImg)
      navigate("/layout/search-result")
      handleRedirect();
      setCreditBalance(response.data.balance)
    }

    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadFailed(true);
    } finally {
      setIsLoading(false);
      setIsSearchLoading(false)
      toggleOverlay()
      handleResetSearch();
      handleRedirect()
      handleDiscard()
      handleSetBlurFalse()
    }
  };

  const handleResetSearch = () => {
    // setUploadedImg(null);
    // setSearchText("")
  }

  const handleDiscard = () => {
    setUploadedImg(null);
  }

  if(isLoading) {
    return <LoadingOverlay message="Searching ..."/>
  }

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"space-evenly"}
      mt={{ sm: "20px", md: "30px", lg: "50px" }}
      padding={"5px"}
    >
      <Divider />
      <Text
        fontWeight={{ base: "600", md: "600" }}
        fontSize={{ base: "17px", md: "20px", lg: "24px" }}
      >
        Try Visual Search
      </Text>
      <Text
        fontWeight={{ sm: "100", md: "400" }}
        fontSize={{ sm: "13px", md: "15px", lg: "17px" }}
        color={"#828282"}
      >
        Search with a picture instead of text
      </Text>
      <div {...getRootProps()} className={Styles.dropZoneArea}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <Box>
            {isLoading ? (
              <Box>
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"5px"}
                  flexDir={"column"}
                >
                  <Flex
                    flexDir={"column"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    position={"relative"}
                  >
                    <Text
                      fontWeight={{ sm: "100", md: "400" }}
                      fontSize={{ sm: "13px", md: "15px", lg: "17px" }}
                      color={"#828282"}
                    >
                      Image loading....
                    </Text>
                    <Progress
                      colorScheme="gray"
                      width={{ base: "100%", md: "200px", lg: "310px" }}
                      borderRadius={"17px"}
                      size="md"
                      value={uploadProgress}
                      mt={"20px"}
                    />
                    <Stack position={"absolute"} top={0} right={0}>
                      <RxCross2 />
                    </Stack>
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Box>
                {uploadedImg ? (
                  <Flex
                    flexDir={"column"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <UploadedImg uploadedImg={uploadedImg} />
                  </Flex>
                ) : (
                  <Box>
                    <Flex
                      flexDir={"column"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      gap={{ base: "5px", sm: "7px", md: "10px" }}
                      px={{ base: "15px", sm: "18px", md: "20px", lg: "37px" }}
                    >
                      <Flex flexDir={{base:"column",md:"column",lg:"row"}} textAlign={"center"} alignItems={"center"} gap={{base:"1",md:"3",lg:"4"}}>
                        <RiDragDropLine className={Styles.Drag_icn} />{" "}
                        <Text m={"0px"} fontSize={{base:"12px",md:"12px",lg:"15px"}}>Drag one or more images here or</Text>
                      </Flex>
                      <Button size={{base:"sm",md:"sm",lg:"md"}}  id="btn">Browse</Button>
                    </Flex>
                    <Flex
                      flexDir={"column"}
                      gap={"10px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    ></Flex>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </div>
      <Flex alignItems={"center"} justifyContent={"center"} gap={"15px"}>
        {
            (uploadedImg && !isLoading) && <Button colorScheme={"red"} size={{sm:"sm",md:"md"}} variant={"outline"} onClick={handleDiscard}>Discard</Button>
        }
        <Button size={{sm:"sm",md:"md"}}  id = "btn" textAlign={"center"}  gap={"10px"} isDisabled={isDisabled || isLoading} onClick={handleSearch}
        >Search <IoSearch fontSize={"20px"} /></Button>
      </Flex>
    </Flex>
  );
};

export default DropZone;
