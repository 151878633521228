import React from 'react'
import { useNavigate,Navigate } from 'react-router-dom';

const ProtectedRoute = ({ redirectTo, children }) => {
    const accessToken = localStorage.getItem("token"); 
    // const accessTokenn = true;

    return !! accessToken ? <>{children}</> : <Navigate to={redirectTo} />;
};


export default ProtectedRoute