import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { RxCross1 } from "react-icons/rx";
import { NavLink, useNavigate } from "react-router-dom";
import creditmanagementService from "../../api/userService";
import userService from "../../api/userService";
import { interceptorsSetup } from "../../api/intreceptor";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

const Transaction = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionType, setTransactionType] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const {fetchConfigSetting} = useFetchConfigSetting()
  const navigate = useNavigate();
  const toast = useToast()

  const currentDate = new Date();
  const defaultToDate = currentDate.toISOString().slice(0, 10);

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  const firstDateOfMonth = (date = new Date()) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding leading zero if needed
    const day = '01'; // First day of the month

    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const defaultDate = firstDateOfMonth();

    setFromDate(defaultDate);
    setToDate(defaultToDate);

    const storedRole = localStorage.getItem("role");
    setRole(storedRole);
    if (storedRole === "User") {
      const storedEmail = localStorage.getItem("email");
      setEmail(storedEmail);
    } else if (storedRole === "superAdmin" || storedRole === "Admin") {
      getActiveUsers()
    }
    fetchConfigSetting()
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const defaultStartDate = firstDateOfMonth()
    const trans_info = { email: storedEmail, from_date: defaultStartDate, to_date: defaultToDate };
    if(trans_info.email.length >0 && trans_info.from_date.length > 0 && trans_info.to_date.length){
       getTransaction(trans_info)
    }
  }, [])

  useEffect(() => {
    if (transactionType === "Credit") {
      let creditData = transactionData.filter(
        (transaction) => transaction.transaction_type === 1
      );
      setFilteredData(creditData);
    } else if (transactionType === "Debit") {
      let debitData = transactionData.filter(
        (transaction) => transaction.transaction_type === 2
      );
      setFilteredData(debitData);
    } else {
      setFilteredData(transactionData);
    }
  }, [transactionType]);

  const getActiveUsers = async () => {
     try{
      setIsLoading(true);
      const response = await userService.getActiveUsers();
      setUsers(response)
     }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("err:", err)
     }finally{
      setIsLoading(false)
     }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetchTransactionData();
  };

  const fetchTransactionData = async () => {
    const trans_info = { email, from_date: fromDate, to_date: toDate };
    try {
      setIsLoading(true);
      const response = await creditmanagementService.transactionData(
        trans_info
      );
      if (response.status === 200) {
        let data = response.data.transaction_data;
        setTransactionData(data);
        setFilteredData(data);
      }
    } catch (err) {
      console.log("fetch transaction err:", err);
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
    } finally {
      setIsLoading(false);
    }
  };

  const getTransaction= async (trans_info) => {
    try {
      setIsLoading(true);
      const response = await creditmanagementService.transactionData(
        trans_info
      );
      if (response.status === 200) {
        let data = response.data.transaction_data;
        setTransactionData(data);
        setFilteredData(data);
      }
    } catch (err) {
      console.log("fetch transaction err:", err);
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(2);
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      name: "Service Type",
      selector: (row) => row.service_type,
      sortable: true,
    },
    {
      name: "Transaction Amount",
      selector: (row) => row.transaction_amount.toFixed(1),
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.balance.toFixed(1),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.transaction_on),
      sortable: true,
    },
  ];

  const tableStyles = {
    head: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#FFFFFF",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
      },
    },
  };

  const NoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <strong>No data found</strong>
    </div>
  );
  return (
    <div>
      <Flex
        padding={"0px 0px 3px 0px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"0.5px solid #BDBDBD"}
        mt={{ base: "8px", md: "12px", lg: "auto" }}
        mb={{ base: "6px", md: "9px", lg: "12px" }}
      >
        <h2>Transaction</h2>

        <NavLink to="/layout" className="crossBtn">
          <span><RxCross1/></span>
        </NavLink>
      </Flex>
      <Box>
        <form onSubmit={handleSubmit}>
          <Flex
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={"10px"}
          >
            <FormControl>
              <FormLabel>From Date</FormLabel>
              <Input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>To Date</FormLabel>
              <Input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FormControl>
            {(role === "superAdmin" || role === "Admin") && (
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Select
                  value={email || localStorage.getItem("email")}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                >
                  {users?.map((user) => (
                    <option key={user.email} value={user.email}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            {role === "user" && (
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input type="email" value={email} readOnly />
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Transaction Type</FormLabel>
              <Select
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Credit">Credit</option>
                <option value="Debit">Debit</option>
              </Select>
            </FormControl>
          </Flex>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            gap={{ base: "10px", md: "15px" }}
            mt={"1.5rem"}
            mb={"1.5rem"}
          >
            <Button
              type="submit"
              variant={"outline"}
              colorScheme={"blue"}
              size={"sm"}
            >
              {isLoading ? "Loading..." : "Apply"}
            </Button>
            <Button
              variant={"outline"}
              colorScheme={"red"}
              size={"sm"}
              onClick={() => {
                setFromDate(firstDateOfMonth());
                setToDate(defaultToDate);
                setTransactionType("All");
                setTransactionData([]);
                const storedEmail = localStorage.getItem("email");
                setEmail(storedEmail);
                const trans_info = { email: storedEmail, from_date: firstDateOfMonth(), to_date: defaultToDate }
                getTransaction(trans_info)
              }}
            >
              Reset
            </Button>
          </Flex>
        </form>
      </Box>
      {
        (isLoading) ? <Skeleton width={"100%"} height={"140px"}/> :
      <DataTable
        persistTableHead={true}
        columns={columns}
        data={filteredData}
        noDataComponent={<NoDataComponent />}
        customStyles={tableStyles}
        pagination
      />
    }
    </div>
  );
};

export default Transaction;
