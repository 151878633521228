import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  Box,
  FormLabel,
  Input,
  Flex,
  useToast,
} from "@chakra-ui/react";
import departmentService from "../../../api/departmentService";

const DepartmentForm = (props) => {
  const {
    isOpen,
    onClose,
    updateUserObj,
    isCreateDepartment,
    setIsCreateDepartment,
    getDepartsments,
    allDept,
    closeModal
  } = props;
  const [departmentName, setDepartmentName] = useState("");
  const [dapartmentCode, setDepartmentCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  let user_info;
  const createDepartmentForm = async (e) => {
    e.preventDefault();
     user_info = {
      department_name: departmentName,
      department_code: dapartmentCode,
    };
   
  };
  
  useEffect(() => {
    if (updateUserObj) {
      setDepartmentName(updateUserObj.department_name);
      setDepartmentCode(updateUserObj.department_code);
    }
  }, [updateUserObj]);

  const deptnameExists = allDept?.some(dept => dept?.department_name.toLowerCase() === departmentName.toLowerCase());
  const deptcodeExists = allDept?.some(dept => dept?.department_code == dapartmentCode);


  const createDepartment = async () => {
    if (deptnameExists) {
      return(
        toast({
          title: `Department Name already exists.`,
          position: "top",
          status: "warning",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      )
    } else if (deptcodeExists) {
      return(
        toast({
          title: `Department Code already exists.`,
          position: "top",
          status: "warning",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      );
    }

    if(!(departmentName.length > 0 && dapartmentCode.length > 0)){
      return(
        toast({
          title: `Required fields are mandatory.`,
          position: "top",
          status: "warning",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      )
    }

    try {
      setIsLoading(true);
      const response = await departmentService.createDepartment({
        department_name: departmentName,
        department_code: dapartmentCode,
      });
      if (response.status === 200) {
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        });
        getDepartsments()
        handleCloseModal()
      }
    } catch (err) {
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("creating department err: ", err);
    } finally {
      setIsLoading(false);
      onClose();
      setDepartmentCode("");
      setDepartmentName("");
    }
  }

  const updateDepartment = async() => {
    if (deptnameExists && deptcodeExists) {
      return(
        toast({
          title: `Department Name and Code already exists.`,
          position: "top",
          status: "warning",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      )
    }
    try{
      setIsLoading(true)
      const depart_info = {
        dept_id:updateUserObj.id,
        department_name: departmentName,
        department_code: dapartmentCode,
      }
       const res = await departmentService.updateDepartment(depart_info)
       if(res.status === 200){
        toast({
          title: `${res.data.new.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        });
        handleCloseModal()
        getDepartsments()
       }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("update depart err: ", err)
    }finally{
      setIsLoading(false)
    }
  }

  const handleCloseModal = () => {
    onClose();
    closeModal()
    setDepartmentName("");
    setDepartmentCode("");
    setIsCreateDepartment(null);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        size={{ base: "md", md: "md", lg: "xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Department</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={createDepartmentForm}>
              <Grid
                gridTemplateColumns={{
                  base: "repeat(1,1fr)",
                  md: "repeat(2,1fr)",
                }}
                gap={{ base: "6px", md: "8px", lg: "9px" }}
              >
                <Box>
                  <FormLabel>Department Name {isCreateDepartment ? <span style={{color:'red'}}>*</span>:null}</FormLabel>
                  <Input
                    type="text"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <FormLabel>Department Code {isCreateDepartment ? <span style={{color:'red'}}>*</span>:null}</FormLabel>
                  <Input
                    type="text"
                    value={dapartmentCode}
                    onChange={(e) => setDepartmentCode(e.target.value)}
                    required
                  />
                </Box>
              </Grid>

              <Flex
                mt={"20px"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={"15px"}
              >
                {isCreateDepartment ? (
                  <Button
                    disabled={isLoading}
                    size={"sm"}
                    id="btn"
                    onClick={createDepartment}
                  >
                    {isLoading ? "Creating..." : "Submit"}
                  </Button>
                ) : (
                  <Button
                    disabled={isLoading}
                    size={"sm"}
                    id="btn"
                    onClick={updateDepartment}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </Button>
                )}
                <Button size={"sm"} variant={"outline"} colorScheme={"red"} onClick={handleCloseModal}>Close</Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DepartmentForm;
