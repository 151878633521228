import React, { useCallback, useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import debounce from "lodash.debounce";
import { User } from "../../types";
import useCredit from "../../hooks/useCredit";
import { roleTypes } from "../../constants";
import usercreditsService from "../../api/userService";
import Styles from '../../assets/styles/CreateDepartment.module.css'
import "./UserForm.css";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { interceptorsSetup } from "../../api/intreceptor";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import MultiSelectStyle from '../../assets/styles/CreateDepartment.module.css'
import CreditContext from "../../contexts/creditProvider/creditContext";

const UserForm = ({
  isCreateUser,
  isUpdateUser,
  userObj,
  modalHeader,
  closeModal,
  fetchUsers,
  useCreditPool,
  creditPoolBalance,
  setCreditPoolBalance,
  setToastMessage,
  setIsFeedActive,
  SetUserAfterUpdate,
  updatedUsersData,
  activeDepartments,
  totalAdmins,
}) => {
  const btnText = isCreateUser ? "Create" : "Update";
  const [currentUser, setCurrentUser] = useState<User>(userObj);
  const [credits, setCredits] = useState<number>();
  const [username, setUsername] = useState(userObj?.name || "");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [creditAction, setCreditAction] = useState("assign");
  const [enableUpdateUser, setEnableUpdateUser] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(btnText);
  const [loading, setLoading] = useState(false);
  const [isEmailExits, setIsEmailExits] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [allDeparts, setAllDepart] = useState([]);
  const [assignDepart, setAssignDepart] = useState([]);
  const [selected, setSelected] = useState([]);
  const {isCrditBalanceLoad} = useContext(CreditContext)
  const [passwordHide, setPasswordHide] = useState(false)
  const [isValidCredit, setIsValidCredit] = useState(false);
  // const [loaderMessage, setLoaderMessage] = useState("Updating Users...")

  const { creditBalance, setCreditBalance } = useCredit();

  const handleCancel = () => {
    closeModal();
  };

  const departmentOptions = activeDepartments.map(department => ({
    label: `${department.department_name} (${department.department_code})`,
    value: department.id
  }));

  // Handle selection change
  const handleSelectionChange = (selectedOptions) => {
    setSelected(selectedOptions);
  };

  // Get the selected IDs
  const departments_ids = selected.map(option => option.value);



  // console.log("multi select data;", activeDepartments)

  const changeCreditAction = (event) => {
    setCreditAction(event.target.value);
  };

  // Validating credit assigning and revoking
  const validateCreditAction = () => {
    let enable = true;
    if (
      creditAction === "assign" &&
      useCreditPool &&
      credits > creditPoolBalance
    ) {
      setError(
        `Credits assigned exceeds credit pool balance ${creditPoolBalance}`
      );
      setIsValidCredit(true)
      enable = false;
    }
    if (creditAction === "revoke" && credits > userObj?.credit_balance) {
      setError("Not enough balance to revoke");
      enable = false;
      setIsValidCredit(true)
    }
    return enable;
  };

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // validating update user form
  useEffect(() => {
    if (credits < 0) {
      setError("Credits cannot be less than 0");
      // setCredits(0);
      setEnableUpdateUser(false);
    } else if (
      credits == 0 &&
      (userObj?.role !== currentUser?.role || password)
    ) {
      setEnableUpdateUser(true);
      setError("");
    } else if (credits == 0 && userObj?.role === currentUser?.role) {
      setEnableUpdateUser(false);
    } else if (credits > 0) {
      if (validateCreditAction()) {
        setEnableUpdateUser(true);
        setError(" ");
      } else {
        setEnableUpdateUser(false);
      }
    }

    if (isCreateUser) {
      if (!username) {
        // setError("Username is required");
        setEnableUpdateUser(false);
      } else {
        setError("");
        if (!isValidEmail(email)) {
          // setError("Invalid email");
          setEnableUpdateUser(false);
        } else {
          setError("");
          setEnableUpdateUser(true);
          if (!role) {
            // setError("Role is mandatory");
            setEnableUpdateUser(false);
          }
        }
      }
    }
  }, [
    currentUser,
    credits,
    creditAction,
    userObj,
    isCreateUser,
    username,
    email,
    role,
  ]);

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  useEffect(() => {
    const preSelectedOptions = departmentOptions?.filter(option =>
      userObj?.department_ids?.includes(option.value)
    );
    setSelected(preSelectedOptions || []);
  }, []);

  useEffect(() => {
    if (totalAdmins === 1 && role === "User") {
      setError("Only one Admin left. Are you sure?");
    } else {
      setError("");
    }
  }, [role, totalAdmins]);

  const updateUser = () => {
    if(isValidCredit){
      return (
        toast({
          title: `${error}`,
          position: "top",
          status: "warning",
          duration: 2000,
          variant: "left-accent",
          isClosable: true,
        })
      )
      
    }
    if(!(departments_ids.length > 0)){
     return(
      toast({
        title: `Department should not empty.`,
        position: "top",
        status: "warning",
        duration: 2000,
        variant: "left-accent",
        isClosable: true,
      })
     )
    }
    const options = {
      email: currentUser.email, // Include email field
      name: username,
      // id: currentUser.id || null,
      role: currentUser.role,
      password: password || null,
      // is_new_user: isCreateUser,
      transaction_amount: Number(credits),
      department_ids:departments_ids
    };
    if (creditAction === "assign") {
      // options["CreditAssigned"] = Number(credits);
      options["transaction_type"] = 1; // transaction_type = 1 for credit assign
    } else {
      // options["Credit_Revoked"] = Number(credits);
      options["transaction_type"] = 2; // transaction_type = 2 for credit revoke
    }

    const user_info = JSON.stringify(options);

    setLoading(true);
    setSubmitButtonText(isCreateUser ? "Creating..." : "Updating...");
    setEnableUpdateUser(false);
    trackPromise(
      usercreditsService
        .updateUser(user_info)
        .then((response) => {
          if (response.status == 200) {
            const loggedEmail = localStorage.getItem("email");
            if(loggedEmail === response.data.new.email){
              setCreditBalance(response.data.new.balance)
            }
            const { users } = response.data;
            setCreditPoolBalance(response.data.config.credit_pool_balance)
            toast({
              title: `${response.data.new.message}`,
              description: `${response.data.new.email}`,
              position: "top",
              status: "success",
              duration: 2000,
              variant: "left-accent",
              isClosable: true,
            });
            SetUserAfterUpdate(response.data.users);
            setSubmitButtonText(isCreateUser ? "Create" : "Update");
            setEnableUpdateUser(true);
          }

          setLoading(false);
          closeModal();
        })
        .catch((err) => {
          const { error } = err.response.data;
          setSubmitButtonText(isCreateUser ? "Create" : "Update");
          setEnableUpdateUser(true);
          setError(error);
          setLoading(false);
          setToastMessage("danger", error || "An error occured");
        })
    );
  };

  const createUser = async () => {
    const options = {
      email: email,
      name: username,
      password: password,
      role: role,
      credit_assigned: credits,
      department_ids:departments_ids
    };
    if (
      !(
        username.length > 0 &&
        email.length > 0 &&
        password.length > 0 &&
        role.length > 0 &&
        departments_ids.length > 0
      )
    ) {
      return toast({
        title: "Required fields are mandatory.",
        position: "top",
        status: "warning",
        duration: 2000,
        variant: "left-accent",
        isClosable: true,
      });
    }
    if(isEmailExits.length > 0){
      return(
        toast({
              title: "Email is already exits.",
              position: "top",
              status: "warning",
              duration: 2000,
              variant: "left-accent",
              isClosable: true,
            })
      )
    }
    const user_info = JSON.stringify(options);
    setSubmitButtonText(isCreateUser ? "Creating..." : "Updating...");

    try {
      setEnableUpdateUser(false);
      setLoading(true);
      const response = await usercreditsService.createUser(user_info);

      if (response.status == 200) {
        const { users } = response.data;
        toast({
          title: `${response?.data.new.message}`,
          description: `${response?.data.new.email}`,
          position: "top",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          isClosable: true,
        });
        SetUserAfterUpdate(response.data.users);
      }
    } catch (err) {
      console.log("creating user err:", err);
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "warning",
        duration: 2000,
        variant: "left-accent",
        isClosable: true,
      });
      setEnableUpdateUser(true);
      setIsFeedActive(true);
    } finally {
      setLoading(false);
      setSubmitButtonText(isCreateUser ? "Create" : "Update");
      setEnableUpdateUser(true);
      closeModal();
    }
  };

  const setUserNameValue = (name) => {
    setUsername(name);
    setCurrentUser((prevUser) => ({
      ...prevUser,
      username: name,
    }));
  };

  const checkEmailExists = (email) => {
    return updatedUsersData.some((user) => user.email === email);
  };

  const debouncedCheckEmail = useCallback(
    debounce((email) => {
      if (checkEmailExists(email)) {
        setIsEmailExits("Email is already exists.");
      } else {
        setIsEmailExits("");
      }
    }, 1000),
    [updatedUsersData]
  );


  const setUserEmailValue = (email) => {
    debouncedCheckEmail(email);
    setEmail(email);
    // setCurrentUser((prevUser) => ({
    //   ...prevUser,
    //   email: email,
    // }));
  };

  const setUserPasswordValue = (pwd) => {
    setPassword(pwd);
    setCurrentUser((prevUser) => ({
      ...prevUser,
      password: pwd,
    }));
  };

  const setUserRole = (e) => {
    setRole(e.target.value);
    setCurrentUser((prevUser) => ({
      ...prevUser,
      role: e.target.value,
    }));
  };

  const setDepartment = () => {};

  return (
    <div>
      <Modal
        size="lg"
        show={isCreateUser || isUpdateUser}
        onHide={closeModal}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-header-text"
            id="example-modal-sizes-title-lg ps-10"
          >
            {isCreateUser ? "Create User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid
            gridTemplateColumns={{ base: "repeat(1,1fr)", md: "repeat(2,1fr)" }}
            gap={{ base: "5px", md: "8px", lg: "10px" }}
          >
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Name</Form.Label> {isCreateUser ? <span style={{color:"red"}}>*</span> : null} 
              <Form.Control
                className="user-input"
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUserNameValue(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Email</Form.Label>{isCreateUser ? <span style={{color:"red"}}>*</span> : null}
              {isCreateUser && (
                <Form.Control
                  className="user-input"
                  type="text"
                  name="email"
                  value={email || currentUser?.email}
                  onChange={(e) => setUserEmailValue(e.target.value)}
                  required
                />
              )}
              {isUpdateUser && (
                <Form.Control
                  className="user-input"
                  type="text"
                  name="email"
                  value={currentUser?.email}
                  disabled
                />
              )}
              {isCreateUser && (
                <Text color={"red"} mb={0} mt={"4px"} fontSize={"11px"}>
                  {isEmailExits}
                </Text>
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Password</Form.Label>{isCreateUser ? <span style={{color:"red"}}>*</span> : null}
              <Form.Control
                className="user-input"
                type={"password"}
                name="password"
                onChange={(e) => setUserPasswordValue(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Role</Form.Label>{isCreateUser ? <span style={{color:"red"}}>*</span> : null}
              <Form.Select value={currentUser?.role} onChange={setUserRole}>
                <option value="">Select an option</option>
                {roleTypes.map((ele) => (
                  <option key={ele.name} value={ele.name}>
                    {ele.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Credits</Form.Label>{isCreateUser ? <span style={{color:"red"}}>*</span> : null}
              <Form.Control
                className="user-input"
                type="number"
                name="credit_revoked"
                min="0"
                value={credits}
                onChange={(e) => setCredits(Number(e.target.value))}
                required
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="user-label">Department</Form.Label><span style={{color:"red"}}>*</span>
             <MultiSelect
              options={departmentOptions}
              value={selected}
              onChange={handleSelectionChange}
              labelledBy="Select Departments"
              className={MultiSelectStyle.multi_selecet_input}
             />
            </Form.Group>
          </Grid>
          <div className="row">
            <label
              className="p-0"
              style={{ width: "70px", marginLeft: "10px" }}
            >
              <input
                style={{ cursor: "pointer", marginRight: "2px" }}
                type="radio"
                value="assign"
                checked={creditAction === "assign"}
                onChange={changeCreditAction}
              />
              Assign
            </label>
            <label className="p-0" style={{ width: "70px" }}>
              <input
                style={{ cursor: "pointer", marginRight: "2px" }}
                type="radio"
                value="revoke"
                disabled={isCreateUser}
                checked={creditAction === "revoke"}
                onChange={changeCreditAction}
              />
              Revoke
            </label>
          </div>
          <div className="errorText">{error ? error : ""}</div>
        </Modal.Body>
        <Modal.Footer>
          <Flex alignItems={"center"} justifyContent={"flex-end"} gap={"15px"}>
            {isCreateUser ? (
              <Button
                id="btn"
                size={"sm"}
                disabled={loading || isEmailExits.length > 0}
                onClick={createUser}
              >
                {submitButtonText}
              </Button>
            ) : (
              <Button
                id="btn"
                size={"sm"}
                disabled={loading}
                onClick={updateUser}
              >
                {submitButtonText}
              </Button>
            )}
            <Button
              onClick={handleCancel}
              size={"sm"}
              colorScheme={"red"}
              variant={"outline"}
            >
              Close
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserForm;
