import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class generateTag {

  generateImageTag = (payload) => {
    return axiosInstance
      .post("/upload/generate_image_tag",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

}

export default new generateTag();
