import React, { useRef, useState } from "react";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import Styles from "../../reusable-components/ImageDrop/DropZone.module.css";
import SwitchStyle from "./ToggleSwitch.module.css";
import { GoFileDirectory } from "react-icons/go";
import CaptureImage from "./CaptureImage";
import UploadZip from "./UploadZip";
import uploadService from "../../api/uploadService";
import { BarLoader, BeatLoader } from "react-spinners";
import useCredit from "../../hooks/useCredit";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";

const UploadModal = (props) => {
	const { isOpen, onOpen, onClose, modalContent, handleReFetch } = props;
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isPublic, setIsPublic] = useState(false);
	const fileInputRef = useRef(null);
	const email = localStorage.getItem("email");
	const [isLoading, setIsLoading] = useState(false);
	const { setCreditBalance } = useCredit();
	const [webScrappingUrl, setWebscrapingUrl] = useState([]);
	const toast = useToast();
	const [maxNumberImages, setMaxNumberImages] = useState(null);
	const [customImageNumber, setCustomImageNumber] = useState(0);

	const maxImages = [5, 10, 'custom']

	const handleFileChange = (e) => {
		const filesArray = Array.from(e.target.files);
		setSelectedFiles(filesArray);
	};

	const handleCustomNumberChange = (value) => {
		setCustomImageNumber(value);
	};

	const handleScrapeUrlChange = (value) => {
		setWebscrapingUrl(value)
	};

  const setMaxImages = (e) => {
	setMaxNumberImages(e.target.value)
  };

	const handlePrivacyChange = (e) => {
		setIsPublic(e.target.checked);
	};

	const handleFileUpload = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true); // Set loading state to true
		const formData = new FormData();
		selectedFiles.forEach(file => {
			formData.append('files', file);
		});
		// formData.append('scope', isPublic ? '1' : '0');
		formData.append('scope', '0');
		formData.append('email', email);

	try {
	  setIsLoading(true);
	  const response = await uploadService.uploadImage(formData);
	  if(response.status === 200){
		toast({
		  title: `${response.data.message}`,
		  position: "top",
		  status: "success",
		  duration: 3000,
		  variant: "left-accent",
		  isClosable: true,
		})
		setCreditBalance(response.data.balance)
		handleReFetch()
	  }
	 	setSelectedFiles([]);
		onClose();
		} catch (err) {
			console.log("Upload error:", err);
			toast({
				title: `${err?.response?.data?.detail}`,
				position: "top",
				status: "info",
				duration: 3000,
				variant: "left-accent",
				isClosable: true,
			  })
		} finally {
			setIsLoading(false);
		}
	};

	const handleWebScrapeUpload = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		let max_images = 0
		max_images = maxNumberImages === 'custom' ? customImageNumber : maxNumberImages;
		let payloadObj = {
			url: webScrappingUrl,
			scrape_images_no: max_images,
			email: email,
			// scope: isPublic ? '1' : '0',
			scope: '0'
		}
		JSON.stringify(payloadObj)

		try {
		setIsLoading(true);
		const response = await uploadService.uploadWebScraping(payloadObj);
		if(response.status === 200){
			toast({
			title: `${response.data.message}`,
			position: "top",
			status: "success",
			duration: 3000,
			variant: "left-accent",
			isClosable: true,
			})

				setCreditBalance(response.data.balance)
				handleReFetch()
			}
			if (response.status === 201) {
				toast({
					title: "Invalid URL",
					position: "top",
					status: "warning",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				})
			}
			if (response.status === 202) {
				toast({
					title: `${response.data.message}`,
					position: "top",
					status: "error",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				})
			}
			setSelectedFiles([]);

			onClose();
		} catch (err) {
			console.log("Upload error:", err);
		} finally {
			setIsLoading(false);
		}
	};

	const closeUploadModal = () => {
		setSelectedFiles([])
		onClose()
	}

	const totalSelectedFilesCount = selectedFiles.length;
	const selectedFilesNames = selectedFiles.map(file => file.name).join(', ');

	if (isLoading) {
		return <LoadingOverlay message="Uploading..." />
	}

	const renderContent = () => {
		switch (modalContent) {
			case "fileUpload":
				return (
					<>
						<form onSubmit={handleFileUpload} className={SwitchStyle.uploadFormCon}>
							<Flex
								flexDir="column"
								alignItems="self-start"
								justifyContent="flex-start"
							>
								<div style={{ width: "100%" }} className={Styles.dropZoneArea} onClick={() => fileInputRef.current.click()}>
									<input
										type="file"
										accept=".jpg, .jpeg, .png, .webp"
										multiple
										onChange={handleFileChange}
										ref={fileInputRef}
										style={{ display: "none" }}
										required
									/>
									<Flex alignItems={"center"} gap={"5px"} color={"#828282"}>
										<GoFileDirectory />
										<span>Browse File</span>
									</Flex>
								</div>
								{
									totalSelectedFilesCount > 0 && <Text fontWeight="500" color="#4F4F4F">
										File selected {totalSelectedFilesCount} : {selectedFilesNames}
									</Text>
								}
								{/* <Flex w={"100%"} alignItems="center" justifyContent="center">
									<span>Private</span>
									<div className={SwitchStyle.checkbox_apple}>
										<input
											className={SwitchStyle.yep}
											id="check-apple"
											type="checkbox"
											checked={isPublic}
											onChange={handlePrivacyChange}
										/>
										<label htmlFor="check-apple"></label>
									</div>
									<span>Public</span>
								</Flex> */}
							</Flex>
							<Flex mt={8} w={"100%"} alignItems={"end"} justifyContent={"flex-end"} gap={{ base: "10px", md: "15px" }}>
								<Button disabled={isLoading} type="submit" id="btn" size={"sm"}>
									{isLoading ? <BeatLoader color="#FFFFFF" /> : "Upload"}
								</Button>
								<Button colorScheme="red" variant={"outline"} size={"sm"} onClick={closeUploadModal}>
									Cancel
								</Button>
							</Flex>
						</form>
					</>
				);
			case "camera":
				return <CaptureImage />
			case "zipUpload":
				// return <input type="file" accept=".zip" multiple />;
				return <UploadZip isOpen={isOpen} onOpen={onOpen} onClose={onClose} handleReFetch={handleReFetch} />
			case "webScraping":
				return (
					<>
						<form onSubmit={handleWebScrapeUpload} className={SwitchStyle.uploadFormCon}>
							<Flex
								flexDir="column"
								alignItems="self-start"
								justifyContent="flex-start"
							>
								<div style={{ width: "80%", marginBottom: '8px', fontSize: '12px', fontWeight: 'bold' }}>Web Scraping URL</div>
								<div style={{ width: "80%", marginBottom: '8px' }}>
									<input
										type="text"
										className="form-control"
										value={webScrappingUrl}
										onChange={(e) =>
											handleScrapeUrlChange(e.target.value)
										}
										required
									/>
								</div>
								<div style={{ width: "80%", marginBottom: '8px', fontSize: '12px', fontWeight: 'bold' }}>Number of images</div>
								<div style={{ width: "80%", marginBottom: '15px' }}>
									<Form.Group controlId="exampleForm.ControlInput1">
										{/* <Form.Label className="user-label">Role</Form.Label> */}
										<Form.Select value={maxNumberImages} onChange={setMaxImages}>
											<option value="">Select an option</option>
											{maxImages.map((ele) => (
												<option key={ele} value={ele}>
													{ele}
												</option>
											))}
										</Form.Select>
									</Form.Group>
								</div>
								{maxNumberImages === 'custom' &&
									<div style={{ width: "100%" }}>
										<div style={{ width: "80%", marginBottom: '8px', fontSize: '12px', fontWeight: 'bold' }}>Custom Number</div>
										<div style={{ width: "80%", marginBottom: '8px' }}>
											<input
												type="text"
												className="form-control"
												value={customImageNumber}
												onChange={(e) =>
													handleCustomNumberChange(e.target.value)
												}
											/>
										</div>
									</div>
								}
								<div style={{ width: "100%", marginBottom: '15px', color: '#C53030', fontSize: '12px', fontWeight: '500' }}>
									* Balance shall be deducted for the number of images selected
								</div>

								{/* <Flex w={"100%"} alignItems="center" justifyContent="center">
									<span>Private</span>
									<div className={SwitchStyle.checkbox_apple}>
										<input
											className={SwitchStyle.yep}
											id="check-apple"
											type="checkbox"
											checked={isPublic}
											onChange={handlePrivacyChange}
										/>
										<label htmlFor="check-apple"></label>
									</div>
									<span>Public</span>
								</Flex> */}
							</Flex>
							<Flex mt={8} w={"100%"} alignItems={"end"} justifyContent={"flex-end"} gap={{ base: "10px", md: "15px" }}>
								<Button disabled={isLoading} type="submit" colorScheme="blue" size={"sm"}>
									{isLoading ? <BarLoader color="#FFFFFF" /> : "Upload"}
								</Button>
								<Button colorScheme="red" variant={"outline"} size={"sm"} onClick={onClose}>
									Cancel
								</Button>
							</Flex>
						</form>
					</>
				);
			default:
				return null;
		}
	};
	return (
		<>
			<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{modalContent === "fileUpload" && "File Upload"}
						{modalContent === "camera" && "Camera"}
						{modalContent === "zipUpload" && "Zip Upload"}
						{modalContent === "webScraping" && "Web Scraping"}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>{renderContent()}</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default UploadModal;
