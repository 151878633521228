import { Button, Flex, Skeleton } from '@chakra-ui/react';
import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';

const CaptureImage: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isCaptured, setIsCaptured] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImageSrc(imageSrc || null);
    setIsCaptured(true);
  }, [webcamRef]);

  const retake = () => {
    setImageSrc(null);
    setIsCaptured(false);
    setIsLoading(true)
  };

  const upload = () => {
    if (imageSrc) {
      const formData = new FormData();
      formData.append('file', imageSrc);
      
      // Replace with your upload URL
      const uploadUrl = 'api/upload';
      
      fetch(uploadUrl, {
        method: 'POST',
        body: formData,
      })
      .then(response => response.json())
      .then(data => {
        console.log('Upload successful:', data);
      })
      .catch(error => {
        console.error('Error uploading:', error);
      });
    }
  };

  const handleUserMedia = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <Skeleton width={"100%"} height="170px"></Skeleton>}
      {!isCaptured ? (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={640}
          height={480}
          onUserMedia={handleUserMedia}
        />
      ) : (
        <img src={imageSrc} alt="Captured" />
      )}
      <Flex alignItems={"center"} justifyContent={"center"} gap={"5px"} mt={"10px"}>
        {!isCaptured ? (
          <Button size={"sm"} variant="outline" colorScheme={"blue"} onClick={capture}>Capture</Button>
        ) : (
          <>
            <Button size={"sm"} variant="outline" colorScheme={"red"} onClick={retake}>Retake</Button>
            <Button size={"sm"} colorScheme={"blue"} onClick={upload}>Upload</Button>
          </>
        )}
      </Flex>
    </div>
  );
};

export default CaptureImage;
