import { Tooltip, Button, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CustomButton = ({ label, onClick, icon, children, ...props }) => {
  return (
    <Tooltip label={label} display={{ base: "block", md: "none" }}>
      <Button display={"flex"} alignItems="center" justifyContent={"center"} flexDir={{ md: "column", lg: "row" }} onClick={onClick} gap={"7px"} colorScheme="black.500" variant="ghost" {...props}>
        {icon} <Box mb={0} display={{ base: "none", md: "block" }}>{children}</Box>
      </Button>
    </Tooltip>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomButton;
