import React, { useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, BrowserRouter } from 'react-router-dom';

const IdleTimer = () => {
	const navigate = useNavigate();
	const idleTimerRef = useRef(null);

	// Check if the user is logged in by verifying the presence of a local key
	const isLoggedIn = localStorage.getItem('status'); // or another key that indicates login

	const onIdle = () => {
		if (isLoggedIn) {
			sessionStorage.setItem('sessionExpired', 'true');
			// Optionally, manually clear other session items except sessionExpired
			Object.keys(sessionStorage).forEach((key) => {
				if (key !== 'sessionExpired') {
					sessionStorage.removeItem(key);
				}
			});
			localStorage.clear();
			navigate('/');
			window.location.reload(); // Force a reload after navigating to ensure the login page is displayed
		}
	};
	useIdleTimer({
		ref: idleTimerRef,
		timeout: 1000 * 60 * 15, // 15 minute
		onIdle: onIdle,
		debounce: 500,
	});

	return (
		<div>

		</div>
	);
};

export default function IdleTimerWrapper() {
	return (
		<BrowserRouter>
			<IdleTimer />
		</BrowserRouter>
	);
}
