import { axiosInstance } from "./intreceptor";

class contactService {

  userFeedbackSupport = (payload) => {
    return axiosInstance
      .post("/user/user_feedback_support", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

}

export default new contactService();
