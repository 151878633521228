import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class configService {
  updateConfig = (payload) => {
    return axiosInstance
      .post("/config/updateconfig", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  getConfigData = () => {
    return axiosInstance
      .get("/config/getconfigs")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  getConfigSetting = () => {
    return axiosInstance
      .get("/config/get_config_features_data")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };

}

export default new configService();
