import { Box, Button, Flex, Grid, IconButton, Input, Stack, Switch, Text, useToast } from "@chakra-ui/react";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import React, { useState, ChangeEvent, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import configService from "../../api/configService";
import { interceptorsSetup } from "../../api/intreceptor";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

interface ConfigItem {
	id: string;
	[key: string]: any;
}

const Configuration: React.FC = () => {
	const [configData, setConfigData] = useState([]);
	const [editId, setEditId] = useState<string | null>(null);
	const [tempData, setTempData] = useState<ConfigItem | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [usedUserLicense, setUsedUserLicense] = useState<number | null>(null);
	const [usedCreditPool, setUsedCreditPool] = useState<number | null>(null);
	const [warningMsg, setWarningMsg] = useState("")
	const [isWarning, setIsWaring] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false);
	const { fetchConfigSetting, configurationSetting } = useFetchConfigSetting()


	const navigate = useNavigate();
	const toast = useToast();

	useEffect(() => {
		interceptorsSetup(navigate);
	}, [navigate]);

	useEffect(() => {
		fetchConfigSetting()
		handleGetConfigData();
	}, []);

	useEffect(() => {
		if (
			tempData &&
			tempData.Total_User_License !== undefined &&
			tempData.Pending_User_License !== undefined
		) {
			setUsedUserLicense(
				tempData.Total_User_License - tempData.Pending_User_License
			);
		}
		if (
			tempData &&
			tempData.Total_Credit_Pool !== undefined &&
			tempData.Balance_Credit_Pool !== undefined
		) {
			setUsedCreditPool(
				tempData.Total_Credit_Pool - tempData.Balance_Credit_Pool
			);
		}
	}, [tempData]);

	const handleGetConfigData = async () => {
		try {
			setIsLoading(true);
			const response = await configService.getConfigData();
			if (response.status === 200) {
				setConfigData(response.data.config);
				fetchConfigSetting()
			}
		} catch (err) {
			console.log("error while getting config data:", err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleEditClick = (id: string) => {
		setIsWaring(false)
		setEditId(id);
		const currentItem = configData.find((item) => item.id === id) || null;
		setTempData(currentItem);
	};

  const handleChange = (key: string, value: string | boolean, size) => {
    if (tempData) {
      let newTotal: number;
      if (key === "Total_User_License") {
         newTotal = value === "" ? 0 :  Math.floor(parseFloat(value as string));
        if (usedUserLicense !== null && newTotal < usedUserLicense) {
          setIsWaring(true)
          setWarningMsg(`Total User License cannot be less than Used User License (${usedUserLicense}).`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        const newPending = newTotal - usedUserLicense!;
        setTempData({
          ...tempData,
          Total_User_License: +(newTotal),
          Pending_User_License: +(newPending),
        });
      } else if (key === "Total_Credit_Pool") {
        const newTotal = value === "" ? 0 : parseFloat(value as string);
        if (usedCreditPool !== null && newTotal < usedCreditPool) {
          setIsWaring(true)
          setWarningMsg(`Total Credit Pool cannot be less than Used Credit Pool (${usedCreditPool}).`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        const newBalance = newTotal - usedCreditPool!;
        setTempData({
          ...tempData,
          Total_Credit_Pool: +(newTotal),
          Balance_Credit_Pool: +(newBalance),
        });
      } else if (key === "Max_Image_Size_Mb") {
        const newSize = value === "" ? 0 : parseFloat(value as string);
        if (newSize > size) {
          setIsWaring(true)
          setWarningMsg(`Max Image Size must be between 0 and ${size} MB.`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        setTempData({
          ...tempData,
          Max_Image_Size_Mb: +(newSize),
          Max_Size: size
        });
      } else if (key === "Max_Image_Quality_Percent") {
        const newQuality = value === "" ? 0 : parseFloat(value as string);
        if (newQuality > size) {
          setIsWaring(true)
          setWarningMsg(`Max Image Quality Size must be between 0 and ${size} MB.`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        setTempData({
          ...tempData,
          Max_Image_Quality_Percent: +(newQuality),
          Max_Quality: size
        });
      } else if (key === "Max_Image_Width") {
        const newQuality = value === "" ? 0 : parseFloat(value as string);
        if (newQuality > size) {
          setIsWaring(true)
          setWarningMsg(`Max Image Width Size must be between 0 and ${size} MB.`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        setTempData({
          ...tempData,
          Max_Image_Width: +(newQuality),
          Max_Width: size
        });
      } else if (key === "Max_Image_Height") {
        const newQuality = value === "" ? 0 : parseFloat(value as string);
        if (newQuality > size) {
          setIsWaring(true)
          setWarningMsg(`Max Image Height must be between 0 and ${size} MB.`)
        }else{
          setIsWaring(false)
          setWarningMsg("")
        }
        setTempData({
          ...tempData,
          Max_Image_Height: +(newQuality),
          Max_Height: size
        });
      } else if(key === 'Credit_Deduction') {
        const newCredit = value === "" ? 0 : parseFloat(value as string);
        setTempData({
          ...tempData,
          Credit_Deduction: +(newCredit)
        });
      } else if(key === 'Default_Credit'){
        const newDefaultCredit = value === '' ? 0 : parseFloat(value as string);
        setTempData({
          ...tempData,
          Default_Credit: +(newDefaultCredit)
        });
      } else {
        setTempData({
          ...tempData,
          [key]: value,
        });
      }
    }
  };

	const handleSave = async () => {
		if (tempData) {
			const filteredTempData = Object.keys(tempData)
				.filter((key) => keysToDisplay.includes(key) || key === "id")
				.reduce((obj, key) => {
					obj[key] = tempData[key];
					return obj;
				}, {} as ConfigItem);

			setConfigData(
				configData.map((item) => (item.id === editId ? filteredTempData : item))
			);
			setEditId(null);
			setIsWaring(false)
			setWarningMsg("")
			if (filteredTempData) {
				const payload = { items: [filteredTempData] };
				try {
					setIsUpdating(true);
					const res = await configService.updateConfig(payload);
					if (res.status === 200) {
						await handleGetConfigData();
						toast({
							title: `${res.data.message}`,
							position: "top",
							status: "success",
							duration: 3000,
							variant: "left-accent",
							isClosable: true,
						});
					}
				} catch (err) {
					console.log("err while update config:", err);
				} finally {
					setIsUpdating(false);
				}
			}
		}
	};

	const handleCancel = () => {
		setEditId(null);
		setTempData(null);
		setIsWaring(false)
		setWarningMsg("")
	};

	const isValid = () => {
		if (tempData) {
			if (tempData.Total_User_License !== undefined && tempData.Pending_User_License !== undefined) {
				return tempData.Total_User_License >= usedUserLicense!;
			}
			if (tempData.Total_Credit_Pool !== undefined && tempData.Balance_Credit_Pool !== undefined) {
				return tempData.Total_Credit_Pool >= usedCreditPool!;
			}
			if (tempData.Max_Image_Size_Mb !== undefined && tempData.Max_Size !== undefined) {
				return tempData.Max_Image_Size_Mb >= 0 && tempData.Max_Image_Size_Mb <= tempData.Max_Size;
			}
			if (tempData.Max_Image_Quality_Percent !== undefined && tempData.Max_Quality !== undefined) {
				return tempData.Max_Image_Quality_Percent >= 0 && tempData.Max_Image_Quality_Percent <= tempData.Max_Quality;
			}
			if (tempData.Max_Image_Width !== undefined && tempData.Max_Width !== undefined) {
				return tempData.Max_Image_Width >= 0 && tempData.Max_Image_Width <= tempData.Max_Width;
			}
			if (tempData.Max_Image_Height !== undefined && tempData.Max_Height !== undefined) {
				return tempData.Max_Image_Height >= 0 && tempData.Max_Image_Height <= tempData.Max_Height;
			}
			return true;
		}
		return false;
	};

  const isBoolean = (value: any) => typeof value === "boolean";
  const keysToDisplay = [
    "Total_User_License",
    "Pending_User_License",
    "Default_Credit",
    "Use_Credit_Pool",
    "Total_Credit_Pool",
    "Balance_Credit_Pool",
    "Credit_Deduction",
    "Max_Image_Size_Mb",
    "Max_Image_Quality_Percent",
    "Max_Image_Width",
    "Max_Image_Height",
    "To_be_Deducted",
    "Credit_Deduction",
    "Intelli_Tag",
    "Intelli_Search",
    "Relevancy_Threshold"
  ];


	const formatConfigName = (name) => {
		switch (name) {
			case 'license':
				return 'License';
			case 'creditpool':
				return 'Credit Pool';
			case 'imagequality':
				return 'Image Quality';
			case 'deductionupload':
				return 'Upload Deduction';
			case 'deductiontags':
				return 'Tags Deduction';
			case 'deductionsearch':
				return 'Search Deduction';
			case 'features':
				return 'Features';
			default:
				return name;
		}
	};

  return (
    <Box p={2}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={6}
        pb={2}
        borderBottom="0.5px solid #BDBDBD"
      >
        <h2>Configuration</h2>
        <NavLink to="/layout" className="crossBtn">
          <span><RxCross1/></span>
        </NavLink>
      </Flex>
     <Grid gridTemplateColumns={{base:"repeat(1,1fr)",md:"repeat(2,1fr)",lg:"repeat(3,1fr)"}} columnGap={"12px"}>

      {configData.map((item) => (
        <Box
          key={item.id}
          p={4}
          shadow="md"
          borderWidth="1px"
          mb={4}
          borderRadius={"5px"}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight={700} fontSize="bold" m={"0px"}>
            {formatConfigName(item.id)}
            </Text>
            {editId === item.id ? (
              <Flex flexDir={{ base: "column", md: "row" }} gap={{ base: "7px", md: "auto" }}>
                <Button
                  size={{ base: "sm" }}
                  // leftIcon={<FaSave />}
                  id="btn"
                  variant="solid"
                  onClick={handleSave}
                  isDisabled={!isValid() || isUpdating}
                >
                  Save
                </Button>
                <Button
                  size={{ base: "sm" }}
                  // leftIcon={<FaTimes />}
                  colorScheme="red"
                  variant="outline"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Flex>
            ) : (
              <IconButton
                aria-label="Edit"
                icon={<FaEdit />}
                onClick={() => handleEditClick(item.id)}
              />
            )}
          </Flex>
          <Stack spacing={1} mt={3}>
            {Object.entries(item)
              .filter(([key]) => keysToDisplay.includes(key))
              .map(([key, value]) => (
                <Grid
                  gap={{base:"auto",md:"7px",lg:"auto"}}
                  gridTemplateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  key={key}
                >
                  <Text fontWeight="sm" m={"0px"}>{key.replace(/_/g, " ")}</Text>
                  {editId === item.id ? (
                    key === "Pending_User_License" ||
                    key === "Balance_Credit_Pool" ? (
                      <Input value={tempData?.[key] || 0} isDisabled />
                    ) : isBoolean(value) ? (
                      <Flex alignItems={"center"} justify={"flex-start"} gap={"10px"}>
                        {key === 'Intelli_Tag' || key === 'Intelli_Search' ?<span>disable</span>:<span>false</span>}
                      <Switch
                        isChecked={tempData?.[key] as boolean}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(key, e.target.checked, value)
                        }
                      />
                      {key === 'Intelli_Tag' || key === 'Intelli_Search' ?<span>enable</span>:<span>true</span>}
                      </Flex>
                      
                    ) : (
                      <Input
                        // value={tempData?.[key] === 0 ? 0 : tempData?.[key]}
                        type="number"
                        step="1"
                        min="0"
                        value={tempData?.[key]?.toString() || ""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(key, e.target.value, value)
                        }
                      />
                    )
                  ) : (
                    <>
                     {(key === 'Intelli_Tag' || key === 'Intelli_Search') ? <Text m={"0px"}>{value == true ? "enable" : "disable"}</Text> :
                     <Text m={"0px"}>{value.toString()}</Text>}
                    </>
                  )}
              
                </Grid>
              ))}
                {
                    (editId === item.id && isWarning && warningMsg.length > 0) && <Flex textAlign={"center"} float={"right"} padding={"5px"}>
                    <Text m={"0px"} fontSize={"12px"} fontWeight={"500"} color={"red"}>{warningMsg}</Text>
                    </Flex>
                  }
          </Stack>
        </Box>
      ))}
     </Grid>
    </Box>
  );
};

export default Configuration;
