import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./promptEditor.css";
 
const BlockEmbed = ReactQuill.Quill.import("blots/block/embed");
 
class NonEditableBlot extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    node.setAttribute("contenteditable", "false");
    node.classList.add("non-editable");
    node.innerHTML = value;
    return node;
  }
 
  static value(node) {
    return node.innerHTML;
  }
}
 
NonEditableBlot.blotName = "non-editable";
NonEditableBlot.tagName = "div";
ReactQuill.Quill.register(NonEditableBlot);
 
const PromptEditor = ({ domainPrompt, setDomainPrompt }) => {
  const [editorContent, setEditorContent] = useState(""); 
  const quillRef = useRef(null); 
  const isInitialContentSet = useRef(false);
 
  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
    ],
  };
 
  useEffect(() => {
    if (quillRef.current && quillRef.current.getEditor) {
      const editor = quillRef.current.getEditor();
 
      if (!isInitialContentSet.current) {
        if (domainPrompt) {
          // Set initial content in editor
          editor.clipboard.dangerouslyPasteHTML(domainPrompt);
          setEditorContent(domainPrompt);
        } else {
          // Default initial content if domainPrompt is empty
          const initialContent = `
            3. If any category cannot be detected from the images, please refrain from generating tags for that specific category. And that category should not exist in the final response.<br>
            4. The color recognition tags provide a limited color range. However, you have the flexibility to label additional colors as required. If multi-color, put all possible colors in a string using commas (',').<br>
            5. Refrain from adding any extra comments into the JSON output.<br>
            6. Correctly map tags with specified entities.<br>
            7. If any information is not available related to any item/product in the image, please use 'N/A' as a placeholder.<br>
          `;
 
          // Set initial content in editor
          editor.clipboard.dangerouslyPasteHTML(initialContent);
          setEditorContent(initialContent);
          setDomainPrompt(initialContent); // Update domainPrompt state
        }
 
        isInitialContentSet.current = true;
      }
    }
  }, [domainPrompt, setDomainPrompt]);
 
  const handleChange = (content) => {
    setEditorContent(content); 
    setDomainPrompt(content);
  };
 
  return (
<div>
<div className="non-editable-labels">
<ol>
<li>
            Accurately identify the _LOV_ that are IN FOCUS AND CAPTURED FULLY
            within a given catalog image. Find the metadata for identified
            items worn or present, from the below information: {" "}
            \n _TAGS_
</li>
<li>
            {" "}
            Use the following format to create json and output only json:{" "}
            \n _OUTPUT_
</li>
</ol>
</div>
      {/* Render the Quill editor */}
<ReactQuill
        ref={quillRef}
        modules={modules}
        theme="snow"
        value={editorContent}
        onChange={handleChange}
      />
</div>
  );
};
 
export default PromptEditor;