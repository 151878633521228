import React, { useEffect, useState } from 'react'
import Style from './GoTop.module.css'
import { HiOutlineArrowNarrowUp } from "react-icons/hi";

const GoTop = (props) => {
    const {parentRef} = props;
    const [isBtnVisible, setIsBtnVisible] = useState(false);

    const listenToScroll = () => {
        if (parentRef.current) {
            const heightToHidden = 250;
            const winScroll = parentRef.current.scrollTop;

            if (winScroll > heightToHidden) {
                setIsBtnVisible(true);
            } else {
                setIsBtnVisible(false);
            }
        }
    };

    const goToTopBtn = () => {
        if (parentRef.current) {
            parentRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    useEffect(() => {
        const parentElement = parentRef.current;
        if (parentElement) {
            parentElement.addEventListener("scroll", listenToScroll);
        }
        return () => {
            if (parentElement) {
                parentElement.removeEventListener("scroll", listenToScroll);
            }
        };
    }, [parentRef]);

  return (
    <div>
      {isBtnVisible && <button onClick={goToTopBtn} className={Style.goTopBtn}><HiOutlineArrowNarrowUp size={24} /></button>}
    </div>
  )
}

export default GoTop
