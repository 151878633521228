import { Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { interceptorsSetup } from '../../../api/intreceptor';
import contactService from '../../../api/contactService';
import LoadingOverlay from "../../LoadingOverlay/LoadingOverlay";

const ContactModal = (props) => {
	const { isOpen, onOpen, onClose, type } = props;
	const email = localStorage.getItem('email') || '';
	const name = localStorage.getItem('name') || '';
	const [text, setText] = useState("")
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate()
	const toast = useToast();

	useEffect(() => {
		interceptorsSetup(navigate);
	}, [navigate]);

	const handleSubmit = async(event) => {
		event.preventDefault();

		const payload = { email, name, text, type }
		console.log("payload:", payload)
		try{
          setIsLoading(true);
		  const response = await contactService.userFeedbackSupport(payload);
		  if(response.status === 200){
			toast({
				description: `${response?.data?.message}`,
				position: "top",
				status: "success",
				duration: 3000,
				variant: "left-accent",
				isClosable: true,
			  });
		  }
		}catch(err){
			console.log("err: ", err)
		}finally{
			setIsLoading(false)
            handleCloseModal()
		}
	};

	const handleCloseModal = () => {
		setText("");
		onClose()
	}

    if(isLoading){
        return <LoadingOverlay message='Submiting...'/>
    }
	return (
		<>
			<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{type === 'Feedback' ? 'Feedback' : 'Contact Support'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Flex flexDir={"column"}>
							<h6>Name <span>:</span> <span>{name}</span></h6>
							<h6>Email <span>:</span> <span>{email}</span></h6>
						</Flex>
						<form onSubmit={handleSubmit}>
							<FormControl mt={4}>
								<FormLabel>Message</FormLabel>
								<Textarea rows={7} value={text} onChange={(e) => setText(e.target.value)} placeholder={`Enter your ${type.toLowerCase() == 'feedback' ? "feedback" : "issue"} here`} />
							</FormControl>
							<Flex justifyContent={"center"} alignItems={"center"} gap={"10px"} mt={4}>
							<Button isDisabled={isLoading || text.length === 0} size={"sm"} id="btn" colorScheme="blue" type="submit">Submit</Button>
							<Button isDisabled={isLoading} size={"sm"} onClick={handleCloseModal} colorScheme={'red'} variant={"outline"}>Close</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ContactModal
