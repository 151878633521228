import React, { useEffect } from 'react'
import Routes from "./Routes";
import { useNavigate } from 'react-router-dom';
import { interceptorsSetup } from './api/intreceptor';

const App = () => {
   
  return (
    <>
    <Routes/>
    </>
  )
}

export default App
