/**
 * owner : retrAIver
 * author : Arpitha from Affine
 */
import axios from "axios";
import { axiosInstance } from "./intreceptor";

let cache = {};
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class userService {

      creditdata(username){
        return axiosInstance
        .get(`/get_user_balance?username=${username}`)
        .then((response) =>{
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      userBalance(email){
        return axiosInstance
        .get(`get_user_balance?email=${email}`)
        .then((response) =>{
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      fetchUsers = () => {
          return axiosInstance
            .get('/user/getusers')
            .then(response => {
              return response;
            })
            .catch(err => {
              throw err;
            });
      }

      createUser = (user_info) => {
        return axiosInstance
          .post("/user/createuser",user_info)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }

      updateUser = (user_info) => {
        return axiosInstance
          .post("/user/updateuser",user_info)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }

      changeUserStatus = (user_info) => {
        return axiosInstance
          .post("/user/changeuserstatus",user_info)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }


      usertransctiondetails  = (email) => {
        return axiosInstance
        .get(`/transaction_details?email=${email}`)
        .then(response => {
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      addCategories(categoriesData) {
        return api
          .post(`/create_taxonomy_item/`,categoriesData)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      fetchDomain(){
        return api
          .get(`/get_taxonomy_items/`)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      updateCategory(domain_id,categoriesData){
        return api
        .put(`/update_taxonomy_item/${domain_id}`, { categories: categoriesData })
        .then((response) => {
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      deleteCategory(domain_id,category_id){
        return api
        .delete(`/delete_category/${domain_id}/${category_id}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      deletedomain(domain_id,email){
        return api
        .delete(`/delete_domain/${domain_id}/${email}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      deleteattribute(domain_id,category_id,attribute){
        return api
        .delete(`/delete_attribute/${domain_id}/${category_id}/${attribute}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          console.log(err);
        });
      }

      getActiveUsers  = () => {
        return axiosInstance
        .get(`/user/getactiveusers`)
        .then(response => {
          console.log("service user:", response)
          return response.data.users
        })
        .catch((err) => {
          console.log(err);
        });
      }

      transactionData = (payload) => {
        return axiosInstance
          .post("/transaction_data",payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }
}

export default new userService()
