import React, { useContext, useEffect } from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  FormControl,
  FormLabel,
  Select,
  DrawerFooter,
  Stack,
  Button,
  Text,
  useToast,
  Box,
} from '@chakra-ui/react';
import { MultiSelect } from "react-multi-select-component";
import MultiSelectStyle from "../../../assets/styles/CreateDepartment.module.css";
import taxonomyService from '../../../api/taxonomyService';

import { useLocation, useNavigate } from 'react-router-dom';
import '../../../assets/global.css';
import searchService from '../../../api/searchService';
import { interceptorsSetup } from '../../../api/intreceptor';
import useCurrentRoute from '../../../hooks/useCurrentRoute';
import { CentralizeStore } from '../../../contexts/CentralizeStoreProvider';


const TagSearch = (props) => {
  const { isTagSearchDrawerOpen, onTagSearchDrawerClose, setUploadedFile } = props;
  const btnRef = React.useRef();
  const [tagsFilterArr, setTagsFilterArr] = React.useState([]);
  const { searchTagFormState, setSearchTagFormState,setSearchRes,setIsTagSearch, setIsSearchLoading,setUploadedImage,IsFilteredExistTrue } = useContext(CentralizeStore) // Use context
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTagSearching, setIsTagSearching] = React.useState(false);
  const currentRoute = useCurrentRoute();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  useEffect(() => {
    getAllTags();
  }, []);

  const getAllTags = async () => {
    try {
      setIsLoading(true);
      const response = await taxonomyService.getDomain();
      setTagsFilterArr(response.taxnonmies);
    } catch (err) {
      console.log('err:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDomainChange = (e) => {
    const domainId = e.target.value;
    setSearchTagFormState(prev => ({
      ...prev,
      selectedDomain: domainId,
      selectedCategory: '', // Reset category on domain change
      attributes: {},
      selectedAttributes: {}
    }));
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    const domain = tagsFilterArr.find((item) => item.id === searchTagFormState.selectedDomain);
    const category = domain.categories.find((cat) => cat.cat_id === categoryId);
    const categoryAttributes = category.attributes || {};
    const splitAttributes = {};
    for (const [key, value] of Object.entries(categoryAttributes)) {
      splitAttributes[key] = value[0].split(',');
    }
    setSearchTagFormState(prev => ({
      ...prev,
      selectedCategory: categoryId,
      attributes: splitAttributes,
      selectedAttributes: {}
    }));
  };

  const handleAttributeChange = (attrName, selectedOptions) => {
    setSearchTagFormState(prev => ({
      ...prev,
      selectedAttributes: {
        ...prev.selectedAttributes,
        [attrName]: selectedOptions.map((option) => option.value),
      }
    }));
  };

  const handleSelectMatchTypeChange = (e) => {
    setSearchTagFormState(prev => ({
      ...prev,
      selectMatchType: e.target.value
    }));
  };

  const handleApply = async (e) => {
    e.preventDefault();
    const domain = tagsFilterArr.find((item) => item.id === searchTagFormState.selectedDomain);
    const category = domain?.categories.find((cat) => cat.cat_id === searchTagFormState.selectedCategory);

    const payload = {
      email: localStorage.getItem("email"),
      role: localStorage.getItem("role"),
      domain_name: domain?.domain_name || '',
      domain_id: domain?.id || '',
      cat_name: category?.cat_name || '',
      cat_id: category?.cat_id || '',
      attributes: searchTagFormState.selectedAttributes,
      match_type: searchTagFormState.selectMatchType,
      fromTagSearch: false,
    };

    try {
      setIsTagSearching(true);
      const response = await searchService.tagSearch(payload);
      if (response.status === 200) {
        setUploadedImage("")
        setUploadedFile(response?.data?.search_result)
        IsFilteredExistTrue()
        // setSearchRes(response.data.search_result)
        // setIsTagSearch(true)
        // setIsSearchLoading(true)
        // if (currentRoute !== '/layout/search') {
        //   navigate('/layout/search-result');
        // }
      }
    } catch (err) {
      console.log("err while tag_search:", err);
    } finally {
      setIsTagSearching(false);
      setIsSearchLoading(false)
    }
  };

  const downloadImage = (url, index) => {
    let filename = `image_${index + 1}.webp`;
    const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
   };

  const handleReset = () => {
    setSearchTagFormState({
      selectedDomain: '',
      selectedCategory: '',
      attributes: {},
      selectedAttributes: {},
      selectMatchType: ''
    });
  };

  const isApplyDisabled = !searchTagFormState.selectedDomain || !searchTagFormState.selectedCategory || !searchTagFormState.selectMatchType;

  return (
    <>
      {/* <Drawer isOpen={isTagSearchDrawerOpen} placement="left" onClose={onTagSearchDrawerClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"whitesmoke"} />
          <DrawerHeader id='drawer'>
            <Text m={"0px"}>Tag Search</Text>
          </DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleApply}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel><span className='tagSearchFilter'>Domain <span>*</span> </span></FormLabel>
                  <Select onChange={handleDomainChange} value={searchTagFormState.selectedDomain}>
                    <option disabled value="">--- Select Domain ---</option>
                    {tagsFilterArr?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.domain_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel><span className='tagSearchFilter'>Match Type <span>*</span></span></FormLabel>
                  <Select onChange={handleSelectMatchTypeChange} value={searchTagFormState.selectMatchType}>
                    <option value="" disabled> --- Select Match Type --- </option>
                    <option value="AND">Match all attributes</option>
                    <option value="OR">Match any one attributes</option>
                  </Select>
                </FormControl>

                {searchTagFormState.selectedDomain && (
                  <>
                    <FormControl>
                      <FormLabel><span className='tagSearchFilter'>Category <span>*</span></span></FormLabel>
                      <Select onChange={handleCategoryChange} value={searchTagFormState.selectedCategory}>
                        <option disabled value="">--- Select category ---</option>
                        {tagsFilterArr.find((item) => item.id === searchTagFormState.selectedDomain)
                          ?.categories.map((cat) => (
                            <option key={cat.cat_id} value={cat.cat_id}>
                              {cat.cat_name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>

                    {searchTagFormState.selectedCategory && searchTagFormState.attributes && Object.keys(searchTagFormState.attributes).length > 0 && (
                      <>
                        <FormLabel><span className='tagSearchFilter'>Attributes</span></FormLabel>
                        {Object.keys(searchTagFormState.attributes).map((attrName) => {
                          const attributeOptions = searchTagFormState.attributes[attrName].map((value) => ({
                            label: value,
                            value,
                          }));
                          return (
                            <FormControl key={attrName}>
                              <FormLabel fontSize={"12px"}>{attrName}</FormLabel>
                              <MultiSelect
                                options={attributeOptions}
                                value={searchTagFormState.selectedAttributes[attrName]?.map((val) => ({ label: val, value: val })) || []}
                                onChange={(selectedOptions) => handleAttributeChange(attrName, selectedOptions)}
                                labelledBy={`Select ${attrName}`}
                                className={MultiSelectStyle.multi_selecet_input}
                              />
                            </FormControl>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </Stack>
            </form>
          </DrawerBody>
          <DrawerFooter display={"flex"} alignItems={"flex-end"} gap={"7px"} width={"100%"} height={"auto"} position={"sticky"} left={"0"} bottom={"0"} backgroundColor={"#FFF"}>
            <Button size={"sm"} variant="outline" colorScheme={"red"} onClick={handleReset}>
              Reset
            </Button>
            <Button isDisabled={isApplyDisabled || isTagSearching} size={"sm"} colorScheme="blue" variant={"outline"} type="submit" onClick={handleApply}>
              {isTagSearching ? "Searching..." : "Apply"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer> */}
          {/* <DrawerBody> */}
            <form onSubmit={handleApply}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel><span className='tagSearchFilter'>Domain <span>*</span> </span></FormLabel>
                  <Select onChange={handleDomainChange} value={searchTagFormState.selectedDomain}>
                    <option disabled value="">--- Select Domain ---</option>
                    {tagsFilterArr?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.domain_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel><span className='tagSearchFilter'>Match Type <span>*</span></span></FormLabel>
                  <Select onChange={handleSelectMatchTypeChange} value={searchTagFormState.selectMatchType}>
                    <option value="" disabled> --- Select Match Type --- </option>
                    <option value="AND">Match all attributes</option>
                    <option value="OR">Match any one attributes</option>
                  </Select>
                </FormControl>

                {searchTagFormState.selectedDomain && (
                  <>
                    <FormControl>
                      <FormLabel><span className='tagSearchFilter'>Category <span>*</span></span></FormLabel>
                      <Select onChange={handleCategoryChange} value={searchTagFormState.selectedCategory}>
                        <option disabled value="">--- Select category ---</option>
                        {tagsFilterArr.find((item) => item.id === searchTagFormState.selectedDomain)
                          ?.categories.map((cat) => (
                            <option key={cat.cat_id} value={cat.cat_id}>
                              {cat.cat_name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>

                    {searchTagFormState.selectedCategory && searchTagFormState.attributes && Object.keys(searchTagFormState.attributes).length > 0 && (
                      <>
                        <FormLabel><span className='tagSearchFilter'>Attributes</span></FormLabel>
                        {Object.keys(searchTagFormState.attributes).map((attrName) => {
                          const attributeOptions = searchTagFormState.attributes[attrName].map((value) => ({
                            label: value,
                            value,
                          }));
                          return (
                            <FormControl key={attrName}>
                              <FormLabel fontSize={"12px"}>{attrName}</FormLabel>
                              <MultiSelect
                                options={attributeOptions}
                                value={searchTagFormState.selectedAttributes[attrName]?.map((val) => ({ label: val, value: val })) || []}
                                onChange={(selectedOptions) => handleAttributeChange(attrName, selectedOptions)}
                                labelledBy={`Select ${attrName}`}
                                className={MultiSelectStyle.multi_selecet_input}
                              />
                            </FormControl>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </Stack>
            </form>
          {/* </DrawerBody> */}
          <Box display={"flex"} padding={"10px"} justifyContent={"flex-end"} gap={"7px"} width={"100%"} height={"auto"} position={"absolute"} right={"0"} bottom={"0"} backgroundColor={"#FFF"}>
            <Button size={"sm"} variant="outline" colorScheme={"red"} onClick={handleReset}>
              Reset
            </Button>
            <Button isDisabled={isApplyDisabled || isTagSearching} size={"sm"} colorScheme="blue" variant={"outline"} type="submit" onClick={handleApply}>
              {isTagSearching ? "Searching..." : "Apply"}
            </Button>
          </Box>
    </>
  );
};

export default TagSearch;
