import "./ModelOutputContainer.css";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import { Box, Button, Flex, Skeleton, Tooltip} from "@chakra-ui/react";
import { RxCross1 } from "react-icons/rx";
import viewOutput from "../../api/viewOutput";
import { interceptorsSetup } from "../../api/intreceptor";
import Metadata from "./components/Metadata";
import ImageZoom from "./components/ImageZoom";
import useFetchConfigSetting from "../../hooks/useConfigSetting";
import ImageCarousel from "./components/ImageCarousel";
import taxonomyService from "../../api/taxonomyService";
import { MultiSelect } from "react-multi-select-component";
import MultiSelectStyle from '../../assets/styles/CreateDepartment.module.css'
import configService from "../../api/configService";

 
const ModelOutputContainer = () => {
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [modelOutput, setModalOutput] = useState([]);
    const [isModelOutputFetched, setIsModelOutputFetched] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage,setCurrentImage] = useState({});
    const [currentFeedbackData,setFeedbackData] = useState([]);
    const [OriginalFeedbackData,setOriginalFeedbackData] = useState([]);
    const [selectedRadioButton, setSelectedRadioButton] = useState("Model_Prediction");
    const [readOnlyModelOutput, setReadOnlyModelOutput] = useState(false);
    const [defaultFeedbackData, setDefaultFeedbackData] = useState([]);
    const [isEditFeedbackPresent, setIsEditFeedbackPresent] = useState(false);
    const {fetchConfigSetting} = useFetchConfigSetting()
    const configSetting = JSON.parse(localStorage.getItem("configSetting"))
    const [allDomain, setAllDomain] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [newConfigSetting, setNewConfigSetting] = useState(null);
 
  const [editFeedbackOutput, setEditFeedbackOutput] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [isFeedBackPresent, setIsFeedBackPresent] = useState(false);
 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
 
  const ids = JSON.parse(queryParams.get("ids"));
  const storedEmail = queryParams.get("email");
  const getViewTagPayload = {
    ids,
    email: storedEmail,
  };
 
    let navigate = useNavigate();
 
    useEffect(() => {
        interceptorsSetup(navigate);
    }, [navigate]);
 
     const transformLabel = (label) =>
        label.split("_").map((word) => word.toUpperCase()).join(" ");
 
    //fetching img info from db
    useEffect(() => {
        handleFetchLatestConfigSetting()
        if (getViewTagPayload) {
            fetchModelOutput();
        }
        fetchConfigSetting()
    }, []);

    useEffect(() => {
      getAllTags();
    }, []);
  
    const getAllTags = async () => {
      try {
        const response = await taxonomyService.getDomain();
        setAllDomain(response.taxnonmies);
      } catch (err) {
        console.log('err:', err);
      }
    };

    
    // console.log("selectedOp:", selectedOptions)
    
  
    

    const fetchModelOutput = async () => {
        try {
            setLoadingOverlay(true);
            const response = await viewOutput.viewModelOutput(getViewTagPayload);
            // console.log(response)
            setModalOutput(response.data.tag_data);
            setIsModelOutputFetched(true)          
        }
        catch (error) {
            console.error("Error fetching image URLs:", error);
        }
        finally {
            setLoadingOverlay(false);
        }
    }
 
    useEffect(() => {
        if (isModelOutputFetched) {
            setCurrentImage(modelOutput[currentImageIndex]);
            setActiveTab(modelOutput[currentImageIndex]['tags_model']?.[0]?.category_name);

            if(modelOutput?.[currentImageIndex]?.['tags_model']?.length === 0){
              setActiveTab("metadata")
            }
            if(!modelOutput[currentImageIndex]['tags_feedback'] || !modelOutput[currentImageIndex]['tags_model']){
              setActiveTab("metadata")
            }
           
            setEditFeedbackOutput(modelOutput?.[currentImageIndex]?.['tags_feedback'])
            if(modelOutput[currentImageIndex]['tags_feedback']?.length === 0){
                setFeedbackData(modelOutput?.[currentImageIndex]?.['tags_model']);
                setOriginalFeedbackData(modelOutput?.[currentImageIndex]?.['tags_model'])
                setDefaultFeedbackData(modelOutput?.[currentImageIndex]?.['tags_model'])
                setEditFeedbackOutput(modelOutput?.[currentImageIndex]?.['tags_model'])
            }else{
                setFeedbackData(modelOutput?.[currentImageIndex]?.['tags_feedback']);
            setOriginalFeedbackData(modelOutput?.[currentImageIndex]?.['tags_feedback'])
            setDefaultFeedbackData(modelOutput?.[currentImageIndex]?.['tags_feedback'])
            }
            if(modelOutput?.[currentImageIndex]?.['tags_feedback']?.length !== 0){
                compareObjects(modelOutput[currentImageIndex]['tags_model'], modelOutput[currentImageIndex]['tags_feedback']);
            }
            setIsModelOutputFetched(false);
        }
    }, [isModelOutputFetched,modelOutput,currentImage]);
 
    function compareObjects(obj1, obj2) {
        const compareCategories = (categories1, categories2) => {
            const resultCategories = [];
   
            categories1.forEach((category1) => {
                var category2 = categories2.find((cat) => cat.category_name === category1.category_name) || {};
                if(Object.keys(category2).length === 0){
                    category2 = category1
                }
              const categoryResult = {};
              Object.keys(category1).forEach((key) => {
                if (Array.isArray(category1[key])) {
                  categoryResult[key] = compareArray(category1[key],category2[key] );
                } else {
                  categoryResult[key] = category2[key] || category1[key];
                }
              });
   
              resultCategories.push(categoryResult);
            });
   
            return resultCategories;
        };
       
        const compareArray = (arr1, arr2) => {
            const result = [];
   
            // Items present in arr1 but not in arr2 (removed)
            let existsAttr = arr1?.map(val => val.split("_")[0]).map(val => val.split(",")).flat().map(val => val.trim());
            let updatedAttr = arr2?.map(val => val.split("_")[0]).map(val => val.split(",")).flat().map(val => val.trim());
            existsAttr.forEach((item) => {
              if (updatedAttr && updatedAttr.includes(item)) {
                result.push(`${item}_unchanged`);
              }
              if (!updatedAttr || !updatedAttr.includes(item)) {
                result.push(`${item}_removed`);
              }
            });
   
            // Items present in arr2 but not in arr1 (added)
            if (updatedAttr) {
              updatedAttr.forEach((item) => {
                if (!existsAttr || !existsAttr.includes(item)) {
                  result.push(`${item}_added`);
                }
              });
            }
   
            return result;
        };
        const resultObj = {};
        if (obj1 && obj2) {
            resultObj["tags_feedback"] = compareCategories(obj1,obj2);
            setFeedbackData(resultObj?.['tags_feedback'])
            setOriginalFeedbackData(resultObj?.['tags_feedback'])
        }
    }
 
    const handleNext = () => {
        setReadOnlyModelOutput(false);
        setSelectedRadioButton("Model_Prediction");
        setCurrentImageIndex((index) => {
          const newIndex = index + 1;
          return newIndex < modelOutput.length ? newIndex : index;
        });
       
    };
   
      const handlePrevious = () => {
        setReadOnlyModelOutput(false);
        setSelectedRadioButton("Model_Prediction");
        setCurrentImageIndex((index) => {
          const newIndex = index - 1;
          return newIndex >= 0 ? newIndex : index;
        });
       
    };
 
    useEffect(() => {
        if(modelOutput.length > 0){
            setCurrentImage(modelOutput[currentImageIndex])
            setIsModelOutputFetched(true)
        }
    }, [currentImageIndex]);
 
    const handleRadioChange = (event) => {
        setReadOnlyModelOutput(false);
        setSelectedRadioButton(event.target.value);
    };
 
    useEffect(() => {
        setReadOnlyModelOutput(false);
        setFeedbackData(OriginalFeedbackData);
        setEditFeedbackOutput(OriginalFeedbackData)
    },[activeTab])
 
    const handleInputValueChange = (value, key, index) => {
        // if(isFeedBackPresent == false){
        //     const currentData  = JSON.parse(JSON.stringify(currentFeedbackData))
        //     currentData[index][key] = value
        //     setEditFeedbackOutput(currentData)
        // }
        // if(isFeedBackPresent) {
        //     const currentData  = JSON.parse(JSON.stringify(currentFeedbackData))
        //     currentData[index][key] = value
        //     setEditFeedbackOutput(currentData)
        // }
        console.log(editFeedbackOutput)
        const updatedFeedback = [...editFeedbackOutput];
        console.log(updatedFeedback)
        updatedFeedback[index] = { ...updatedFeedback[index], [key]: value };
        console.log(updatedFeedback)
        setEditFeedbackOutput(updatedFeedback);
    };

    const handleMultiSelectChange = (key, selected, ind) => {
      // console.log("key:-",key)
      // console.log("selected:-", selected)
      setSelectedOptions(prevState => ({
        ...prevState,
        [key]: selected
      }));
      console.log(selectedOptions)
    // Update the editFeedbackOutput or other state if necessary
    // const currentData = [...editFeedbackOutput];
    // currentData[ind][key] = selected;
    // setEditFeedbackOutput(currentData);

      // const selectedValues = selected.map(option => option.value);
      // setSelectedOptions(prev => ({ ...prev, [key]: selectedValues }));
      // handleInputValueChange(selectedValues, key, ind);
    };
 
    const handleUserFeedbackSubmit = async (index, type) => {
        let confirmDelete = false;
        if (type == 1 || type == 2) {
          confirmDelete = window.confirm(
            "Clicking 'Approve' will freeze tag editing across all tabs. Use it only after reviewing all tabs."
          );
        }else{
          confirmDelete = true
        }
        if(confirmDelete) {

        try{
            // var updateObj = editFeedbackOutput?.[index]
            // if(editFeedbackOutput.length === 0) {
            //     updateObj = {}
            // }

            // var finalObj = {}
            // Object.entries(updateObj)?.map(([key, val]) => {
            //     finalObj[key] = formatValue(val,"edit")
            // })
            let finalObj = {};
    
            // Include all keys from editFeedbackOutput
            // console.log("editFeedbackOutput in sub:", editFeedbackOutput)
            // console.log(editFeedbackOutput)
            // console.log(index)
            let data = editFeedbackOutput?.[index]
            // editFeedbackOutput?.forEach((data, index) => {
            //   Object.entries(data).forEach(([key, val]) => {
            //     finalObj[key] = formatValue(val, "edit");
            //   });
            // });
            // console.log("payload d:", data)
            Object.entries(data).forEach(([key, val]) => {
                  finalObj[key] = formatValue(val, "edit");
                });
            // Include all keys from selectedOptions for MultiSelect fields
            Object.entries(selectedOptions).forEach(([key, val]) => {
              finalObj[key] = (val as { value: any }[]).map(option => option.value);
            });
            // console.log("finalObj:", finalObj)
          const feedBack = {
            ids,
            update_id: currentImage['id'],
            tags_feedback:finalObj,
            email:localStorage.getItem("email"),
            type: type
          };
          console.log(feedBack)
          setLoadingOverlay(true);
          const response = await viewOutput.updateTagFeedback(feedBack);
          console.log(response)
            if(response.status === 200){
               await fetchModelOutput()
            }
            setIsModelOutputFetched(true)
        } catch (error) {
          console.error("Error while submitting the feedback:", error);
        } finally {
          setReadOnlyModelOutput(false);
          setLoadingOverlay(false);
        }
      }

    };
 
    function formatValue(value: any, mode?) {
		if(value === undefined || value == null || value == "") {
			return [] 
		}

        if (Array.isArray(value)) {
          const formattedValues = value?.map?.((item) => {
            const trimmedItem = trimOperationSuffix(item);
            const cssClass = getCssClass(item);
            if (mode && mode === "edit") {
              return trimmedItem;
            }
            return (
              <span key={item} className={cssClass}>
                {trimmedItem}
              </span>
            );
          });
   
          if (mode && mode === "edit") {
            return formattedValues;
          }
   
          return formattedValues.map((element, index) => (
            <React.Fragment key={index}>
              {index > 0 && ", "}
              {element}
            </React.Fragment>
          ));
        }
   
        // return value.toString();
        if (value !== undefined && value !== null) {
            return value.toString();
        }
        return "";
    }
   
    function trimOperationSuffix(item: string): string {
        const suffixes = ["_unchanged", "_removed", "_added", "_not_applicable"];
        for (const suffix of suffixes) {
          if (item.endsWith(suffix)) {
            return item.slice(0, -suffix.length); // Remove the suffix
          }
        }
        return item;
    }
   
    function getCssClass(item: string): string {
        if (item.endsWith("_removed")) {
            return "removed";
        }
        if (item.endsWith("_added")) {
            return "added";
        }
        return "";
    }
 
    const handleGoBack = () => {
        navigate(-1)
    }
    const handleEditCancel = () => {
        if (readOnlyModelOutput) {
        //   setFeedbackData(defaultFeedbackData);
        setFeedbackData(OriginalFeedbackData)
        setSelectedOptions([])
        const currentData  = JSON.parse(JSON.stringify(currentFeedbackData))
        setEditFeedbackOutput(currentData)
        }
        setReadOnlyModelOutput(prevState => !prevState);
      };
    const currentZoomImage = currentImage["processed_image_url"];
 
    useEffect(() => {
        if (modelOutput && modelOutput[currentImageIndex]?.tags_model && modelOutput[currentImageIndex]?.tags_feedback && modelOutput !== undefined ) {
          const modelCategories = modelOutput[currentImageIndex].tags_model.map((tag) => tag.category_name);
          if(modelOutput[currentImageIndex].tags_feedback.length === 0){
            setIsFeedBackPresent(false);
          }else{
          const feedbackCategories = modelOutput[currentImageIndex].tags_feedback.map((tag) => tag.category_name);
   
          const isPresent = modelCategories.some((category) =>
            feedbackCategories.includes(category)
          );
   
          const isActiveTabPresent = feedbackCategories.includes(activeTab);
          setIsFeedBackPresent(isActiveTabPresent);
         
        }
 
        // if(!isEditFeedbackPresent){
        //     setIsEditFeedbackPresent(false)
        // }
        }
        setSelectedOptions([])
      }, [modelOutput, currentImageIndex, activeTab]);

      // console.log("selectedOptions:", selectedOptions)
      

      const getOptions = (feedbackData, domain) => {
        return feedbackData?.map((category) => {
          // const matchedDomain = domain?.categories?.find(cat => cat.cat_name.replace(/[^a-zA-Z0-9\s]/g, " ").toUpperCase() === activeTab.replace(/[^a-zA-Z0-9\s]/g, " ").toUpperCase());
          const matchedDomain = domain?.categories?.find(cat => 
            cat.cat_name.replace(/\s+/g, "").toLowerCase() === activeTab.replace(/\s+/g, "").toLowerCase()
          );
          if (!matchedDomain) return null;
    
          const attributes = matchedDomain.attributes;
          return {
            category_name: category.category_name,
            attributes: Object.keys(attributes).reduce((acc, attrKey) => {
              if (category[attrKey]) {
                const domainAttrValues = attributes[attrKey][0].split(",").map(value => value.trim());
                // const feedbackAttrValues = category[attrKey].map(val => val.split("_")[0].trim());
                // console.log("Prev:", feedbackAttrValues)
                // const feedbackAttrValues = category[attrKey][0].split(",").map(value => value.trim());
                
                // const feedbackAttrValues = category[attrKey].map(val => val.split("_")[0]).map(val => val.split(",")).flat().map(val => val.trim());
                const feedbackAttrValues = Array.isArray(category[attrKey]) 
                 ? category[attrKey].map(val => val.split("_")[0]).map(val => val.split(",")).flat().map(val => val.trim()): [];
                // console.log("feedbackAttrValues:", feedbackAttrValues)
                // console.log("domainAttrValues:", domainAttrValues)
    
                const options = domainAttrValues.map(value => ({ label: value, value }));
                // console.log("options:", options)
    
                acc[attrKey] = {
                  options,
                  // selected: options.filter(option => feedbackAttrValues.includes(option.value))
                  selected: options.filter(option => 
                    feedbackAttrValues.map(v => v.toLowerCase()).includes(option.value.toLowerCase())
                  )
                };
              }
              return acc;
            }, {})
          };
        }).filter(item => item !== null);
      };
      const domain = allDomain.find(d => d.domain_name === currentImage['domain']);
      const optionsData = getOptions(currentFeedbackData, domain);
      // console.log("optionsData:", typeof optionsData);
      // console.log("domain:", typeof domain)

    const constructFinalObject = () => {
      let finalObj = {};
    
      // Include all keys from editFeedbackOutput
      editFeedbackOutput?.forEach((data, index) => {
        Object.entries(data).forEach(([key, val]) => {
          finalObj[key] = formatValue(val, "edit");
        });
      });
    
      // Include all keys from selectedOptions for MultiSelect fields
      Object.entries(selectedOptions).forEach(([key, val]) => {
        finalObj[key] = (val as { value: any }[]).map(option => option.value);
      });
    
      return finalObj;
    };
    
    const finalObj = constructFinalObject();

    const handleFetchLatestConfigSetting = async () => {
      try{
        const response = await configService.getConfigSetting();
        setNewConfigSetting(response.configuration_data)
      }catch(err){
        console.log("err while getting config setting: ", err)
      }
    }

    // console.log("optionsData", optionsData);
    // console.log("domain:", domain)
 
    // console.log("isFeedBackPresent:", isFeedBackPresent)
    // console.log("isEditFeedExits:", isEditFeedbackPresent)
    // console.log("currentFeedbackData:", currentFeedbackData, "editFeedbackOutput:", editFeedbackOutput)
 
 
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
 
  return (
    <div>
        {/*------------------PAGE HEADER---------------*/}
        <Flex padding={"0px 0px 3px 0px"} alignItems={"center"} justifyContent={"space-between"} borderBottom={"0.5px solid #BDBDBD"} mt={{ base: "8px", md: "12px", lg: "auto" }} mb={{ base: "6px", md: "9px", lg: "12px" }} marginTop={{base:"140px",sm:"155px",md:"10px",lg:"auto"}}>  
            <Flex className="col-6" gap="12px" alignItems="center" textAlign="start" justifyContent="start">
              <Tooltip label={currentImage ? currentImage['original_image_name'] : "Image Details"} hasArrow>
                    <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="100%">
                        <h4>{currentImage ? currentImage['original_image_name'] : "Image Details"}</h4>
                    </Box>
              </Tooltip>
            </Flex>
            <Flex flex="1" padding={'0px 60px'} justifyContent="space-between" alignItems="center">
              <Button onClick={handlePrevious} variant="outline" colorScheme="blue" size="sm" isDisabled={currentImageIndex === 0}>
              <FaRegArrowAltCircleLeft className="mr-1" /> Prev
              </Button>
              <div className="image-footer d-inline-block mx-3 text-center">
                <span style={{ fontWeight: "600" }}>{currentImageIndex + 1}</span>
                <span style={{ margin: "0px 4px" }}>of</span>
                <span style={{ fontWeight: "600", marginRight: "4px" }}>{modelOutput.length}</span> images
              </div>
              <Button onClick={handleNext} variant="outline" colorScheme="blue" size="sm" isDisabled={1 + currentImageIndex === modelOutput.length}>
              Next <FaRegArrowAltCircleRight className="ml-1" />
              </Button>
          </Flex>
          <Flex className="col-1" justifyContent="center" cursor={"pointer"}>
                {/* <Button onClick={handleGoBack}  width="40px"  height="40px" borderRadius="50%" bgGradient="linear(to-r, #7ED0D8, #F90A8B)"  color="#FFFFFF" _hover={{ color: "#FFFFFF" }} > <span><RxCross1 /></span> </Button> */}
                <span onClick={handleGoBack}><RxCross1 size={23}/></span>
            </Flex>
            
        </Flex>
 
 
      {loadingOverlay && <LoadingOverlay message={"Loading"} />}
 
     
        {currentImage ? (
            <div className="table-container">
                <div className="row">
                    {modelOutput.length>1 &&(
                      <div className="col-1 sticky-component">
                      <ImageCarousel allImages={modelOutput} setSelectedRadioButton={setSelectedRadioButton} setCurrentImageIndex={setCurrentImageIndex} currImg={currentImageIndex}/>
                    </div>)
                    }
                    { loadingOverlay ? <Skeleton width={"20%"} height={"400px"}></Skeleton> : 
                    <div className="col-3 sticky-component imgZoomCon">
                        <ImageZoom src={currentImage["processed_image_url"]} zoomLevel={2.1}/>
                    </div>
                    }
                    <div className="col-8 scrollable-content">
                        {(configSetting?.Intelli_Tag || newConfigSetting?.Intelli_Search === true) ? 
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} defaultActiveKey="model_output" id="tag_category">
                            {currentImage['tags_model'] && currentImage['tags_model'].map((category, index) => {
                              // const domain = allDomain.find(d => d.domain_name === currentImage['domain']);
                              // const domainAttributes = domain?.categories.find(cat => cat.cat_name.replace(/[^a-zA-Z0-9\s]/g, " ").toUpperCase() === category.category_name.replace(/[^a-zA-Z0-9\s]/g, " ").toUpperCase())?.attributes;

                              return(
                                <Tab key={index} eventKey={category.category_name} title={category.category_name.replace(/[^a-zA-Z0-9\s]/g, " ").toUpperCase()} >
                                    <div className="headline">
                                        {/* RADIO BUTTON */}
                                        <Form>
                                            <div key={`inline-radio`}>
                                                <Form.Check inline label="Model Prediction" name="group1" type="radio" id={`inline-radio-2`} value="Model_Prediction" checked={selectedRadioButton === "Model_Prediction" } onChange={handleRadioChange} className={`boldLabel radio-css ${selectedRadioButton === "Model_Prediction"? "radio-color" : ""}`} />
                                               
                                                {// modelOutput[currentImageIndex]["review_status"] !== 0 ||
                                                isFeedBackPresent ? (
                                                <Form.Check inline label="User Feedback" type="radio" id={`inline-radio-3`} value="User_Feedback" checked={selectedRadioButton === "User_Feedback" } onChange={handleRadioChange} className={`boldLabel radio-css ${selectedRadioButton === "User_Feedback" ? "radio-color" : "" }`} />
                                                ) : ("")}
                                            </div>
                                        </Form>
                                        {/* EDIT BUTTON */}
                                        <div>
                                            {
                                    ((selectedRadioButton === 'User_Feedback' && isFeedBackPresent && (currentImage["review_status"] === 0)) && <Flex alignItems={'center'} justifyContent={"center"} gap={"12px"}>
                                        <Button size={"sm"} className={`${readOnlyModelOutput ? "red-border-btn": "green-border-btn" }`} variant="light"
                                          onClick={() => handleEditCancel()}>{readOnlyModelOutput ? "Cancel " : "Edit  "}
                                        </Button>
                                        {!readOnlyModelOutput && <Button size={"sm"} id="btn" onClick={() => handleUserFeedbackSubmit(index, 2)}>Approve</Button>}
                                       </Flex>
                                )}
                                 {((selectedRadioButton === 'Model_Prediction' && !isFeedBackPresent && (currentImage["review_status"] === 0)) &&
                                  <Flex alignItems={"center"} justify={"center"} gap={"10px"}>
                                     <Button size={"sm"} className={`${readOnlyModelOutput ? "red-border-btn" : "green-border-btn"  }`} variant="light"
                                      onClick={() =>handleEditCancel()}>{readOnlyModelOutput ? "Cancel " : "Edit  "}
                                    </Button>
                                    {!readOnlyModelOutput && <Button size={"sm"} id="btn" onClick={() => handleUserFeedbackSubmit(index, 2)}>Approve</Button>}
                                </Flex>
                                )
                                }
                                </div>
                                    </div>
                                    <div className="image-data-wrapper">
                                        {Object.entries(category)?.map(([key, val]) => {
                                          return(
                                            <div className="image-data" key={key}>
                                                <div className="attribute-name"><p>{transformLabel(key)}</p></div>
                                                <div className="attribute-value">
                                                    {/*---------WHEN MODEL PREDICTION RADIO BUTTON IS SELECTED*/}
                                                    {!readOnlyModelOutput && selectedRadioButton === "Model_Prediction" && (
                                                        Array.isArray(val) ? (
                                                            val.length ? (<p className="attribute">{val.join(", ")}</p>) :
                                                            (<p className="attribute greyed-out">{"Not Applicable"}</p>)
                                                        ) :
                                                        (<p className="attribute">{String(val)}</p>)
                                                    )}
 
                                                    {/*---------WHEN USER FEEDBACK RADIO BUTTON IS SELECTED IN NON EDITABLE MODE*/}
                                                    {!readOnlyModelOutput && selectedRadioButton === "User_Feedback" && (
                                                        <p className="attribute">{formatValue(currentFeedbackData?.[index]?.[key] || "")}</p>
                                                    )}
 
                                                    {/*---------WHEN EDITABLE MODE IS ON */}
                                                    {readOnlyModelOutput && (selectedRadioButton === "User_Feedback" || selectedRadioButton === "Model_Prediction") && (Array.isArray(val) ?
                                                    <>
                                                    {
                                                        isEditFeedbackPresent ? <>
                                                        {/* If the value is Array then create TAG INPUT */}
                                                        <TagsInput
                                                        value={formatValue(editFeedbackOutput[index]?.[key] || [], "edit")}
                                                        addOnBlur={true} addKeys={[9, 13, 188]} onChange={(event) => handleInputValueChange(event,key,index)}/>
                                                        </> : <>
                                                        {/* If the value is Array then create TAG INPUT */}
                                                        {/* <TagsInput
                                                        value={
                                                            editFeedbackOutput?.length !== 0
                                                              ? formatValue(editFeedbackOutput?.[index]?.[key] || "", "edit")
                                                              : formatValue(currentFeedbackData[index]?.[key] || "", "edit")
                                                          }
                                                        addOnBlur={true} addKeys={[9, 13, 188]} onChange={(event) => handleInputValueChange(event,key,index)}/> */}
                                                       {
                                                        !optionsData.find(opt => opt.category_name.toLowerCase() === category.category_name.toLowerCase())?.attributes[key] &&(

                                                          <TagsInput
                                                        value={
                                                            editFeedbackOutput?.length !== 0
                                                              ? formatValue(editFeedbackOutput?.[index]?.[key] || "", "edit")
                                                              : formatValue(currentFeedbackData[index]?.[key] || "", "edit")
                                                          }
                                                        addOnBlur={true} addKeys={[9, 13, 188]} onChange={(event) => handleInputValueChange(event,key,index)}/>
                                                        )
                                                       }
                                                        
                                                       {/* {optionsData.find(opt => opt.category_name === category.category_name)?.attributes[key] && (
                                <MultiSelect
                                  options={optionsData.find(opt => opt.category_name === category.category_name).attributes[key].options}
                                  value={selectedOptions[key] || optionsData.find(opt => opt.category_name === category.category_name).attributes[key].selected}
                                  onChange={(selected) => handleMultiSelectChange(key, selected)}
                                  labelledBy={`Select ${key}`}
                                  className={MultiSelectStyle.multi_selecet_input}
                                />
                              )} */}
                              {optionsData.find(opt => opt.category_name.toLowerCase() === category.category_name.toLowerCase())?.attributes[key] && (
                                <MultiSelect
                                  options={optionsData.find(opt => opt.category_name.toLowerCase() === category.category_name.toLowerCase()).attributes[key].options}
                                  value={selectedOptions[key] || optionsData.find(opt => opt.category_name.toLowerCase() === category.category_name.toLowerCase()).attributes[key]?.selected}
                                  onChange={(selected) => handleMultiSelectChange(key, selected,index)}
                                  labelledBy={`Select ${key}`}
                                  className={MultiSelectStyle.multi_selecet_input}
                                />
                              )}
                                                        </>
                                                    }
                                                   
                                                        </>
                                                        : (
                                                        /*If the value is Array then create TEXT AREA*/
                                                        <Form.Control as="textarea" disabled={key === "category_name"} rows={1}
                                                        value={
                                                            editFeedbackOutput.length !== 0 
                                                              ? formatValue(editFeedbackOutput[index]?.[key] || "", "edit")
                                                              : formatValue(currentFeedbackData[index]?.[key] || "", "edit")
                                                          }
                                                             onChange={(event) => handleInputValueChange(event.target.value,key,index)}
                                                              style={{width: "100%",marginBottom: "10px",}}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                    {((readOnlyModelOutput) && (currentImage["review_status"] === 0)) &&(
                                        <Flex w={"100%"} mt={"15px"} alignItems={"center"} gap={"10px"} justifyContent={"center"}>
                                        <Button onClick={() => {handleUserFeedbackSubmit(index,0);}}
                                          size={"sm"} id="btn">Save</Button>
                                          <Button id="btn" size={"sm"} onClick={() => handleUserFeedbackSubmit(index, 1)}>Save & Approve</Button>
                                        </Flex>
                                    )}
                                </Tab>)
                             })}
                       
                        <Tab eventKey="metadata" title="META DATA" className="metadatatab">
                          <div className="image-data-wrapper">{currentImage && <Metadata currentImage={currentImage}/>}</div>
                         </Tab>
                        </Tabs> :
                        <Tabs activeKey='metadata' onSelect={() => setActiveTab('metadata')} defaultActiveKey="metadata" id="tag_category">
                        <Tab eventKey="metadata" title="META DATA" className="metadatatab">
                          <div className="image-data-wrapper">{currentImage && <Metadata currentImage={currentImage}/>}</div>
                         </Tab>
                        </Tabs>
                        }

                    </div>
                </div>
            </div>
        ) : (<h6> Fetching Data</h6>
        )}
    </div>
  );
};
 
export default ModelOutputContainer;
 