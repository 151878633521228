import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class viewOutput {

  viewModelOutput = (payload) => {
    return axiosInstance
      .post("/upload/get_tag_data",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  updateTagFeedback = (payload) => {
    return axiosInstance
      .post("/upload/update_tag_feedback",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

}

export default new viewOutput();
