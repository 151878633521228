/**
 * owner : retrAIver
 * author : Arpitha from Affine
 */
import axios from "axios";
import { axiosInstance } from "./intreceptor";

let cache = {};
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class useDepartmentService {
  createDepartment = (user_info) => {
    return axiosInstance
      .post("/department/createdepartment", user_info)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  updateDepartment = (user_info) => {
    return axiosInstance
      .post("/department/updatedepartment", user_info)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  changeDepartmentStatus = (user_info) => {
    return axiosInstance
      .post("/department/changedepartmentstatus", user_info)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  getDepartment = () => {
    return axiosInstance
      .get("/department/getdepartments")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  getActiveDepartment = () => {
    return axiosInstance
      .get("/department/getactivedepartments")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export default new useDepartmentService();
