import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiTrash, BiPencil } from "react-icons/bi";
import "./taxonomy.css";
import { v4 as uuidv4 } from "uuid"; // Importing UUID generator
import { trackPromise } from "react-promise-tracker";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import taxonomyService from "../../api/taxonomyService";
import { NavLink, useNavigate } from "react-router-dom";
import { interceptorsSetup } from "../../api/intreceptor";
import { Box, Button, Flex, FormLabel, useToast } from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import MultiSelectStyle from "../../assets/styles/CreateDepartment.module.css";
import { FiPlus } from "react-icons/fi";
import departmentService from "../../api/departmentService";
import PromptEditor from "./components/promptEditor";
import 'react-quill/dist/quill.snow.css';
import { RxCross1 } from "react-icons/rx";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

const stripHtmlTags = (html) => {
	return html
		.replace(/<p[^>]*>/g, ' ')
		.replace(/<\/p>/g, ' ')
		.replace(/<div[^>]*>/g, '')
		.replace(/<\/div>/g, '')
		.replace(/<br\s*\/?>/g, ' ')
		.replace(/\s{2,}/g, ' ')
		.replace(/<ol[^>]*>/g, ' ')
		.replace(/<\/ol>/g, ' ')
		.replace(/<li[^>]*>/g, ' ')
		.replace(/<\/li>/g, ' ')
		.replace(/<ul[^>]*>/g, ' ')
		.replace(/<\/ul>/g, ' ')
		.replace(/<ul[^>]*>/g, ' ')
		.replace(/<\/ul>/g, ' ')
		.replace(/<strong[^>]*>/g, ' ')
		.replace(/<\/strong>/g, ' ')
		.replace(/<em[^>]*>/g, ' ')
		.replace(/<\/em>/g, ' ')
		.replace(/<u[^>]*>/g, ' ')
		.replace(/<\/u>/g, ' ')
		.trim();
};

const Taxonomy = React.memo(() => {
	const [lgShow, setLgShow] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loaderMessage, setLoaderMessage] = useState("Fetching Domains...");
	const [domainsData, setDomainsData] = useState({
		domains: [],
		categories: [],
	});
	const [domainOpen, setDomainOpen] = useState(true);
	const [categoryOpen, setCategoryOpen] = useState(true);
	const [defaultselectedCategories, defaultsetSelectedCategories] = useState(
		[]
	);
	const [show, setShow] = useState(false);
	const [categoryName, setCategoryName] = useState("");
	const [showCategoryModal, setShowCategoryModal] = useState(false);
	const [selectedDomain, setSelectedDomain] = useState(null);
	const [selectedDomainData, setSelectedDomainData] = useState(null);
	const [mode, setMode] = useState("add");
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [attributes, setAttributes] = useState([]);
	const [domainNameModal, setDomainNameModal] = useState("");
	const [selectedCategoryData, setSelectedCategoryData] = useState(null);
	const [editCategoryName, setEditCategoryName] = useState("");
	const [domainDesc, setDomainDesc] = useState(null);
	const [domainPrompt, setDomainPrompt] = useState(null);
	const [activeDepartments, setActiveDepartments] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	const { fetchConfigSetting } = useFetchConfigSetting()
	const toast = useToast();

	const navigate = useNavigate();

	useEffect(() => {
		interceptorsSetup(navigate);
	}, [navigate]);

	useEffect(() => {
		fetchConfigSetting()
		getAllDomains();
	}, []);

	const getDepartments = async () => {
		try {
			const res = await departmentService.getActiveDepartment();
			if (res.status === 200) {
				setActiveDepartments(res.data.departments);
			}
		} catch (err) {
			console.log("err:", err);
		}
	};
	const getAllDomains = () => {
		setLoading(true);
		trackPromise(
			taxonomyService
				.getTaxonomy()
				.then((response) => {
					if (response.status === 200) {
						const data = response.data.taxnonmies;
						const processedData = processData(data);
						setDomainsData(processedData);
						setLoading(false);
						getDepartments();
					}
				})
				.catch((err) => {
					setLoading(false);
					// Handle error
				})
		);
	};
	const processData = (data) => {
		if (!data || data.length === 0) {
			return { domains: [], categories: [] };
		}

		const domains = data.map((item) => ({
			domain_id: item.id,
			id: item.id,
			domain: item.domain_name,
			category_count: item.category_count,
			attributes_count: item.attributes_count,
			department_ids: item.department_ids,
			domain_description: item.domain_description,
			domain_prompt: item.domain_prompt,
			domain_prompt_custom: item.domain_prompt_custom,
			domain_name: item.domain_name,
			categories: item.categories.map((category) => ({
				cat_id: category.cat_id,
				cat_name: category.cat_name,
				attributes: category.attributes, // Assuming attributes are directly nested within the category object
			})),
		}));

		const categories = domains.reduce((acc, domain) => {
			domain.categories.forEach((category) => {
				acc.push(category);
			});
			return acc;
		}, []);
		return {
			domains: domains,
			categories: categories,
		};
	};

	const handleDomainClick = (domain) => {
		setSelectedDomain(domain);
		setDomainNameModal(domain.domain);
		setDomainDesc(domain.domain_description)
		// setDomainPrompt(domain.domain_prompt)
		setDomainPrompt(domain.domain_prompt_custom)
		defaultsetSelectedCategories(domain.categories || []);

		const newSelectedCategory = domain.categories.find(
			(cat) => cat.cat_id === selectedCategory?.cat_id
		);

		if (newSelectedCategory) {
			setSelectedCategory(newSelectedCategory);

			if (Array.isArray(newSelectedCategory.attributes)) {
				setAttributes(newSelectedCategory.attributes);
			} else {
				setAttributes([]);
			}
		} else {
			setSelectedCategory(null);
			setAttributes([]);
		}
	};

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		if (category.attributes) {
			const attributeArray = Object.entries(category.attributes).map(
				([attribute, value]) => ({
					attribute: attribute,
					value: value.join(", "),
				})
			);
			setAttributes(attributeArray);
		} else {
			setAttributes([]);
		}
	};

	useEffect(() => {
		if (domainsData && domainsData.domains && domainsData.domains.length > 0) {
			// Check if the selected domain exists in the fetched data
			const selectedDomainExists = domainsData.domains.some(
				(domain) => domain.domain_id === selectedDomain?.domain_id
			);

			// If the selected domain exists in the fetched data, update selected domain and category
			if (selectedDomainExists) {
				const selectedDomainData = domainsData.domains.find(
					(domain) => domain.domain_id === selectedDomain.domain_id
				);
				setSelectedDomain(selectedDomainData);
				// Refresh all categories when domains data changes
				defaultsetSelectedCategories(selectedDomainData.categories || []);

				// Set attributes for the first category of the selected domain if a category is selected
				if (selectedCategory) {
					const firstCategory = selectedDomainData.categories.find(
						(category) => category.cat_id === selectedCategory.cat_id
					);
					if (firstCategory && firstCategory.attributes) {
						const attributeArray = Object.entries(firstCategory.attributes).map(
							([attribute, value]) => ({
								attribute: attribute,
								value: value.join(", "),
							})
						);
						setAttributes(attributeArray);
					} else {
						setAttributes([]);
					}
				} else {
					// If no category is selected, do not set attributes
					setAttributes([]);
				}
			} else {
				// If the selected domain does not exist in the fetched data, select the first domain and its first category
				const firstDomain = domainsData.domains[0];
				defaultsetSelectedCategories(firstDomain.categories || []);
				setSelectedDomain(firstDomain);
				// setSelectedCategory(firstDomain.categories[0]);
				const attributeArray = (
					firstDomain.categories[0]?.attributes
						? Object.entries(firstDomain.categories[0].attributes)
						: []
				).map(([key, value]) => ({ attribute: key, value: value.join(", ") }));
				setAttributes(attributeArray);
			}
		}
	}, [
		domainsData,
		selectedDomain,
		selectedCategory,
		defaultselectedCategories,
	]);

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = (domain) => {
		if (domain) {
			setMode("edit");
			setSelectedDomainData(domain);
			setDomainNameModal(domain.domain);
			setDomainDesc(domain.domain_description)
			// setDomainPrompt(domain.domain_prompt)
			setDomainPrompt(domain.domain_prompt_custom)
			const preSelectedOptions = departmentOptions?.filter(option =>
				domain?.department_ids?.includes(option.value)
			);
			setSelectedIds(preSelectedOptions || []);
		} else {
			setMode("add");
			setSelectedDomainData(null);
			setDomainNameModal("");
			setDomainDesc("")
			setDomainPrompt("")
			setSelectedIds([])
		}
		setShow(true);
	};

	const handleSaveDomain = () => {
		if (mode === "add") {
			if (!(domainNameModal.length > 0 && domainDesc.length > 0 && departments_ids.length > 0)) {
				return (
					toast({
						title: "Required fields are mandatory.",
						position: "top",
						status: "warning",
						duration: 2000,
						variant: "left-accent",
						isClosable: true,
					})
				)
			}

			const cleanDomainPrompt = stripHtmlTags(domainPrompt);
			const newDomain = {
				domain_id: uuidv4(),
				domain_name: domainNameModal,
				domain_prompt: cleanDomainPrompt,
				domain_description: domainDesc,
				department_ids: departments_ids,
				domain_prompt_custom: domainPrompt
			}

			setLoading(true)
			taxonomyService.createDomain(newDomain)
				.then((response) => {
					setDomainsData((prevState) => ({
						...prevState,
						domains: [...prevState.domains, newDomain],
					}));
					setSelectedDomain(newDomain);
					setLgShow(false);
					toast({
						title: `${response.data.message}`,
						position: "top",
						status: "success",
						duration: 2000,
						variant: "left-accent",
						isClosable: true,
					})
					getAllDomains(); // Set the newly added domain as selected
					handleClose();
				})
				.catch((err) => {
					toast({
						title: `Something went wrong.`,
						position: "top",
						status: "error",
						duration: 2000,
						variant: "left-accent",
						isClosable: true,
					})
					console.log("create domain err:", err);
				})
				.finally(() => {
					setLoading(false)
				})
		} else if (mode === "edit") {
			if (
				domainsData.domains.some(
					(d) =>
						d.domain.toLowerCase() === domainNameModal.toLowerCase() &&
						d.domain_id !== selectedDomainData?.domain_id
				)
			) {
				return (
					toast({
						title: "Domain name already exists!",
						position: "top",
						status: "warning",
						duration: 2000,
						variant: "left-accent",
						isClosable: true,
					})
				)
			}
			const cleanDomainPrompt = stripHtmlTags(domainPrompt);
			const updateDomain = {
				...selectedDomainData,
				domain_name: domainNameModal,
				domain_prompt: cleanDomainPrompt,
				domain_description: domainDesc,
				department_ids: departments_ids,
				id: selectedDomainData.id,
				domain_prompt_custom: domainPrompt
			}
			setLoading(true);
			taxonomyService.updateDomain(updateDomain)
				.then((response) => {
					if (response.status === 200) {

						setDomainsData((prevState) => ({
							...prevState,
							domains: prevState.domains.map((domain) =>
								domain.domain_id === updateDomain.domain_id
									? updateDomain
									: domain
							),
						}));
						setLgShow(false);
						toast({
							title: `${response.data.new.message}`,
							position: "top",
							status: "success",
							duration: 2000,
							variant: "left-accent",
							isClosable: true,
						})
						setSelectedDomain(updateDomain); // Keep the updated domain as selected
					}
				})
				.catch((err) => {
					console.log("update domain err:", err);
				})
				.finally(() => {
					handleClose();
					setLoading(false)
				})
		}
	};

	const handleCloseCategoryModal = () => {
		setShowCategoryModal(false);
		setCategoryName("");
	};

	const handleShowCategoryModal = (mode, category) => {
		setMode(mode); // Set the mode (add or edit)
		setSelectedCategoryData(category); // Set the selected category data
		setShowCategoryModal(true); // Open the category modal
	};

	const handleEditCategory = (category) => {
		setSelectedCategoryData(category);
		setMode("edit");
		setShowCategoryModal(true);
		setEditCategoryName(category.cat_name); // Set the category name being edited
	};

	const handleAddCategory = () => {
		if (!selectedDomain) {
			return (
				toast({
					title: `No domain selected`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		if (!editCategoryName) {
			return (
				toast({
					title: `Please enter a category name.`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		if (
			defaultselectedCategories.some(
				(c) => c.cat_name.toLowerCase() === editCategoryName.toLowerCase()
			)
		) {
			return (
				toast({
					title: `Category name already exists in this domain !`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		const newCategory = {
			domain_name: selectedDomain.domain,
			domain_id: selectedDomain.domain_id,
			id: selectedDomain.domain_id,
			categories: [
				{
					cat_id: uuidv4(),
					cat_name: editCategoryName,
					attributes: {},
				},
			],
		};

		if (mode === "add") {
			setEditCategoryName("");
			setLoading(true);
			trackPromise(
				taxonomyService
					.createCategory({ id: newCategory.id, cat_name: editCategoryName, attributes: {} })
					.then((response) => {
						setLgShow(false);
						// alert(response.data.message);
						if (response.status === 200) {
							toast({
								title: `${response.data.message}`,
								position: "top",
								status: "success",
								duration: 2000,
								variant: "left-accent",
								isClosable: true,
							})
							getAllDomains();
							// setEditCategoryName('');
							handleCloseCategoryModal();
							setShowCategoryModal(false);
						}
					})
					.catch((err) => {
						console.log("create category err: ", err)
					})
					.finally(() => {
						setLoading(false)
					})
			);
		} else if (mode === "edit") {
			const updateCategory = {
				domain_name: selectedDomain.domain,
				domain_id: selectedDomain.domain_id,
				categories: [
					{
						cat_id: selectedCategory.cat_id,
						cat_name: editCategoryName,
						attributes: {},
					},
				],
			};
			setLoading(true)
			trackPromise(
				taxonomyService.updateCategory({ id: selectedDomain.domain_id, cat_id: selectedCategory.cat_id, cat_name: editCategoryName })
					.then((response) => {
						setLgShow(false);
						// alert(response.data.message);
						if (response.status === 200) {
							toast({
								title: `${response.data.new.message}`,
								position: "top",
								status: "success",
								duration: 2000,
								variant: "left-accent",
								isClosable: true,
							})
							getAllDomains();
							setEditCategoryName("");
						}
						handleCloseCategoryModal();
						setShowCategoryModal(false);
					})
					.catch((err) => {
						console.log("update category err: ", err)
					})
					.finally(() => {
						setLoading(false)
					})
			);
		}
	};

	const handleInputChange = (index, key, value) => {
		const updatedAttributes = [...attributes];
		const updatedAttribute = { ...updatedAttributes[index] };
		updatedAttribute[key] = value;
		updatedAttributes[index] = updatedAttribute;
		setAttributes(updatedAttributes);
	};

	const handleAddAttribute = () => {
		setAttributes((prevAttributes) => [
			...prevAttributes,
			{ name: "", value: "" },
		]);
	};

	const handleRemoveAttribute = (index) => {
		setAttributes((prevAttributes) =>
			prevAttributes.filter((_, i) => i !== index)
		);
	};

	const handleSaveAttributes = () => {
		if (!selectedCategory || !selectedCategory.cat_id) {
			// alert("Please select a category!");
			return (
				toast({
					title: `Please select a category!`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		const attributeNames = attributes.map((attr) => attr.attribute);
		const lowerCaseAttributeNames = attributeNames.map((attr) =>
			(attr?.trim() ?? "").toLowerCase()
		);

		const upperCaseAttributeNames = attributeNames.map((attr) =>
			(attr?.trim() ?? "").toUpperCase()
		);

		const uniqueAttributeNames = new Set([
			...lowerCaseAttributeNames,
			...upperCaseAttributeNames,
		]);

		const hasDuplicates =
			uniqueAttributeNames.size !==
			lowerCaseAttributeNames.length + upperCaseAttributeNames.length;

		if (
			attributes.some(
				(attr) =>
					!attr.attribute ||
					!attr.value ||
					typeof attr.attribute.trim !== "function" ||
					typeof attr.value.trim !== "function" ||
					!attr.attribute.trim() ||
					!attr.value.trim()
			)
		) {
			// alert("Attribute names and values cannot be empty!");
			return (
				toast({
					title: `Attribute names and values cannot be empty !`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		if (hasDuplicates) {
			// alert("Duplicate attribute names are not allowed!");
			return (
				toast({
					title: `Duplicate attribute names are not allowed !`,
					position: "top",
					status: "warning",
					duration: 2000,
					variant: "left-accent",
					isClosable: true,
				})
			)
		}

		const formattedAttributes = {};
		attributes.forEach((attribute) => {
			const trimmedAttr = attribute.attribute.trim();
			const trimmedValue = attribute.value.trim();
			if (formattedAttributes[trimmedAttr]) {
				formattedAttributes[trimmedAttr].push(trimmedValue);
			} else {
				formattedAttributes[trimmedAttr] = [trimmedValue];
			}
		});

		const formatteddata = {
			domain_id: selectedDomain.domain_id,
			domain_name: selectedDomain.domain,
			categories: [
				{
					cat_id: selectedCategory.cat_id,
					cat_name: selectedCategory.cat_name,
					attributes: formattedAttributes,
				},
			],
		};

		setLoading(true);
		trackPromise(
			taxonomyService.createAttributes({ id: selectedDomain.domain_id, cat_id: selectedCategory.cat_id, attributes: formattedAttributes })
				.then((response) => {
					// alert(response.data.message);
					if (response.status === 200) {
						getAllDomains();
						toast({
							title: `${response.data.message}`,
							position: "top",
							status: "success",
							duration: 2000,
							variant: "left-accent",
							isClosable: true,
						})
					}
				})
				.catch((err) => {
					console.log("create attribute err: ", err)
				})
				.finally(() => {
					setLoading(false)
				})
		);
	};

	const handleDeleteDomain = (domain) => {
		const confirmDelete = window.confirm(
			`Are you sure you want to delete the ${domain.domain} domain?\nDeleting the ${domain.domain} domain will delete all its associated categories and attributes.`
		);
		if (confirmDelete) {
			setLoading(true);
			taxonomyService.deleteDomain({ id: `${domain.domain_id}`, status: 0 })
				.then((response) => {
					getAllDomains();
				})
				.catch((err) => {
					console.log("delete domain err:", err)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			// User cancelled the deletion, do nothing
		}
	};

	const handleDeleteCategory = (domain, category) => {
		const confirmDelete = window.confirm(
			`Are you sure you want to delete the ${category.cat_name} Category ?\nDeleting the ${category.cat_name} Category will delete all the associated attributes.`
		);
		if (confirmDelete) {
			setLoading(true);
			trackPromise(
				taxonomyService
					.deleteCategory({ id: domain.domain_id, cat_id: category.cat_id })
					.then((response) => {
						// alert(response.data.message);
						if (response.status === 200) {
							toast({
								title: `${response.data.message}`,
								position: "top",
								status: "success",
								duration: 2000,
								variant: "left-accent",
								isClosable: true,
							})
						}
						getAllDomains();
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
					})
			);
		} else {
			console.log("User cancellled the delete");
		}
	};

	const handleSelectionChange = (selectedOptions) => {
		setSelectedIds(selectedOptions);
	};

	const departmentOptions = activeDepartments?.map((department) => ({
		label: `${department?.department_name} (${department?.department_code})`,
		value: department?.id,
	}));

	const departments_ids = selectedIds.map((option) => option.value);
	return (
		<>
			<Flex
				padding={"0px 0px 3px 0px"}
				alignItems={"center"}
				justifyContent={"space-between"}
				borderBottom={"0.5px solid #BDBDBD"}
				mt={{ base: "8px", md: "12px", lg: "auto" }}
				mb={{ base: "10px", md: "17px", lg: "25px" }}
			>
				<Flex gap={"12px"} alignItems={"center"}>
					<h2>Taxonomy</h2>
				</Flex>
				<NavLink to="/layout" className="crossBtn">
					<span><RxCross1 /></span>
				</NavLink>
			</Flex>
			<div className="sidebar">
				{loading && <LoadingOverlay message={loaderMessage} />}
				<div className={`section domain ${domainOpen ? "" : "closed"}`}>
					<Flex justifyContent={"space-between"}>
						<h5>Domain</h5>
						<Button
							width="27px"
							height="27px"
							borderRadius="25px"
							bgGradient="linear(to-r, #F90A8B, #F90A8B)"
							color="#FFFFFF"
							_hover={{
								color: "#FFFFFF",
							}}
							fontSize="17px"
							onClick={() => handleShow()}
						>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<FiPlus />
							</Flex>
						</Button>
					</Flex>
					<div className="separator"></div>

					<table className="table">
						<thead>
							<tr
								className="domain-con-default"
								style={{ borderBottom: "1px solid #DEE2E6" }}
							>
								<th
									style={{
										background: "#FFFFFF",
										fontWeight: "bold",
										fontSize: "14px",
										color: "black",
										width: "160px",
									}}
								>
									Domain
								</th>
								<th
									style={{
										background: "#FFFFFF",
										fontWeight: "bold",
										fontSize: "14px",
										color: "black",
										//   width: "160px",
										float: "right",
										borderBottom: "none",
									}}
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{domainsData.domains.map((domain) => (
								<tr
									key={domain.domain_id}
									onClick={() => handleDomainClick(domain)}
									className={
										selectedDomain &&
											selectedDomain.domain_id === domain.domain_id
											? "selected"
											: "domain-con-default"
									}
									style={{ borderBottom: "1px solid #DEE2E6" }}
								>
									<td style={{ fontSize: "12px" }} className={selectedDomain?.domain_id === domain?.domain_id ? "selectedDomainAttr" : ""}>{domain.domain}</td>
									<td style={{ display: "flex", float: "right", gap: "7px", borderBottom: "none" }}>
										<BiPencil onClick={() => handleShow(domain)} />
										<BiTrash
											color={
												selectedDomain &&
													selectedDomain.domain_id === domain.domain_id
													? `red`
													: "#ff6363"
											}
											style={{ cursor: "pointer" }}
											title="delete domain"
											onClick={() => handleDeleteDomain(domain)}
										></BiTrash>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className={`section category ${categoryOpen ? "" : "closed"}`}>
					<Flex justifyContent={"space-between"}>
						<h5>Category</h5>
						<Button
							width="27px"
							height="27px"
							borderRadius="25px"
							bgGradient="linear(to-r, #F90A8B, #F90A8B)"
							color="#FFFFFF"
							_hover={{
								color: "#FFFFFF",
							}}
							fontSize="17px"
							onClick={() => handleShowCategoryModal("add")}
						>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<FiPlus />
							</Flex>
						</Button>
					</Flex>
					<div className="separator"></div>

					<table className="table">
						<thead>
							<tr
								className="domain-con-default"
								style={{ borderBottom: "1px solid #DEE2E6" }}
							>
								<th
									style={{
										background: "#FFFFFF",
										fontWeight: "bold",
										fontSize: "14px",
										color: "black",
										width: "160px",
									}}
								>
									Category
								</th>
								<th
									style={{
										background: "#FFFFFF",
										fontWeight: "bold",
										fontSize: "14px",
										color: "black",
										//   width: "160px",
										float: "right",
										borderBottom: "none",
									}}
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{defaultselectedCategories.map((category) => (
								<tr
									key={category.cat_id}
									onClick={() => handleCategoryClick(category)}
									className={
										selectedCategory &&
											selectedCategory.cat_id === category.cat_id
											? "selected"
											: "domain-con-default"
									}
									style={{ cursor: "pointer", borderBottom: "1px solid #DEE2E6" }}
								>
									<td style={{ fontSize: "12px" }} className={selectedCategory?.cat_id === category?.cat_id ? "selectedDomainAttr" : ""}>{category.cat_name}</td>
									<td style={{ display: "flex", float: "right", gap: "7px", borderBottom: "none" }}>
										<BiPencil onClick={() => handleEditCategory(category)} />

										<BiTrash
											color={
												selectedCategory &&
													selectedCategory.cat_id === category.cat_id
													? `red`
													: "#ff6363"
											}
											style={{ cursor: "pointer" }}
											title="delete category"
											onClick={() =>
												handleDeleteCategory(selectedDomain, category)
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="Attrsection attributes">
					<div className="attrHead">
						<h5>Attributes</h5>
						<div className="Attrseparator"></div>
					</div>

					<div className="fixed-header">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ background: "#FFFFFF", fontWeight: "bold", fontSize: "14px", color: "black", width: "25%" }}>Attribute</th>
									<th style={{ background: "#FFFFFF", fontWeight: "bold", fontSize: "14px", color: "black", width: "60%" }}>Description</th>
									<th style={{ background: "#FFFFFF", fontWeight: "bold", fontSize: "14px", color: "black", width: "15%" }}>Action</th>
								</tr>
							</thead>
						</table>
					</div>

					<div className="attributes-content">
						<table className="table table-bordered">
							<tbody>
								{attributes.map((attribute, index) => (
									<tr key={index}>
										<td style={{ fontSize: "12px", width: "25%" }}>
											<input type="text" className="form-control" value={attribute.attribute} onChange={(e) => handleInputChange(index, "attribute", e.target.value)} />
										</td>
										<td style={{ fontSize: "12px", width: "60%" }}>
											<input type="text" className="form-control" value={attribute.value} onChange={(e) => handleInputChange(index, "value", e.target.value)} />
										</td>
										<td style={{ color: "red", width: "15%" }}>
											<BiTrash style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => handleRemoveAttribute(index)} title="remove row" />
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{selectedCategory && selectedCategory.cat_id && (
							<>
								<Button width="27px" height="27px" borderRadius="25px" bgGradient="linear(to-r, #F90A8B, #F90A8B)" color="#FFFFFF" _hover={{ color: "#FFFFFF" }} fontSize="17px" onClick={handleAddAttribute}>
									<Flex alignItems={"center"} justifyContent={"center"}>
										<FiPlus />
									</Flex>
								</Button>

								<div className="submit-btn" style={{ textAlign: "center" }}>
									<Button variant={"outline"} id="btn" size={"md"} disabled={loading} onClick={handleSaveAttributes}> Submit </Button>
								</div>
							</>
						)}
					</div>
				</div>




				<Modal show={show} onHide={handleClose} animation={false} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="modal-header-text">
							{mode === "edit" ? "Edit Domain" : "Add Domain"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Box display={"flex"} flexDir={"column"} gap={"12px"}>
							<Flex
								flexDir={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
							>
								<FormLabel>
									Domain Name {mode !== 'edit' && <span style={{ color: "red" }}>*</span>}
								</FormLabel>
								<input
									type="text"
									className="inp_field"
									placeholder="Domain Name"
									value={domainNameModal} // Use domainNameModal here
									onChange={(e) => setDomainNameModal(e.target.value)} // Update domainNameModal
								/>
							</Flex>
							<Flex
								flexDir={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
							>
								<FormLabel>
									Department {mode !== 'edit' && <span style={{ color: "red" }}>*</span>}
								</FormLabel>
								<MultiSelect
									options={departmentOptions}
									value={selectedIds}
									onChange={handleSelectionChange}
									labelledBy="Select Departments"
									className={MultiSelectStyle.multi_selecet_input}
								/>
							</Flex>
							<Flex
								flexDir={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
							>
								<FormLabel>
									Domain Description {mode !== 'edit' && <span style={{ color: "red" }}>*</span>}
								</FormLabel>
								<input
									type="text"
									className="inp_field"
									value={domainDesc} // Use domainNameModal here
									onChange={(e) => setDomainDesc(e.target.value)} // Update domainNameModal
								/>
							</Flex>
							<Flex
								flexDir={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
							>
								<FormLabel>Custom Prompt</FormLabel>
								<PromptEditor domainPrompt={domainPrompt} setDomainPrompt={setDomainPrompt} />
							</Flex>
						</Box>
					</Modal.Body>
					<Modal.Footer>
						<Flex
							w={"100%"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"10px"}
						>
							<Button disabled={loading} id="btn" size={"sm"} onClick={handleSaveDomain}>
								{mode === "edit" ? `${!loading ? "Save" : "Loading..."}` : `${!loading ? 'Submit' : "Loading..."}`}
							</Button>
							<Button
								colorScheme={"red"}
								variant={"outline"}
								size={"sm"}
								onClick={handleClose}
							>
								Close
							</Button>
						</Flex>
					</Modal.Footer>
				</Modal>
				<Modal
					show={showCategoryModal}
					onHide={handleCloseCategoryModal}
					animation={false}
				>
					<Modal.Header closeButton>
						<Modal.Title className="modal-header-text">
							{mode === "edit" ? "Edit Category" : "Add Category"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Box>
							<Flex
								flexDir={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
							>
								<FormLabel>
									Category Name {mode !== 'edit' && <span style={{ color: "red" }}>*</span>}
								</FormLabel>
								<input
									className="inp_field"
									type="text"
									name="category"
									value={editCategoryName} // Use the new state for input value
									onChange={(e) => setEditCategoryName(e.target.value)} // Update the new state
								/>
							</Flex>
						</Box>
					</Modal.Body>
					<Modal.Footer>
						<Flex
							width={"100%"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"10px"}
						>
							<Button
								id="btn"
								size={"sm"}
								disabled={loading}
								onClick={handleAddCategory}
							>
								{mode === "edit" ? "Save" : "Add"}
							</Button>
							<Button
								variant={"outline"}
								size={"sm"}
								onClick={handleCloseCategoryModal}
								colorScheme={"red"}
							>
								Close
							</Button>
						</Flex>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
});

export default Taxonomy;
