import { Grid, Skeleton as SkeletonLoader } from "@chakra-ui/react";
import React from "react";

const Skeleton = () => {
  const skeletonArray = Array.from({ length: 12 });

  return (
    <Grid
      gridTemplateColumns={{
        base: "repeat(1,auto)",
        sm: "repeat(3,200px)",
        md: "repeat(4,1fr)",
        lg: "repeat(6,1fr)",
      }}
      margin={"auto"}
      gap={{ base: "10px", md: "12px", lg: "17px" }}
      mt={"40px"}
      justifyContent={"center"}
    >
      {skeletonArray.map((_, index) => (
        <SkeletonLoader key={index} height="260px" borderRadius={"10px"}/>
      ))}
    </Grid>
  );
};

export default Skeleton;
