import { Flex, Grid, Text } from '@chakra-ui/react'
import React from 'react'
import './ImageZoom.css'

const Metadata = ({ currentImage }) => {
	return (
		<Grid gap={1} templateColumns="max-content 1fr" alignItems="center" fontSize={"11px"}>
			<Text fontWeight={"400"} lineHeight={"18px"}>IMAGE NAME</Text>
			<Text className='detail'>{currentImage.original_image_name}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}>IMAGE DESCRIPTION</Text>
			<Text className='detail'>{currentImage['image_description']}</Text>

			{/* <Text fontWeight={"400"} lineHeight={"18px"}>SCOPE</Text>
			<Text>{currentImage['scope'] === 0 ? "Private" : "Public"}</Text> */}

			<Text fontWeight={"400"} lineHeight={"18px"}>FAVOURITE</Text>
			<Text className='detail'>{currentImage['is_favourite'] ? "Yes" : "No"}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}>UPLOADED ON</Text>
			<Text className='detail'>{new Date(currentImage['created_at']).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }).replace(/\//g, "-")}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}>UPLOADED BY</Text>
			<Text className='detail'>{currentImage['created_by']}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}> {currentImage?.time_taken && 'TAG PROCESSING TIME'}</Text>
			<Text className='detail'>{currentImage?.['time_taken'] ? `${currentImage.time_taken} sec` : ''}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}>{currentImage?.tag_generated_on && 'TAG GENERATE ON'}</Text>
			<Text className='detail'>{currentImage?.tag_generated_on && new Date(currentImage['tag_generated_on']).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }).replace(/\//g, "-")}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}>{currentImage?.tag_generated_by && 'TAG GENERATE BY'}</Text>
			<Text className='detail'>{currentImage?.['tag_generated_by']}</Text>

			<Text>{currentImage?.['reviewed_on'] && 'REVIEWED ON'}</Text>
			<Text className='detail'>{currentImage?.reviewed_on && new Date(currentImage['reviewed_on']).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }).replace(/\//g, "-")}</Text>

			<Text fontWeight={"400"} lineHeight={"18px"}> {currentImage?.reviewed_by && 'REVIEW BY'}</Text>
			<Text className='detail'>{currentImage?.['reviewed_by']}</Text>
		</Grid>
	)
}

export default Metadata
