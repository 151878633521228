import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import msalConfig from "./contexts/login-service/config";
// import App from "./App_ex";
import App from "./App";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { OverlayContextProvider } from "./contexts/overlay/OverlayContextProvider";
import { CentralizeStoreProvider } from "./contexts/CentralizeStoreProvider";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload
  ) {
    if ("account" in event.payload) {
      // If 'account' property exists, set active account
      msalInstance.setActiveAccount(event.payload.account);
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ChakraProvider>
    {/* <BrowserRouter> */}
    <CentralizeStoreProvider>
    <OverlayContextProvider>
      <App />
    </OverlayContextProvider>
    </CentralizeStoreProvider>
    {/* </BrowserRouter> */}
  </ChakraProvider>
  // </React.StrictMode>
);
