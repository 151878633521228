import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import UploadMenu from "./UploadMenu";
import isFileSelectIcon from "../../assets/Images/isFileSelect.svg";
import fileSelectedIcon from "../../assets/Images/fileSelected.svg";
import Skeleton from "../../reusable-components/LoadingOverlay/Skeleton";
import { useNavigate } from "react-router-dom";
import uploadService from "../../api/uploadService";
import { interceptorsSetup } from "../../api/intreceptor";
import { FaRegHeart } from "react-icons/fa";
import { IoPricetagsOutline } from "react-icons/io5";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { IoLockOpenOutline } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { BounceLoader, PuffLoader } from "react-spinners";
import useFetchConfigSetting from "../../hooks/useConfigSetting";
import useCredit from "../../hooks/useCredit";
import configService from "../../api/configService";
import DataTable from "react-data-table-component";
import { CentralizeStore } from "../../contexts/CentralizeStoreProvider";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";

const Upload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedItems, setSelectedItems] = useState(new Set());
  // const [selectedItems, setSelectedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const email = localStorage.getItem("email");
  const role = localStorage.getItem("role");
  const [toggleView, setToggleView] = useState(true);
  const [uploadedFile, setUploadedFile] = useState([]);
  // const [selectedImage, setSelectedImage] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const {fetchConfigSetting} = useFetchConfigSetting()
  const [isFavLoading, setIsFavLoading] = useState(false);
  const configSetting = JSON.parse(localStorage.getItem("configSetting"))
  const [newConfigSetting, setNewConfigSetting] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);
  const {isFilteredExist, IsFilteredExistFalse} = useContext(CentralizeStore)
  
  const [loadingId, setLoadingId] = useState(null);

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  useEffect(() => {
    handleFetchLatestConfigSetting()
    getUploadedImages();
  }, []);

  const getUploadedImages = async () => {
    IsFilteredExistFalse()
    try {
      setIsLoading(true);
      setIsFavLoading(true);
      const res = await uploadService.getUploadedImages({ email,role });
      if (res.status === 200) {
        setUploadedFile(res.data.image_data);
        setFilteredImages(res.data.image_data)
        fetchConfigSetting()
      }
    } catch (err) {
      toast({
        title: `${err?.response?.data?.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      });
      console.log("err:", err);
    } finally {
      setIsLoading(false);
      setIsFavLoading(false);
    }
  };

  const handleReFetch = () => {
    getUploadedImages();
  };

  const handleToggleView = () => {
    setToggleView(!toggleView);
  };

  // useEffect(() => {
  //   const selectedData = selectedItems.map((index) => uploadedFile[index]);
  //   // console.log("Selected data:", selectedData);
  //   setSelectedImage(selectedData);
  // }, [selectedItems]);

  useEffect(() => {
    const selectedData = uploadedFile.filter((item) => selectedItems.has(item.id));
    // console.log("selectedImage data:", selectedImage)
    setSelectedImage(selectedData);
  }, [selectedItems, uploadedFile]);

  useEffect(() => {
    if (search.length === 0) {
      if (filteredImages.length !== uploadedFile.length) {
        // console.log("Resetting filtered images to uploadedFile");
        setFilteredImages([...uploadedFile]);
      }
    } else {
      // Filter the uploadedFile based on the search term
      const matchingFilteredData = uploadedFile?.filter((el) =>
      el.original_image_name.toLowerCase().includes(search.trim().toLowerCase()) || 
      el.created_by.toLowerCase().includes(search.trim().toLowerCase()) || 
      el.image_description.toLowerCase().includes(search.trim().toLowerCase()) || 
      el.created_at.toLowerCase().includes(search.trim().toLowerCase()) ||
      el.domain.toLowerCase().includes(search.trim().toLowerCase())
      );
      // console.log("Matching Filtered Data:", matchingFilteredData);
      setFilteredImages(matchingFilteredData);
    }
  }, [search,uploadedFile]);

  // const selectCounter = useMemo(() => selectedItems.length, [selectedItems]);
  const selectCounter = useMemo(() => selectedItems.size, [selectedItems]);
  
  const handleItemClick = useCallback(
    // (index) => {
    //   setSelectedItems((prevSelectedItems) => {
    //     if (prevSelectedItems.includes(index)) {
    //       // If the item is already selected, remove it
    //       return prevSelectedItems.filter((item) => item !== index);
    //     } else {
    //       // If the item is not selected, add it
    //       return [...prevSelectedItems, index];
    //     }
    //   });
    // },
    // [setSelectedItems]
    (id) => {
      const clickedItem = uploadedFile.find((item) => item.id === id);
      setSelectedImage(clickedItem);
      // Toggle the selection
      setSelectedItems((prevSelectedItems) => {
        const updatedSelection = new Set(prevSelectedItems);
        if (updatedSelection.has(id)) {
          updatedSelection.delete(id);
        } else {
          updatedSelection.add(id);
        }
        return updatedSelection;
      });
    },
    [uploadedFile]
  );

  const handleItemMouseEnter = useCallback((index) => {
    setHoveredItem(index);
  }, []);

  const handleItemMouseLeave = useCallback(() => {
    setHoveredItem(null);
  }, []);

  const handleClearSelection = useCallback(() => {
    // setSelectedItems([]);
    setSelectedItems(new Set());
  }, []);

  // const handleSelectAll = useCallback(() => {
  //   if (selectedItems.length === uploadedFile.length) {
  //     setSelectedItems([]);
  //   } else {
  //     setSelectedItems(uploadedFile.map((_, index) => index));
  //   }
  // }, [selectedItems, uploadedFile]);
  const handleSelectAll = useCallback(() => {
    if (selectedItems.size === uploadedFile.length) {
      setSelectedItems(new Set()); // Deselect all
    } else {
      const allIds = new Set(uploadedFile.map((item) => item.id));
      setSelectedItems(allIds); // Select all
    }
  }, [selectedItems, uploadedFile]);

  const toggleFav = async (id, isFav) => {
    setLoadingId(id);
    try {
      const payload = {
        ids: [id],
        is_favourite: isFav ? false : true,
      };
      setIsFavLoading(true);
      const response = await uploadService.addToFavourite(payload);
      if (response.status === 200) {
        getUploadedImages();
      }
    } catch (err) {
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      });
      console.log("toggle fav err:", err);
    } finally {
      setIsFavLoading(false);
    }
  };

  const handleChangeScope = async(scope:number, imageID) => {
    const ids = [imageID];
    const payload = {
      ids,
      scope: scope
    };

    try{
      const response = await uploadService.changeScope(payload)
      if(response.status === 200){
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
         handleReFetch()
      }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("scope err:", err)
    }finally{
      handleResetSelectedImages()
      handleClearSelection()
    }
  }

  const handleViewModel = async(id) => {
    const ids = [id];
    let storedEmail =localStorage.getItem("email")
    const params = new URLSearchParams({
      ids: JSON.stringify(ids),
      email: storedEmail
    }).toString();
    
    navigate(`/layout/model-output?${params}`);
  }

  const handleResetSelectedImages = () => {
    setSelectedImage([]);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit).join(" ") + "...",
        isTruncated: true,
      };
    }
    return {
      truncated: text,
      isTruncated: false,
    };
  };

  const truncateImageName = (text, charLimit) => {
    if (text.length > charLimit) {
      return {
        truncated: text.slice(0, charLimit) + "...",
        isTruncated: true,
      };
    }
    return {
      truncated: text,
      isTruncated: false,
    };
  }

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFetchLatestConfigSetting = async () => {
    try{
      const response = await configService.getConfigSetting();
      setNewConfigSetting(response.configuration_data)
    }catch(err){
      console.log("err while getting config setting: ", err)
    }
  }

  const columns = [
    {
      name: "",
      cell: (row, i) => (
        <input
          type="checkbox"
          checked={row.selectedItems.includes(row.id)}
          onChange={() => row.handleItemClick(row.id)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Image Name",
      cell: (row, i) => {
        const { truncated, isTruncated } = truncateImageName(row.original_image_name, 18);
        return isTruncated ? (
          <Tooltip label={row.original_image_name}>
            <span onClick={() => handleItemClick(row.id)} className="cursorPointer">{truncated}</span>
          </Tooltip>
        ) : (
          <span onClick={() => handleItemClick(row.id)} className="cursorPointer">{truncated}</span>
        );
      },
      sortable: true,
      width:"160px"
    },
    {
      name: "Upload By",
      // selector: (row) => row.created_by,
      cell: (row, i) =>(
        <span onClick={() => row.handleItemClick(row.id)} className="cursorPointer">{row.created_by}</span>
      ),
      sortable: true,
      width:"173px"
    },
    {
      name: "Upload At",
      cell: (row,i) =>
        <span className="cursorPointer" onClick={() => row.handleItemClick(row.id)} >
        {new Date(row.created_at)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")}
      </span>,
      sortable: true,
    },
    {
      name: "Domain",
       cell: (row, i) => (
      <span onClick={() => row.handleItemClick(row.id)} className="cursorPointer" >
        {(row.image_description !=="" && row.domain == "") ? "Not Available" : row.domain}
      </span>
    ),
    sortable: true,
    },
    {
      name: "Description",
      cell: (row, i) => {
        const { truncated, isTruncated } = truncateText(row.image_description, 6);
        return isTruncated ? (
          <Tooltip label={row.image_description}>
            <span onClick={() => handleItemClick(row.id)}>{truncated}</span>
          </Tooltip>
        ) : (
          <span onClick={() => handleItemClick(row.id)} className="cursorPointer">{truncated}</span>
        );
      },
      width:"200px"
    },
    {
      name: "Is Favorite",
      cell: (row) => (
        <div onClick={row.is_favourite ? () => row.toggleFav(row.id, row.is_favourite) : null}>
          {row.is_favourite ? <FaHeart color="red" fontSize={"17px"} /> : <FaRegHeart fontSize={"17px"} />}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Tag Generate Status",
      selector: (row) => (row.tag_status === 0 ? "No" : "Yes"),
    },
    {
      name: "Scope",
      cell: (row) =>
        row.scope === 0 ? <HiOutlineLockClosed fontSize={"17px"} /> : <IoLockOpenOutline fontSize={"17px"} />,
    },
  ];
  
  const tableStyles = {
    headCells: {
      style: {
        backgroundColor: "#FFFFFF",
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
      },
    },
  };
  

  const NoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <strong>No data found</strong>
    </div>
  );

  return (
    <div>
      <Box paddingTop={{base:"230px",sm:"195",md:"74px",lg:"72px"}} >
      <UploadMenu
        selectCounter={selectCounter}
        onClearSelection={handleClearSelection}
        toggleView={toggleView}
        handleToggleView={handleToggleView}
        selectedImage={selectedImage}
        handleReFetch={handleReFetch}
        handleResetSelectedImages={handleResetSelectedImages}
        handleSelectAll={handleSelectAll}
        toggleFilter={toggleFilter}
        setUploadedFile={setUploadedFile}
        totalUploadedFile = {uploadedFile}
      />
      </Box>
      {isLoading && <Skeleton />}
      <Box>
      {(isFilteredExist && uploadedFile?.length >0) && <Center fontWeight={600} color={"#333333"}>Filtered : {uploadedFile?.length === 0 ? "0" : (uploadedFile?.length < 10 ? `0${uploadedFile?.length}` : uploadedFile?.length)}</Center>}
      {
       ( uploadedFile?.length === 0 && !isLoading) && <Flex height={"55vh"} alignItems={"center"} justify={"center"}><Text fontWeight={600} fontSize={{base:"16px",md:"18px",lg:"27px"}}>No Result Found !</Text></Flex>
      }
      {uploadedFile?.length !== 0 && 
      <div> 
      {toggleView ? (
        <Suspense fallback={<Skeleton />} >
          { !isLoading &&
            <Grid
              gridTemplateColumns={{
                base: "repeat(1,auto)",
                sm: "repeat(3,200px)",
                md: "repeat(4,1fr)",
                lg: "repeat(6,1fr)",
              }}
              margin={"auto"}
              justifyContent={"center"}
              gap={{ base: "10px", md: "12px", lg: "17px" }}
              mt={6}
              transition={"ease all 0.6s"}
            >
              {uploadedFile?.length > 0 &&
                uploadedFile?.map((el, i) => {
                  const IconComponent = el.icn;
                  // const isSelected = selectedItems.includes(i);
                  // const isHovered = hoveredItem === i;
                  const isSelected = selectedItems.has(el.id);
                  const isHovered = hoveredItem === el.id;
                  const displayIcon = isSelected
                    ? fileSelectedIcon
                    : isFileSelectIcon;

                  return (
                    <Tooltip
                      label={
                        // el.original_image_name
                        <>
                          <strong>Domain:</strong> {(el.image_description !=="" && el.domain == "") ? "Not Available" : el.domain}
                          <br />
                          <strong>Description:</strong> {el.image_description}
                        </>
                      }
                      fontSize="xs"
                      key={i}
                      placement="auto-start"
                    >
                      <Flex
                        position={"relative"}
                        flexDir={"column"}
                        key={i}
                        alignItems={"center"}
                        justifyContent={"center"}
                        background={el.type === "file" ? "#FFFFFF" : "none"}
                        borderRadius={"7px"}
                        width={"100%"}
                        height={"100%"}
                        _hover={{
                          border: isSelected ? "2px solid #7BD6DA" : "1px solid #828282"
                        }}
                        transition={"ease all 0.6s"}
                        padding={"3px"}
                        onMouseEnter={() => handleItemMouseEnter(el.id)}
                        onMouseLeave={handleItemMouseLeave}
                        border={isSelected ? "2.4px solid #7BD6DA" : "default"}
                        boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
                      >
                        {el.type === "file" ? (
                          <Box
                            width={"100%"}
                            borderRadius={"5px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            padding={"10px"}
                            fontSize={"80px"}
                          >
                            <IconComponent color={el.borderColor} />
                          </Box>
                        ) : (
                          <Flex
                            alignItems={"center"}
                            // width={"100%"}
                            // height={"100%"}
                            h={"220px"}
                            justifyContent={"center"}
                            onClick={() => handleItemClick(el.id)}
                            cursor={"pointer"}
                          >
                            <Image
                              // w={"220px"}
                              // h={"220px"}
                              width={"100%"}
                              height={"100%"}
                              borderRadius={"5px"}
                              src={el.processed_image_url}
                              alt={`${el.title + `${i}`}`}
                              objectFit={"cover"}
                              // fallbackSrc='https://via.placeholder.com/150'
                            />
                          </Flex>
                        )}
                        <Flex
                          w={"100%"}
                          alignItems={"center"}
                          justify={"space-around"}
                          px={"7px"}
                        >
                          {isLoading ? (
                            <PuffLoader size={"20px"} />
                          ) : (
                            <Tooltip
                              label={
                                el.is_favourite
                                  ? "Remove from favorites"
                                  : "Add to favorites"
                              }
                              fontSize="xs"
                            >
                              <Button
                                isDisabled={isFavLoading && loadingId === el.id}
                                colorScheme="black.500"
                                variant="ghost"
                                onClick={()=>toggleFav(el.id, el.is_favourite)}
                                cursor={
                                  el.is_favourite ? "pointer" : "not-allowed"
                                }
                              > 
                              {(isFavLoading && loadingId === el.id) ? <BounceLoader size={20} color="#FC088B"/> : <>
                                {el.is_favourite ? (
                                  <FaHeart color="red" fontSize={"17px"} />
                                ) : (
                                  <FaRegHeart fontSize={"17px"} />
                                )}</>}
                              </Button>
                            </Tooltip>
                          )}
                            {
                              (configSetting?.Intelli_Tag ||newConfigSetting?.Intelli_Search === true) && 
                              <>
                          {el.tag_status === 0 ? (
                            <Button
                              colorScheme="black.500"
                              variant="ghost"
                              cursor="pointer"
                              onClick={() => handleViewModel(el.id)}
                            >
                              {" "}
                              <IoPricetagsOutline
                                cursor={"pointer"}
                                color="#BDBDBD"
                                fontSize={"17px"}
                              />{" "}
                            </Button>
                          ) : (
                            <Button
                              colorScheme="black.500"
                              variant="ghost"
                              cursor="pointer"
                              onClick={() => handleViewModel(el.id)}
                            >
                              <IoPricetagsOutline
                                cursor={"pointer"}
                                color="#4F256F"
                                fontSize={"17px"}
                              />
                            </Button>
                          )}
                           </>
                            }
                          {el.scope === 0 ? (
                            <Tooltip label={"Private"} fontSize="xs">
                              <Button
                                colorScheme="black.500"
                                variant="ghost"
                                // cursor="pointer"
                                // onClick={() => handleChangeScope(1, el.id)}
                              >
                                <HiOutlineLockClosed fontSize={"17px"} />{" "}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip label={"Public"} fontSize="xs">
                              <Button
                                colorScheme="black.500"
                                variant="ghost"
                                // cursor="pointer"
                                // onClick={() => handleChangeScope(0, el.id)}
                              >
                                <IoLockOpenOutline fontSize={"17px"} />
                              </Button>
                            </Tooltip>
                          )}
                        </Flex>
                        <Text
                          m={"0px"}
                          pb={"7px"}
                          noOfLines={1}
                          onClick={() => handleItemClick(el.id)}
                          fontSize={"10px"}
                          cursor={"pointer"}
                          color={"#AAA"}
                        >
                          Uploaded : {" "}
                          {new Date(el.created_at)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-")}
                        </Text>
                        {(isHovered || isSelected) && (
                          <Box
                            position={"absolute"}
                            top={0}
                            right={0}
                            padding={"9px 9px 0px 0px"}
                            onClick={() => handleItemClick(el.id)}
                            cursor={"pointer"}
                          >
                            <Image src={displayIcon} alt="selectIcon" />
                          </Box>
                        )}
                      </Flex>
                    </Tooltip>
                  );
                })}
            </Grid>
          }
        </Suspense>
      ) : (
        <Box>
          <Flex padding={"10px"} justifyContent={"flex-end"}>
          <input
            className="view-search-field"
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          {/* <Button size={"sm"} variant={"outline"} colorScheme={"red"} onClick={()=>setSearch("")}>Clear</Button> */}
          </Flex>
          <DataTable
              persistTableHead={true}
              columns={columns}
              // data={filteredImages.map((el, i) => ({ ...el, selectedItems, handleItemClick, toggleFav }))}
              data={filteredImages.map((el) => ({
                ...el,
                selectedItems: Array.from(selectedItems), // Convert Set to Array if needed
                handleItemClick: (id) => handleItemClick(id),
                toggleFav: (id, isFav) => toggleFav(id, isFav),
              }))}
              noDataComponent={<NoDataComponent />}
              customStyles={tableStyles}
              pagination
            />

          {/* <TableContainer bg={"#FFFFFF"} padding={"15px"} borderRadius={"10px"}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Image Name</Th>
                  <Th>Upload By</Th>
                  <Th>Upload At</Th>
                  <Th>Domain</Th>
                  <Th>Description</Th>
                  <Th>is Favorite</Th>
                  <Th>Tag Generate <br/>Status</Th>
                  <Th>Scope</Th>
                </Tr>
              </Thead>

              {uploadedFile.length > 0 &&
                uploadedFile?.map((el, i) => {
                  const { truncated, isTruncated } = truncateText(el.image_description,5);
                  const {truncatedName, isTruncatedName} = truncateImageName(el.original_image_name, 3)
                  return (
                    <Tbody key={i}>
                      <Tr>
                        <Td>
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(i)}
                            onChange={() => handleItemClick(i)}
                          />
                        </Td>
                        <Td onClick={() => handleItemClick(i)} cursor={"pointer"} >
                          {isTruncatedName ? (
                            <Tooltip
                              label={el.original_image_name}
                              aria-label="Full description"
                            >
                              <span>{truncatedName}</span>
                            </Tooltip>
                          ) : (
                            <span>{truncatedName}</span>
                          )}
                        </Td>
                        <Td
                          onClick={() => handleItemClick(i)}
                          cursor={"pointer"}
                        >
                          {el.created_by}
                        </Td>
                        <Td
                          onClick={() => handleItemClick(i)}
                          cursor={"pointer"}
                        >
                          {new Date(el.created_at)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-")}
                        </Td>
                        <Td
                          onClick={() => handleItemClick(i)}
                          cursor={"pointer"}
                        >
                          {el.domain}
                        </Td>
                        <Td onClick={() => handleItemClick(i)} cursor="pointer">
                          {isTruncated ? (
                            <Tooltip
                              label={el.image_description}
                              aria-label="Full description"
                            >
                              <span>{truncated}</span>
                            </Tooltip>
                          ) : (
                            <span>{truncated}</span>
                          )}
                        </Td>
                        <Td
                          cursor={"pointer"}
                          onClick={
                            el.is_favourite
                              ? () => toggleFav(el.id, el.is_favourite)
                              : null
                          }
                        >
                          {el.is_favourite ? (
                            <FaHeart color="red" fontSize={"17px"} />
                          ) : (
                            <FaRegHeart fontSize={"17px"} />
                          )}
                        </Td>
                        <Td>{el.tag_status === 0 ? "No" : "Yes"}</Td>
                        <Td>
                          {el.scope === 0 ? (
                            <HiOutlineLockClosed fontSize={"17px"} />
                          ) : (
                            <IoLockOpenOutline fontSize={"17px"} />
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  );
                })}
            </Table>
          </TableContainer> */}
        </Box>
      )}
      </div>
      }
      </Box>
    </div>
  );
};

export default Upload;
