import React, { useState, useEffect } from "react";
import CreditContext from "./creditContext";
import axios from "axios";
import usercreditsService from "../../api/userService";

const CreditProvider = ({ children, setIsAdmin }) => {
	const [creditBalance, setCreditBalance] = useState(0);
	const [isCrditBalanceLoad, setIsCrditBalanceLoad] = useState(false);
	const email = localStorage.getItem("email");
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: { "Content-Type": "application/json" },
	});

	useEffect(() => {
		if (email && email.length > 0) {

			const fetchCreditBalance = async () => {
				setIsCrditBalanceLoad(true)
				try {
					const res = await usercreditsService.userBalance(email);
					const { role, credit_balance } = res.data;
					localStorage.setItem("role", role);
					setCreditBalance(credit_balance);
					if (localStorage.getItem("role") === 'Admin' || localStorage.getItem("role") === 'superAdmin') {
						setIsAdmin(true)
					}
					else {
						setIsAdmin(false)
					}
				}
				catch (err) {
					console.error("Failed to fetch credit balance:", err);
				}
				finally {
					setIsCrditBalanceLoad(false)
				}
			};
			fetchCreditBalance();
		}
	}, [email]);
	
	return (
		<CreditContext.Provider value={{ creditBalance, setCreditBalance, isCrditBalanceLoad }}>
			{children}
		</CreditContext.Provider>
	);
};

export default CreditProvider;
