import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './changepassword.css'; // Import your CSS file
import "../../contexts/login-service/login.css";
import logo from "../../assets/Images/IntelliSearch-Black.png";
import { Box, Button, useToast } from '@chakra-ui/react';
import authenticationService from '../../api/authenticationService';
import useFetchConfigSetting from '../../hooks/useConfigSetting';
import { interceptorsSetup } from '../../api/intreceptor';
import { PulseLoader } from 'react-spinners';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const[success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast()
    const Email = localStorage.getItem("email")
    const {fetchConfigSetting} = useFetchConfigSetting()

    useEffect(() => {
        interceptorsSetup(navigate);
    }, [navigate]);

    const handleChangePassword = async () => {
        // Validate if all fields are filled
        if (!Email || !currentPassword || !newPassword || !confirmNewPassword) {
            return(
                toast({
                    title: "Please fill in all fields.",
                    status: "warning",
                    position: "top",
                    duration: 2000,
                    variant: "left-accent",
                    isClosable: true,
                  })
            )
        }

        // Validate if new password matches confirm new password
        if (newPassword !== confirmNewPassword) {
            return(
                toast({
                    title: "New password and confirm new password do not match.",
                    status: "warning",
                    position: "top",
                    duration: 2000,
                    variant: "left-accent",
                    isClosable: true,
                  })
            )
        }

        try {
            // Make API call to change password
            setIsLoading(true)
            const user_info = {
                email:Email,
                old_password: currentPassword,
                new_password: newPassword
            }

            const response = await authenticationService.changePassword(user_info);
            if(response.status === 200){
                toast({
                    title: "Password has been changed.",
                    status: "success",
                    position: "top",
                    duration: 2000,
                    variant: "left-accent",
                    isClosable: true,
                  })
                  navigate("/layout")
                  fetchConfigSetting()
            }
        } catch (error) {
            console.error('Error changing password:', error);
            setErrorMessage('Failed to change password. Please try again later.');
        } finally{
            setIsLoading(false)
        }
    };

    return (
        <Box mt={{base:"180px",md:"90px",lg:"auto"}} id="change_password_container">
            {!success && <div className="change_pass_form">
                <Form className="FormControllCon">
                <img className="intellitag-logo-black" src={logo} alt="logo" />
                <h5 style={{color:"#000000"}} className="sing-in-text">Change Password</h5>
                    <Form.Group controlId="formCurrentPassword" className="inputBox">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                              placeholder="Enter current password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword" className="inputBox">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                              placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmNewPassword" className="inputBox">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                              placeholder="Confirm new password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </Form.Group>
                </Form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="buttons-container">
                    <Button size={"sm"} id='btn' isDisabled={isLoading} onClick={handleChangePassword}>
                    {isLoading? <PulseLoader color="#FFFFFF"/>:"Submit"}
                    </Button>
                    <Link to="/layout">
                        <Button size={"sm"} variant={"outline"} isDisabled={isLoading} colorScheme={"red"}>Cancel</Button>
                    </Link>
                </div>
            </div>}
            {success && <div style={{color: '#FFFFFF'}}>
                Password changed successfully. Please click <Link to="/login">here</Link> to login.
            </div>}
        </Box>
    );
};

export default ChangePassword;