import axios from "axios";
import { axiosInstance } from "./intreceptor";

// Base API setup with default JSON headers
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

const uploadApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const imageScrapeApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

uploadApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class UploadService {
  uploadImage = (payload) => {
    return uploadApi
      .post("/upload/file_upload", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  uploadWebScraping = (payload) => {

    return imageScrapeApi
      .post("/upload/web_scraping", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  getUploadedImages = (payload) => {
    return axiosInstance
      .post("/upload/image_data", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  reRameImgName = (payload) =>{
    return axiosInstance
      .post("/upload/renamefile", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  addToFavourite = (payload) =>{
    return axiosInstance
      .post("/upload/addtofavourite", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  changeScope = (payload) =>{
    return axiosInstance
      .post("/upload/changescope", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  deleteImage = (payload) =>{
    return axiosInstance
      .post("/upload/deleteimage", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  uploadZip = (payload) => {
    return uploadApi
      .post("/upload/zip_upload", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

  searchImage = (payload) => {
    return uploadApi
      .post("/search/image_search", payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  };

}

export default new UploadService();
