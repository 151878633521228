
import React, { createContext, useState, ReactNode, useContext } from 'react';

interface OverlayContextProps {
  isBlur: boolean;
  setIsBlur: (value: boolean) => void;
  toggleOverlay: () => void;
  handleSetBlurTrue: () => void;
  handleSetBlurFalse: () => void;
}

const defaultContextValue: OverlayContextProps = {
  isBlur: false,
  setIsBlur: () => {}, // This is just a placeholder
  toggleOverlay: () => {}, // This is just a placeholder
  handleSetBlurTrue: () => {},
  handleSetBlurFalse : () => {}
};

export const OverlayContext = createContext<OverlayContextProps>(defaultContextValue);

interface OverlayContextProviderProps {
  children: ReactNode;
}

export const OverlayContextProvider = ({ children }: OverlayContextProviderProps) => {
  const [isBlur, setIsBlur] = useState(false);
  
  const toggleOverlay = () => {
    setIsBlur((prevState) => !prevState);
  };
  const handleSetBlurTrue = () => {
   setIsBlur(true)
  }
  const handleSetBlurFalse = () => {
    setIsBlur(false)
   }

  return (
    <OverlayContext.Provider value={{ isBlur, setIsBlur, toggleOverlay,handleSetBlurTrue, handleSetBlurFalse }}>
      {children}
    </OverlayContext.Provider>
  );
};
