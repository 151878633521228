// LoadingIndicator.js
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { PulseLoader } from "react-spinners";
// import Loader from 'react-loader-spinner';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress && <PulseLoader size={"11px"} color="#FFFFFF" />;
};

export default LoadingIndicator;
