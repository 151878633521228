import { useState,useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./login.css";
import { trackPromise } from "react-promise-tracker";
import Form from "react-bootstrap/Form";
import { interceptorsSetup } from "../../api/intreceptor";
import useCredit from "../../hooks/useCredit";
import logo from "../../assets/Images/IntelliSearch-Black.png";
import microsofttIcn from "../../assets/Images/Microsoft_Logo.svg";
import { BeatLoader, PulseLoader } from "react-spinners";
import { Button, Center, Checkbox, Flex, FormControl, FormLabel, Input, Link, Text, useToast, VStack } from "@chakra-ui/react";
import LoadingIndicator from "../../reusable-components/LoadingOverlay/LoadingIndicator";
import authenticationService from "../../api/authenticationService";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

const Login = ({ setIsAdmin, msalInstance,setIsSuperAdmin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [LoginMessage, setLoginMessage] = useState("");
  const [LoginDeniedMessage, setLoginDeniedMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isSSOloading, setIsSSOloading] = useState(false);
  const { setCreditBalance } = useCredit();
  const toast = useToast();
  const {fetchConfigSetting} = useFetchConfigSetting()
  const [loginToggle, setLoginToggle] = useState(true);
  const [registerName, setRegisterName] = useState("");
  const [organization, setOrganization] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmRegisterPassword, setConfirmRegisterPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isSignup, setIsSignup] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const {setIsSuperAdminExist} = useContext(CentralizeStore)



  useEffect(() => {
    // Check if the sessionExpired flag is set in sessionStorage
		if (sessionStorage.getItem('sessionExpired') === 'true') {
			alert('Your session has expired.');
			sessionStorage.removeItem('sessionExpired'); // Clear the flag after showing the alert
		}
        if (location.pathname === "/") {
            sessionStorage.clear();
        }
}, []);

	const CustomLogin = async () => {
		setErrorMessage("");
		setLoginDeniedMessage("");
		if (username.trim() === "" || password.trim() === "") {
			setErrorMessage("Email and password are required");
			return;
		}

    try {
      setDisableBtn(true);
      const res = await authenticationService.handleCustomLogin({
        email: username.toLowerCase(),
        password,
      });

			const { jwt_token, role, email, name, status, balance, isLoginFrom } = res.data;

			if (status === 0) {
				setLoginDeniedMessage(
					"Login access denied. Please contact your admin."
				);
				return;
			}
			if (email.length > 0) {
				localStorage.setItem("token", jwt_token);
				localStorage.setItem("role", role);
				localStorage.setItem("email", email);
				localStorage.setItem("name", name);
				localStorage.setItem("status", status);
				localStorage.setItem("isLoginFrom", isLoginFrom);
				localStorage.setItem("authenticateFrom", "Custom")
				if (role === 'superAdmin'){
					setIsSuperAdmin(true);
				};
				toast({
					title: "Login Succesfull",
					position: "top-left",
					status: "success",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				});
				interceptorsSetup(navigate);

				fetchConfigSetting()

				setCreditBalance(balance);
				navigate("/layout");
			}
		} catch (err) {
			console.log(err)
			const { detail } = err.response.data;
			if (!!detail) {
				toast({
					title: `${err.response?.data?.detail || 'An error occurred'}`,
					position: "top",
					status: "error",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				});
				setUsername("");
				setPassword("");
			}
			console.log("Failed to login :", err);
		} finally {
			setDisableBtn(false);
		}
	};

	const ssoLogin = async () => {
		try {
			const accounts = msalInstance.getAllAccounts();

			if (accounts.length > 0) {
				const userAccount = accounts[0];

				const silentRequest = {
					scopes: ["openid", "profile", "email", "User.Read"],
					account: userAccount,
				};

				try {
					const response = await msalInstance.acquireTokenSilent(silentRequest);

					const userInformation = {
						name: userAccount.name,
						email: userAccount.username, // Assuming the email is stored in the username field
						accessToken: response.accessToken,
						expirationTime: response.expiresOn,
						lastLoggedIn: new Date().toISOString(), // You can replace this with the actual last login time
					};

					// Call usercredits function with user information
					usercredits(userInformation);
				} catch (silentError) {
					console.error("Silent login failed:", silentError);
					await interactiveLogin();
					// Handle silent login failure, you can fall back to interactive login if needed
					// ...
				}
			} else {
				await interactiveLogin();

			}
		} catch (error) {
			console.error("Login failed:", error);
		}
	};

	const interactiveLogin = async () => {
		try {
			const loginResponse = await msalInstance.loginPopup({
				scopes: ["openid", "profile", "email", "User.Read"],
			});

			// Extract user information
			const userInformation = {
				name: loginResponse.account.name,
				email: loginResponse.account.username,
				accessToken: loginResponse.accessToken,
				expirationTime: loginResponse.expiresOn,
				lastLoggedIn: new Date().toISOString(),
			};
			usercredits(userInformation);
		} catch (interactiveError) {
			console.error("Interactive login failed:", interactiveError);
		}
	}
	const usercredits = (userInfo) => {
		setLoginDeniedMessage("");
		setIsSSOloading(true);
		trackPromise(
			authenticationService.handleLoginSSO(userInfo)
				.then((response) => {
					// Process the response if needed
					const { name, email, role, balance, jwt_token, status } =
						response.data;
					localStorage.setItem("role", role);
					localStorage.setItem("token", jwt_token);
					localStorage.setItem("status", status);
					localStorage.setItem("email", email);
					localStorage.setItem("name", name);
					localStorage.setItem("authenticateFrom", "SSO")
					if (response.data.status != 1) {
						setLoginDeniedMessage(
							"Login access denied. Please contact your admin."
						);
						sessionStorage.clear();
						localStorage.clear();
					} else {
						setIsAdmin(role === "Admin");
						toast({
							title: "Login Succesfull",
							status: "success",
							description: "You have successfully signed in with Microsoft.",
							position: "top-right",
							duration: 2000,
							variant: "left-accent",
							isClosable: true,
						});
						interceptorsSetup(navigate)

						fetchConfigSetting()

						setCreditBalance(balance);
						navigate("/layout");
					}
				})
				.catch((err) => {
					setLoginDeniedMessage(err.response.data.error);
					toast({
						title: `${err.response?.data?.detail || 'An error occurred'}`,
						position: "top",
						status: "error",
						duration: 3000,
						variant: "left-accent",
						isClosable: true,
					});
					sessionStorage.clear();
					localStorage.clear();
				})
				.finally(() => {
					setIsSSOloading(false);
				})
		);
	};

	const handleUsernameChange = (event) => {
		setUsername(event.target.value);
		setErrorMessage("");
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
		setErrorMessage("");
	};


	const handleSignup = async (event) => {
		event.preventDefault();

    if (registerPassword !== confirmRegisterPassword) {
      setPasswordMismatch(true);
        return(
            toast({
                title: "Password and Confirm Password do not match.",
                status: "warning",
                position: "top",
                duration: 2000,
                variant: "left-accent",
                isClosable: true,
              })
        )
    }
    // else{
      setPasswordMismatch(false);
    // }

    const payload = {
      name: registerName,
      organization: organization,
      email: registerEmail.toLowerCase(),
      password: registerPassword,
      confirm_password: confirmRegisterPassword
    };

		try {
			setIsSignup(true)
			const response = await authenticationService.varifyEmail(payload.email);

			if (response.data.status === true) {
				await handleRegister(payload)
			} else {
				toast({
					title: `${response.data.message}`,
					position: "top",
					status: "warning",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				});
				setRegisterEmail("")
			}

		} catch (err) {
			console.log("err while varifying email: ", err)
		} finally {
			setIsSignup(false)
		}

		// You can send this payload to your backend here
	};

  const handleRegister = async(payload) => {
      try{
        setIsSignup(true)
        const response = await authenticationService.registerNewUser(payload)
        setLoginToggle(true);
        setRegisterName("")
        setRegisterEmail("")
        setConfirmRegisterPassword("")
        setRegisterPassword("")
        setOrganization("")
        toast({
        //   title: `You've completed the signup. Check your email to verify your account.`,
          title: `You've completed the signup.`,
          position: "top",
          status: "success",
        //   duration: 5000,
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        });
      }catch(err){
       console.log("err while register : ", err)
    //    toast({
    //     title: `${err.response.data.details}`,
    //     description:"Please contact with your admin.",
    //     position: "top",
    //     status: "info",
    //     duration: 3000,
    //     variant: "left-accent",
    //     isClosable: true,
    //   });
      }finally{
        setIsSignup(false)
      }
  }
  const signupAuthForm = () => {
    setLoginToggle(false);
    setRegisterName("")
    setRegisterEmail("")
    setConfirmRegisterPassword("")
    setRegisterPassword("")
    setOrganization("")
    setTermsAccepted(false)
  }

  const loginAuthForm = () => {
    setLoginToggle(true);
  }

  return (
    <div id="log-container">
      <div className="login-form">
        <img className="intellitag-logo" src={logo} alt="logo" />
        <h4 className="sing-in-text">{loginToggle ? "Sign In": "Sign Up"}</h4>
        {
          !!loginToggle &&
        <Form className="FormControllCon">
          <Form.Group controlId="formPlaintextEmail" className="inputBox">
            <Form.Control
              type="text"
              placeholder="Enter your email here"
              value={username}
              onChange={handleUsernameChange}
              // style={{ padding: "5px" }}
              className="custom-input"
            />
          </Form.Group>
          <Form.Group controlId="formPlaintextPassword" className="inputBox">
            <Form.Control
              type="password"
              placeholder="Enter your password here"
              value={password}
              onChange={handlePasswordChange}
              className="custom-input"
            />
          </Form.Group>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </Form>
        }
        { !!loginToggle &&
        <form autoComplete="off" className="FormControllCon" >
          <button type="button" disabled={disableBtn} className="login-btn btn-pink" onClick={CustomLogin} >
            {/* {disableBtn ? (<PulseLoader size={"11px"} color="#ffffff" />) : ("Login")} */}
            Sign In
          </button>

          <button type="button" className="login-btn btn-white" onClick={signupAuthForm}>Sign Up </button>

          {LoginMessage && <p>{LoginMessage}</p>}
          <div className="divider">
            <span className="divider-text">OR</span>
          </div>

          <button disabled={isSSOloading} className="login-btn btn-black" type="button" onClick={ssoLogin}>
                <img id="microsoft-icn" src={microsofttIcn} alt="icn" /> Sign In with Microsoft
                {/* {!isSSOloading ? (
              <>
                <img id="microsoft-icn" src={microsofttIcn} alt="icn" /> Sign in
                with Mircrosoft
              </>
            ) : (
              <LoadingIndicator />
            )} */}
          </button>
          {/* <Text color="#FFF" mb={0}>Don't have an account ? {' '} <Link onClick={signupAuthForm} color='teal.400' >signup.</Link></Text> */}
          {LoginDeniedMessage && (
            <p className="error-message" style={{ marginTop: "8px" }}>
              {LoginDeniedMessage}
            </p>
          )}
        </form>
        }
        {!loginToggle &&
        <form onSubmit={handleSignup} className="signup-page">
        <Flex flexDir={"column"} gap={4}  justifyContent={"center"} className="signupFields" color={"#FFF"}>
          <FormControl id="name" isRequired >
            {/* <FormLabel>Name</FormLabel> */}
            <Input
              size={"sm"}
              type="text"
              value={registerName}
              placeholder="Name"
              onChange={(e) => setRegisterName(e.target.value)}
              className="custom-input"
            />
          </FormControl>
          <FormControl id="email" isRequired>
            {/* <FormLabel>Email</FormLabel> */}
            <Input
              size={"sm"}
              type="email"
              placeholder="Email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              className="custom-input"
            />
          </FormControl>
          <FormControl id="organization" isRequired>
            {/* <FormLabel>Organization</FormLabel> */}
            <Input
              size={"sm"}
              type="text"
              placeholder="Organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            {/* <FormLabel>Password</FormLabel> */}
            <Input
              size={"sm"}
              type="password"
              placeholder="Password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
            />
          </FormControl>
          <FormControl id="confirmPassword" isRequired>
            {/* <FormLabel>Confirm Password</FormLabel> */}
            <Input
              size={"sm"}
              type="password"
              placeholder="Confirm password"
              value={confirmRegisterPassword}
              onChange={(e) => setConfirmRegisterPassword(e.target.value)}
            />
          </FormControl>
          <div className="condition-apply-con">
            <Checkbox isChecked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} colorScheme="pink" iconColor="white" borderColor="#FF0087" />
            <span>By Signing Up you agree with our <a href="https://origamis.ai/terms" target="_blank" rel="noopener noreferrer" className="condition-link">Terms & Condition</a>  and <a href="https://origamis.ai/privacy-policy" target="_blank" rel="noopener noreferrer" className="condition-link">Privacy Policy</a></span>
          </div>
          {/* {passwordMismatch && (
              <Text fontSize="sm" color="red.400">
                Password and Confirm Password do not match.
              </Text>
            )} */}
          <button type="submit" className="login-btn btn-pink" disabled={!termsAccepted || isSignup}>{isSignup? <PulseLoader color="#FFFFFF"/>:"Sign Up"}</button>
          <button type='button' className="login-btn btn-white mb-3" onClick={loginAuthForm}>Sign In</button>  
          {/* <Text color="#FFF" mt={2} mb={0}>Already have an account ? {' '} <Link onClick={loginAuthForm} color='teal.400' >login.</Link></Text> */}
        </Flex>
        </form>
        }

      </div>
      {(isSSOloading || disableBtn) && (
      <Flex position={"absolute"} bottom={0} right={0} paddingBottom={"14px"} w={"100%"} justifyContent={"flex-end"} mt={{base:"20px",md:"30px",lg:"40px"}} paddingRight={{base:"20px",md:"30px",lg:"40px"}}>
        <BeatLoader size={18} color="#FFFFFF"/>
      </Flex>
      )}
    </div>
  );
};

export default Login;
