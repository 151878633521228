import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: { "Content-Type": "application/json" },
});

class authenticationService {
	changePassword(userInfo) {
		return axiosInstance
			.post(`/user/change_password`, userInfo)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleCustomLogin(userInfo) {
		return api
			.post("/login_custom", userInfo)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

	handleLoginSSO(userInfo) {
		return api
			.post("/login_ms_sso", userInfo)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

	varifyEmail(email) {
		return axiosInstance
			.get(`/verify_unique_email?email=${email}`)
			.then((response) => {
				return response
			})
			.catch((err) => {
				console.log(err);
			});
	}

	registerNewUser(payload) {
		return api
			.post("/login_register", payload)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

	signupEmailVerification(token) {
		return api
			.post("/signupEmailVerification", { token })
			.then((response) => {
				return response;
			})
			.catch((err) => {
				throw err;
			});
	}

}

export default new authenticationService();
