import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';

interface CentralizeStoreProps {
  setSearchRes?: React.Dispatch<React.SetStateAction<any[]>>;
  setSearchedText?: React.Dispatch<React.SetStateAction<string>>
  setUploadedImage?: React.Dispatch<React.SetStateAction<string | null>>;
  searchRes?: any[];
  searchedText?: string
  uploadedImage ? : string | null
  setIsSuperAdminExist ? : React.Dispatch<React.SetStateAction<boolean>>
  isSuperAdminExist ? : boolean 
  setTagSearchData ?: React.Dispatch<React.SetStateAction<any>>;
  tagSearchData ?: any;
  setIsFilteredExist ?: React.Dispatch<React.SetStateAction<boolean>>;
  isFilteredExist ?: boolean,
  IsFilteredExistTrue ? : any,
  IsFilteredExistFalse ? : any,
  setSearchTagFormState ? : React.Dispatch<React.SetStateAction<any>>;
  searchTagFormState ? : any;
  setFilterState ? :  React.Dispatch<React.SetStateAction<any>>;
  filterState ? : any;
  setIsTagSearch ? : React.Dispatch<React.SetStateAction<boolean>>;
  isTagSearch ?: boolean;
  setIsSearchLoading ?: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchLoading ?: boolean;
}

const defaultContextValue: CentralizeStoreProps = {
  setSearchRes: () => {},
  setSearchedText: () => {},
  setUploadedImage: () => {},
  searchRes: [],
  searchedText : '',
  uploadedImage : '' || null, 
  setIsSuperAdminExist : () => {},
  isSuperAdminExist : false,
  setTagSearchData: () => {},
  tagSearchData : {},
  setIsFilteredExist: () => {},
  isFilteredExist : null,
  IsFilteredExistTrue : () => {},
  IsFilteredExistFalse : () => {},
  setSearchTagFormState : () => {},
  searchTagFormState : {},
  setFilterState : () => {},
  filterState : {},
  setIsTagSearch: () => {},
  isTagSearch : null,
  setIsSearchLoading: () => {},
  isSearchLoading : null
};

export const CentralizeStore = createContext<CentralizeStoreProps>(defaultContextValue);

interface CentralizeStoreProviderProps {
  children: ReactNode;
}

export const CentralizeStoreProvider = ({ children }: CentralizeStoreProviderProps) => {
  const [searchRes, setSearchRes] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [tagSearchData, setTagSearchData] = useState({})
  const [isTagSearch, setIsTagSearch] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSuperAdminExist, setIsSuperAdminExist] = useState(() => {
    const role = localStorage.getItem("role");
    return role === "superAdmin";
  });
  const [isFilteredExist, setIsFilteredExist] = useState(false);
  const [filterState, setFilterState] = useState({
    fromDate: "",
    toDate: "",
    selectedDomains: [],
    selectedScopes: [],
    selectedStatus: []
  });

  const [searchTagFormState, setSearchTagFormState] = useState({
    selectedDomain: '',
    selectedCategory: '',
    attributes: {},
    selectedAttributes: {},
    selectMatchType: ''
  });


  useEffect(() => {
    const role = localStorage.getItem("role");
    setIsSuperAdminExist(role === "superAdmin");
  }, []);

  const IsFilteredExistTrue =() =>  {
     setIsFilteredExist(true)
  }
  const IsFilteredExistFalse = () => {
    setIsFilteredExist(false)
  }

  
  return (
    <CentralizeStore.Provider value={{ setSearchRes, searchRes, setSearchedText, searchedText, setUploadedImage, uploadedImage, setIsSuperAdminExist, isSuperAdminExist, setTagSearchData, tagSearchData,setIsFilteredExist,isFilteredExist,IsFilteredExistTrue,IsFilteredExistFalse,setSearchTagFormState,searchTagFormState,setFilterState, filterState, setIsTagSearch,isTagSearch,setIsSearchLoading, isSearchLoading }}>
      {children}
    </CentralizeStore.Provider>
  );
};