import { MsalProvider } from '@azure/msal-react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './contexts/login-service/login';
import ModelOutputContainer from './pages/model-output-container/ModelOutputContainer';
import Layout from './pages/layout/Layout';
import UsersList from './pages/users/UserList';
import NotFound from './reusable-components/not-found-component';
import React, { useState, useEffect } from 'react';
import CreditProvider from './contexts/creditProvider/creditProvider';
import Taxonomy from './pages/taxonomy/taxonomy';
import ChangePassword from './pages/changepassword/changepassword';
import ProtectedRoute from './protectedroute';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from './contexts/login-service/config';
import SearchResult from './pages/search-result/SearchResult';
import UploadComponent from './pages/upload/Upload';
import Department from './pages/department/Department';
import Transaction from './pages/transactions/Transaction';
import Configuration from './pages/configuration/Configuration';
import VerifyEmailList from './pages/verify-email/VerifyEmail';
import IdleTimer from './reusable-components/IdleTimer';

const msalInstance = new PublicClientApplication(msalConfig);

const Routes = () => {

  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("role") === "Admin" || localStorage.getItem("role") === "superAdmin"
  );
  const [isSuperAdminExist, setIsSuperAdmin] = useState(localStorage.getItem("role") === "superAdmin");

  // const [msalInstance, setMsalInstance] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      await msalInstance.initialize(); // Initialize MSAL instance
      await msalInstance.handleRedirectPromise();
      setInitialized(true);
    };

    initializeMsal();
   }, []);

  function signOutClickHandler() {
    sessionStorage.clear();
    localStorage.clear();
    msalInstance?.logoutRedirect(); // Ensure msalInstance is not null before calling logoutRedirect
  }

  if (!msalInstance) {
    // Handle the case where MSAL instance is not yet initialized
    return null;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Login setIsAdmin={setIsAdmin} msalInstance={msalInstance} setIsSuperAdmin={setIsSuperAdmin} />
      ),
    },
    {
      path: "/login",
      element: (
        <Login setIsAdmin={setIsAdmin} msalInstance={msalInstance} setIsSuperAdmin={setIsSuperAdmin} />
      ),
    },
    {
      path: "layout",
      element: (
        <ProtectedRoute redirectTo={"/"}>
          <Layout isAdmin={isAdmin} signOutClickHandler={signOutClickHandler} />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "model-output",
          element: <ModelOutputContainer />
        },
        {
          path: "users",
          element: isAdmin ? <UsersList setIsAdmin={setIsAdmin} signOutClickHandler={signOutClickHandler} /> : <NotFound />,
        },
        {
          path: "taxonomy",
          element: <Taxonomy />
        },
        {
          path: "search-result",
          element: <SearchResult />
        },
        {
          path: "upload",
          element: <UploadComponent />
        },
        {
          path: "department",
          element: isAdmin ? <Department setIsAdmin={setIsAdmin} signOutClickHandler={signOutClickHandler} /> : <NotFound />,
        },
        {
          path: "transaction",
          element: <Transaction />
        },
        {
          path: "changepassword",
          element: <ChangePassword />
        },
        {
          path: "configuration",
          element: isSuperAdminExist ? <Configuration /> : <NotFound />
        }
      ],
    },
    {
        path: "/emailVerification",
        element: (
          <VerifyEmailList />
        ),
      },
  ]);

  return (
    <React.StrictMode>
      {initialized && (<MsalProvider instance={msalInstance}>
          <CreditProvider setIsAdmin={setIsAdmin}>
            <IdleTimer />
            <RouterProvider router={router} />
          </CreditProvider>
        </MsalProvider>)}
    </React.StrictMode>
  );
};

export default Routes;
