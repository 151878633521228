import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

let isTokenExpired = false;

const interceptorsSetup = (navigate) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const jwttoken = localStorage.getItem('token'); 
      const userEmail = localStorage.getItem('email'); 
      if (jwttoken && userEmail) {
        config.headers.Authorization = `Bearer ${jwttoken}`;
        config.headers.Email = userEmail;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

 
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
     
      if (error.response && error.response.data && error.response.data.error === "Token has expired") {
        // If token has expired
        sessionStorage.clear();
        localStorage.clear(); 
        isTokenExpired = true;
        navigate("/login"); 
      }
      return Promise.reject(error);
    }
  );
};

export { axiosInstance,isTokenExpired, interceptorsSetup };