import React, { useRef, useState } from 'react'
import { Box, Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import Styles from "../../reusable-components/ImageDrop/DropZone.module.css";
import SwitchStyle from "./ToggleSwitch.module.css";
import { GoFileDirectory } from "react-icons/go";
import uploadService from "../../api/uploadService";
import useCredit from "../../hooks/useCredit";
import { BarLoader } from "react-spinners";


const UploadZip = (props) => {
	const { isOpen, onOpen, onClose, modalContent, handleReFetch } = props;
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isPublic, setIsPublic] = useState(false);
	const fileInputRef = useRef(null);
	const email = localStorage.getItem("email");
	const [isLoading, setIsLoading] = useState(false);
	const { setCreditBalance } = useCredit();
	const toast = useToast();


	const handleFileChange = (e) => {
		const filesArray = Array.from(e.target.files);
		setSelectedFiles(filesArray);
	};

	const handlePrivacyChange = (e) => {
		setIsPublic(e.target.checked);
	};

	const handleFileUpload = async (e) => {
		e.preventDefault();

		// Prepare data object to return
		const formData = new FormData();
		formData.append('email', email);
		//   formData.append('scope', isPublic ? '1' : '0');
		formData.append('scope', '0');
		formData.append('files', selectedFiles[0]);

		try {
			setIsLoading(true);
			const response = await uploadService.uploadZip(formData);
			if (response.status === 200) {
				toast({
					title: `${response.data.message}`,
					position: "top",
					status: "success",
					duration: 3000,
					variant: "left-accent",
					isClosable: true,
				})
				setCreditBalance(response.data.balance)
				handleReFetch()
			}
			setSelectedFiles([]);

			onClose();
		} catch (err) {
			console.log("Upload error:", err);
		} finally {
			setIsLoading(false);
		}
	};

	const totalSelectedFilesCount = selectedFiles.length;
	const selectedFilesNames = selectedFiles.map(file => file.name).join(', ');

	return (
		<form onSubmit={handleFileUpload} className={SwitchStyle.uploadFormCon}>
			<Flex
				flexDir="column"
				alignItems="self-start"
				justifyContent="flex-start"
			>
				<div style={{ width: "100%" }} className={Styles.dropZoneArea} onClick={() => fileInputRef.current.click()}>
					<input
						type="file"
						accept=".zip"
						multiple
						onChange={handleFileChange}
						ref={fileInputRef}
						style={{ display: "none" }}
					/>
					<Flex alignItems={"center"} gap={"5px"} color={"#828282"}>
						<GoFileDirectory />
						<span>Browse Zip</span>
					</Flex>
				</div>
				{
					totalSelectedFilesCount > 0 && <Text fontWeight="500" color="#4F4F4F">
						File selected : {selectedFilesNames}
					</Text>
				}
				{/* <Flex width={"100%"} alignItems="center" justifyContent="center">
				<span>Private</span>
				<div className={SwitchStyle.checkbox_apple}>
				  <input
					className={SwitchStyle.yep}
					id="check-apple"
					type="checkbox"
					checked={isPublic}
					onChange={handlePrivacyChange}
				  />
				  <label htmlFor="check-apple"></label>
				</div>
				<span>Public</span>
			  </Flex> */}
			</Flex>
			<Flex mt={8} w={"100%"} alignItems={"end"} justifyContent={"flex-end"} gap={{ base: "10px", md: "15px" }}>
				<Button type="submit" id='btn' size={"sm"}>
					{isLoading ? <BarLoader color="#FFFFFF" /> : "Upload"}
				</Button>
				<Button colorScheme="red" variant={"outline"} size={"sm"} onClick={onClose}>
					Cancel
				</Button>
			</Flex>
		</form>
	)
}

export default UploadZip
