import axios from "axios";
import { axiosInstance } from "./intreceptor";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

class taxonomyService {
  
  getTaxonomy  = () => {
    return axiosInstance
    .get(`/taxonomy/gettaxonomy`)
    .then(response => {
      return response
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getDomain = () => {
    return axiosInstance
    .get(`/taxonomy/gettaxonomy`)
    .then(response => {
      return response.data
    })
    .catch((err) => {
      console.log(err);
    });
  }

  createDomain = (payload) => {
    return axiosInstance
      .post("/taxonomy/createdomain",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  updateDomain = (payload) => {
    return axiosInstance
      .post("/taxonomy/updatedomain",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  deleteDomain = (payload) => {
    return axiosInstance
      .post("/taxonomy/deletedomain",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  createCategory = (payload) => {
    return axiosInstance
      .post("/taxonomy/createcategories",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  updateCategory = (payload) => {
    return axiosInstance
      .post("/taxonomy/updatecategories",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  deleteCategory = (payload) => {
    return axiosInstance
      .post("/taxonomy/deletecategories",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  createAttributes = (payload) => {
    return axiosInstance
      .post("/taxonomy/createattributes",payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }


}

export default new taxonomyService();
