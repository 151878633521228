import React, { useState } from 'react';
import './ImageZoom.css';

const ImageZoom = ({ src, zoomLevel = 2 }) => {
  const [zoom, setZoom] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setZoom(true);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setZoom(false);
  };

  return (
    <div className="image-zoom-container">
      <div
        className="image-container"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img src={src} alt="Zoom" className="image" />
      </div>
      {zoom && (
        <div className="zoom-container">
          <img
            src={src}
            alt="Zoom"
            className="zoom-image"
            style={{
              transformOrigin: `${position.x}% ${position.y}%`,
              transform: `scale(${zoomLevel})`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageZoom;
