import React from 'react';
import './index.css'
import { Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found"><h1>404 - Not Found</h1><p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p> <NavLink to="/layout"><Button colorScheme={"blue"} size={"md"} variant={"outline"}>Home</Button></NavLink> </div>
  );
};

export default NotFound;