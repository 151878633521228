import { useState, useCallback } from 'react';
import configService from '../api/configService';

const useFetchConfigSetting = () => {
  const [configurationSetting, setConfigurationSetting] = useState(null);
  const [isLoadingConfigSetting, setIsLoadingConfigSetting] = useState(false);
  const [configSettingMsg, setConfigSettingMsg] = useState('');

  const fetchConfigSetting = useCallback(async () => {
    try {
      setIsLoadingConfigSetting(true);
      const response = await configService.getConfigSetting();

      const newConfig = response.configuration_data;

      const prevConfigString = localStorage.getItem('configSetting');
      const prevConfig = prevConfigString ? JSON.parse(prevConfigString) : {};
    
      if ((prevConfig.Intelli_Search !== newConfig.Intelli_Search || prevConfig.Intelli_Tag !== newConfig.Intelli_Tag) && (Object.keys(prevConfig).length !== 0)) {
        if(response.message !== "" || response.message.length>0){
            alert(`${response.message}`)
        }
        localStorage.setItem('configSetting', JSON.stringify(newConfig));
        window.location.reload();
      } else {
        localStorage.setItem('configSetting', JSON.stringify(newConfig));
        setConfigSettingMsg(response.message);
        setConfigurationSetting(newConfig);
      }

      setConfigSettingMsg(response.message);
      setConfigurationSetting(response.configuration_data);
    } catch (error) {
      console.error('Error fetching config setting:', error);
    } finally {
      setIsLoadingConfigSetting(false);
    }
  }, []);

  return {
    configurationSetting,
    isLoadingConfigSetting,
    configSettingMsg,
    fetchConfigSetting,
  };
};

export default useFetchConfigSetting;
