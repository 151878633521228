import { Box,  Button, Flex, Grid,Image, Stack,Text,Tooltip,} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Styles from "../../assets/styles/Navbar.module.css";
import { RxCross1, RxCross2 } from "react-icons/rx";
import { NavLink, useNavigate } from "react-router-dom";
import { CentralizeStore } from "../../contexts/CentralizeStoreProvider";
import { IoPricetagsOutline } from "react-icons/io5";
import { OverlayContext } from "../../contexts/overlay/OverlayContextProvider";
import useFetchConfigSetting from "../../hooks/useConfigSetting";
import SearchMenu from "./components/SearchMenu";
import isFileSelectIcon from "../../assets/Images/isFileSelect.svg";
import fileSelectedIcon from "../../assets/Images/fileSelected.svg";
import { BsDownload } from "react-icons/bs";
import Skeleton from "../../reusable-components/LoadingOverlay/Skeleton";
import configService from "../../api/configService";


const SearchResult = () => {
	const navigate = useNavigate()
	const { searchRes, searchedText, uploadedImage, tagSearchData, isTagSearch, isSearchLoading } = useContext(CentralizeStore)
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const { fromTagSearch, domain_name, domain_id, cat_name, cat_id, attributes } = tagSearchData;
	const { fetchConfigSetting } = useFetchConfigSetting()
	const { handleSetBlurFalse } = useContext(OverlayContext)
	const [selectedItems, setSelectedItems] = useState([]);
	const [hoveredItem, setHoveredItem] = useState(null);
	const [selectedImage, setSelectedImage] = useState([]);
	const configSetting = JSON.parse(localStorage.getItem("configSetting"))
  const [newConfigSetting, setNewConfigSetting] = useState(null);


	useEffect(() => {
		if (fromTagSearch) {
			setDrawerOpen(true);
		}
	}, [fromTagSearch]);

	useEffect(() => {
		handleSetBlurFalse()
    handleFetchLatestConfigSetting()
		fetchConfigSetting()
	}, [])

	const handleGoBack = () => {
		navigate('/layout')
	}

	useEffect(() => {
		const selectedData = selectedItems.map((index) => searchRes[index]);
		setSelectedImage(selectedData);
	}, [selectedItems]);

	const selectCounter = useMemo(() => selectedItems.length, [selectedItems]);

	const handleItemClick = useCallback(
		(index) => {
			setSelectedItems((prevSelectedItems) => {
				if (prevSelectedItems.includes(index)) {
					return prevSelectedItems.filter((item) => item !== index);
				} else {
					return [...prevSelectedItems, index];
				}
			});
		},
		[setSelectedItems]
	);

	const handleClearSelection = useCallback(() => {
		setSelectedItems([]);
	}, []);

	const handleItemMouseEnter = useCallback((index) => {
		setHoveredItem(index);
	}, []);

	const handleItemMouseLeave = useCallback(() => {
		setHoveredItem(null);
	}, []);

	const handleSelectAll = useCallback(() => {
		if (selectedItems.length === searchRes.length) {
			setSelectedItems([]);
		} else {
			setSelectedItems(searchRes.map((_, index) => index));
		}
	}, [selectedItems, searchRes]);

	const openDrawer = () => {
		setDrawerOpen(true);
	};

	const closeDrawer = () => {
		setDrawerOpen(false);
	};

	const handleViewModel = async (id) => {
		const ids = [id];
		let storedEmail = localStorage.getItem("email")
		const params = new URLSearchParams({
			ids: JSON.stringify(ids),
			email: storedEmail
		}).toString();

		navigate(`/layout/model-output?${params}`);
	}
	interface MatchCondition {
		[key: string]: string[];
	}

	const formatMatchCondition = (conditions: MatchCondition[]): string => {
		return conditions
			.map(condition => {
				const [key, values] = Object.entries(condition)[0];
				return `<strong>${key}</strong>: ${values.join(', ')}`;
			})
			.join(' | ');
	};

	const downloadImage = (url, index) => {
		if (!url) return;

		let filename = `image_${index + 1}.webp`;
		const link = document.createElement('a');
		link.href = url;
		link.download = filename;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

  const handleFetchLatestConfigSetting = async () => {
    try{
      const response = await configService.getConfigSetting();
      setNewConfigSetting(response.configuration_data)
    }catch(err){
      console.log("err while getting config setting: ", err)
    }
  }

// if(searchRes && searchRes.length === 0 && !fromTagSearch && !isSearchLoading) {
//   handleSetBlurFalse()
//  return <Flex flexDir={"column"} gap={2} alignItems={"center"} justifyContent={"center"} height={"70vh"}><h1>No result found !</h1><NavLink to="/layout"><Button colorScheme={"blue"} size={"md"} variant={"outline"}>Home</Button></NavLink></Flex>
//  }
  return (
    <div>
    <SearchMenu 
    selectCounter={selectCounter}
    onClearSelection={handleClearSelection}
    selectedImage={selectedImage}
    handleSelectAll={handleSelectAll}
    />
    <Box padding={{base: "10px 10px", sm: "10px 13px", md: "10px 50px", lg: "10px 70px"}} mt={{base:"180px",sm:"179px",md:"60px",lg:"45px"}}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        {!isTagSearch && (
        <span
          style={{ color: "#333333", marginLeft: "0px", fontSize: "17px" }}
          className={Styles.search_heading}
        >
          Searching into:{" "}
          <span id={Styles.search_subHeading} style={{ fontWeight: "500" }}>
            My files
          </span>
        </span>)}
              <span style={{ color: "#8C8C8C" }}>
                Total Search Result:{" "}
                <span style={{ fontWeight: "600", color: "#333333" }}>{searchRes.length === 0 ? "0" : (searchRes.length < 10 ? `0${searchRes.length}` : searchRes.length)}</span>
              </span>
        <Text cursor={"pointer"}  onClick={handleGoBack}><RxCross1 size={23}/></Text>
      </Flex>

      <Box borderBottom={"1px solid #BDBDBD"} pb={"2px"}>
        {!isTagSearch && (
          <Stack color={"#C7C7C7"}>
            { (searchedText !== undefined) &&
              searchedText === "" ? <span>Search by image</span>: <span><Text color={"#3165DF"} fontWeight={600} m={'0px'} fontSize={"15px"}>AI thought process</Text><Text m={"0px"} color={"#222121"} fontWeight={400}>{searchedText}</Text></span>
            }   
          </Stack>)}
      </Box>
      {
        searchRes && searchRes.length === 0 && !fromTagSearch && !isSearchLoading ? (
          <Flex flexDir={"column"} gap={2} alignItems={"center"} justifyContent={"center"} height={"58vh"}><h3>No result found !</h3></Flex>
        ):
      <Flex
        alignItems={"flex-start"}
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "10px", md: "12px", lg: "15px" }}
        justify={"space-around"}
        mt={"18px"}>
        {(uploadedImage && searchRes.length >0) &&
        <Flex
          width={{base:"100%",md:"70%",lg:"60%",xl:"50%"}}
          height={{ base: 'auto', md: 'auto', lg: '60vh' }}
          alignItems={"center"}
          justifyContent={"center"}
          padding={{ base: "7px", sm: "8px", md: "25px", lg: "30px 45px" }}
          backgroundColor={"#333333"}
          overflow={'hidden'}
        >
          <Image
            src={uploadedImage}
            alt="img"
            objectFit={'contain'}
          />
        </Flex>
        }
        <Grid
          gridTemplateColumns={{
            base: "repeat(1, 200px)",
            md: "repeat(2, 1fr)",
            lg:`${uploadedImage === null || uploadedImage == '' ? 'repeat(6, 1fr)' : 'repeat(4, 200px)'}`,
          }}
          margin={"auto"}
          gap={{ base: "10px", md: "15px", lg: "22px" }}
          transition={"ease all 0.6s"}
        >
          {searchRes.length > 0 &&
            searchRes?.map((el, i) => {
                  const IconComponent = el.icn;
                  const isSelected = selectedItems.includes(i);
                  const isHovered = hoveredItem === i;
                  const displayIcon = isSelected
                    ? fileSelectedIcon
                    : isFileSelectIcon;
                    const tooltipLabel = el.match_condition ? formatMatchCondition(el.match_condition) : '';

              return (
                <Tooltip key={i} label={ el.relevancy_percentage && el.reason ? (
                  <>
                    <strong>Relevancy :</strong> {`${el.relevancy_percentage}%`}
                    <br />
                    <strong>Reason :</strong> {el.reason}
                  </>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: tooltipLabel }} />
                )
              }
              fontSize="xs"
              placement="auto-start"
              aria-label={el.relevancy_percentage && el.reason ? "Relevancy Tooltip" : "Match Condition Tooltip"}
              isDisabled={!el.match_condition && !(el.relevancy_percentage && el.reason)}
            >
                <Flex
                  position={"relative"}
                  flexDir={"column"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  textAlign={"center"}
                  borderRadius={"7px"}
                        width={"100%"}
                        height={"100%"}
                        _hover={{
                          border: isSelected ? "2px solid #7BD6DA" : "1px solid #828282"
                        }}
                        transition={"ease all 0.6s"}
                        padding={"3px"}
                        onMouseLeave={handleItemMouseLeave}
                        border={isSelected ? "2.4px solid #7BD6DA" : "default"}
                        boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
                  onMouseEnter={() => handleItemMouseEnter(i)}
                >
                  <Image
                    // w={"220px"}
                    // h={"220px"}
                    width={"100%"}
                    height={"100%"}
                    borderRadius={"5px"}
                    src={el.image_link}
                    alt="img"
                    objectFit={"cover"}
                    cursor={"pointer"}
                    // onClick={() => handleView(el.image_id, el.image_tag_status)}
                    onClick={() => handleItemClick(i)}
                  />
                  <Text m={"0px"} pb={"7px"} noOfLines={1} fontSize={"10px"} cursor={"pointer"} color={"#AAA"}
                  onClick={() => handleItemClick(i)}
                  >
                  {el.image_uploaded_by}
                  </Text>
                  
                       <Text m={"0px"} pb={"7px"} noOfLines={1} fontSize={"10px"} cursor={"pointer"} color={"#AAA"}>
                          Uploaded : {" "}
                          {new Date(el.image_uploaded_at)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .replace(/\//g, "-")}
                        </Text>
                        <Flex w={"100%"} alignItems={"center"} justify={"space-around"} px={"7px"} >
                        {
                              (configSetting?.Intelli_Tag || newConfigSetting?.Intelli_Search === true) && 
                              <>
                          {el.tag_status === 0 ? (
                            <Button
                              colorScheme="black.500"
                              variant="ghost"
                              cursor="pointer"
                              onClick={() => handleViewModel(el.image_id)}
                            >
                              {" "}
                              <IoPricetagsOutline
                                cursor={"pointer"}
                                color="#BDBDBD"
                                fontSize={"17px"}
                              />{" "}
                            </Button>
                          ) : (
                            <Button
                              colorScheme="black.500"
                              variant="ghost"
                              cursor="pointer"
                              onClick={() => handleViewModel(el.image_id)}
                            >
                              <IoPricetagsOutline
                                cursor={"pointer"}
                                color="#4F256F"
                                fontSize={"17px"}
                              />
                            </Button>
                          )}
                           </>
                            }
                            <Button onClick={() => downloadImage(el.image_link, i)} colorScheme="black.500" variant="ghost" cursor="pointer"><BsDownload color="#4F256F" fontSize="17px"/></Button>
                        </Flex>
                  <Box position={"absolute"} top={0} right={0} padding={"4px 4px 0px 0px"}>
                  {/* {el.image_tag_status == "0" ? (
                            <Button colorScheme="black.500" variant="ghost" cursor="pointer">
                              <IoPricetagsOutline color="#BDBDBD" fontSize={"17px"}/>
                            </Button>
                          ) : (
                            <Button colorScheme="black.500" variant="ghost" cursor="pointer">
                              <IoPricetagsOutline color="#4F256F" fontSize={"17px"}/>
                            </Button>
                  )} */}
                  {(isHovered || isSelected) && (
                          <Box padding={"9px 9px 0px 0px"} onClick={() => handleItemClick(i)} cursor={"pointer"}>
                            <Image src={displayIcon} alt="selectIcon" />
                          </Box>
                        )}
                  </Box>
                </Flex>
                </Tooltip>
              );
            })}
        </Grid>
      </Flex>
      }
    </Box>
    </div>
  );
};

export default SearchResult;
