import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { User } from "../../types";
import { BiPencil, BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { trackPromise } from "react-promise-tracker";
import DataTable from "react-data-table-component";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import { Toast, ToastContainer } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import {Flex} from "@chakra-ui/react";
import { RxCross1 } from "react-icons/rx";
import DepartmentForm from "./components/DepartmentForm";
import { interceptorsSetup } from "../../api/intreceptor";
import departmentService from "../../api/departmentService";
import useFetchConfigSetting from "../../hooks/useConfigSetting";

const Department = ({ setIsAdmin, signOutClickHandler }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateUserObj, setUpdateUserObj] = useState<User | null>(null);
  const [users, setUsers] = useState<User[] | []>([]);
  const [loaderMessage, setLoaderMessage] = useState("Fetching Users...");
  const userEmail = localStorage.getItem("email");
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [toastType, setToastType] = useState("info");
  const [toastText, setToastText] = useState("");
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[] | []>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreateDepartment, setIsCreateDepartment] = useState(null);
  const {fetchConfigSetting} = useFetchConfigSetting()
  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate])

  const columns = [
    {
      name: "Department Name",
      // width: "200px",
      selector: (row) => row.department_name,
      sortable: true,
    },
    {
      name: "Department Code",
      // width: "200px",
      selector: (row) => row.department_code,
      sortable: true,
    },
    
    {
      name: "Status",
      // width: "100px",
      selector: (row) =>
        row.status === 1 ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
      // width: "110px",
      cell: (row) => (
        <>
          <BiPencil
            className="fa fa-pencil pointer action-icon"
            style={{ color: "#222", marginRight: "10px", cursor: "pointer" }}
            title="Edit Department"
            onClick={() => updateUser(row)}
          />

          {row.status === 1 ? (
            <BiToggleRight
              className="fa fa-solid fa-toggle-on text-success pointer action-icon"
              style={{ color: "#222", cursor: "pointer" }}
              title="Deactivate Department"
              onClick={() => changeUserStatus({ status: 0, id: row.id })}
            />
          ) : (
            <BiToggleLeft
              className="fa fa-solid fa-toggle-off text-danger action-icon"
              style={{ color: "#222", cursor: "pointer" }}
              title="Activate Department"
              onClick={() => changeUserStatus({ status: 1, id: row.id })}
            />
          )}
        </>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const tableStyles = {
    head: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#FFFFFF",
        display: "flex",
      alignItems: "center",
      justifyContent: "center",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        display: "flex",
      alignItems: "center",
      justifyContent: "center",
      },
    },
  };

  const NoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <strong>No users found</strong>
    </div>
  );

  const changeUserStatus = ({ status, id }) => {
    let confirmUpdate = false;
    if (status == 1) {
      confirmUpdate = window.confirm(
        "Are you sure you want to activate this department?"
      );
    } else {
      confirmUpdate = window.confirm(
        "Are you sure you want to Deactivate this department?"
      );
    }

    if (confirmUpdate) {
      setLoading(true);
      trackPromise(
        departmentService
          .changeDepartmentStatus({ status, id })
          .then((response) => {
            if(response.status === 200){
              getDepartsments();
              toast({
                title: `${response.data.new.message}`,
                position: "top",
                status: "success",
                duration: 3000,
                variant: "left-accent",
                isClosable: true,
              });
            }
          })
          .catch((err) => {
            alert(err.response.data.error);
            setLoading(false);
          })
      );
    }
  };

  const getDepartsments = () => {
    setLoading(true);
    trackPromise(
      departmentService
        .getDepartment()
        .then((response) => {
          const { departments } = response.data;
          setUsers(departments);
          setFilteredUsers(departments);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        })
    );
  };

  useEffect(() => {
    getDepartsments();
    fetchConfigSetting();
  }, []);

  useEffect(() => {
    const matchingUsers = users?.filter((user: User) => {
      return (
        user?.department_name.toLowerCase().match(search.trim().toLowerCase()) ||
        user?.department_code.toLowerCase().match(search.trim().toLowerCase()) ||
        user?.created_on.toLowerCase().match(search.trim().toLowerCase()) ||
        user?.id.toLowerCase().match(search.trim().toLowerCase())
      );
    });
    setFilteredUsers(matchingUsers);
  }, [search]);

  const createUser = () => {
    setIsCreateUser(true);
    setShowModal(true);
    onOpen()
    setIsCreateDepartment(true)
  };

  const closeModal = () => {
    setIsCreateDepartment(null)
    setShowModal(false);
    setIsCreateUser(false);
    setIsUpdateUser(false);
    setUpdateUserObj(null);
  };

  const updateUser = (userObj: User) => {
    setIsCreateDepartment(false)
    setIsCreateUser(false);
    setIsUpdateUser(true);
    setUpdateUserObj(userObj);
    setShowModal(true);
    onOpen()
  };
  
  const toggleToast = () => setDisplayToast(!displayToast);

  return (
    <div>
      <Flex
        padding={"0px 0px 3px 0px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"0.5px solid #BDBDBD"}
        mt={{ base: "8px", md: "12px", lg: "auto" }}
        mb={{ base: "6px", md: "9px", lg: "12px" }}
      >
        <h2>Department</h2>
        
        <NavLink to="/layout" className="crossBtn">
          <span><RxCross1/></span>
        </NavLink>
      </Flex>
      
      <Flex
        alignItems={"center"}
        width={{ base: "auto", md: "auto", lg: "100%" }}
        gap={{ base: "10px", md: "15px", lg: "20px" }}
      >
        <input
          className="form-control"
          type="search"
          placeholder="Search department name, department code, id"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
        <Button onClick={createUser} id="btn" size={"sm"}>
          <span className="plusIcon"><FaPlus/></span> Add
        </Button>
      </Flex>
      <div className="users-list">
        {
        showModal && 
        <DepartmentForm onOpen={onOpen} isOpen={isOpen} onClose={onClose} updateUserObj={updateUserObj} isCreateDepartment={isCreateDepartment} setIsCreateDepartment={setIsCreateDepartment} allDept={filteredUsers} getDepartsments={getDepartsments} closeModal={closeModal}/>
        }
        </div>
      <div className="table-data">
        {loading && <LoadingOverlay message={loaderMessage} />}
        {
          <DataTable
            persistTableHead={true}
            columns={columns}
            data={filteredUsers}
            noDataComponent={<NoDataComponent />}
            customStyles={tableStyles}
            pagination
          />
        }
        {
          <ToastContainer
            className="p-3"
            position={"top-end"}
            style={{ zIndex: 9999 }}
          >
            <Toast
              show={displayToast}
              onClose={toggleToast}
              bg={toastType}
              delay={5000}
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Message</strong>
              </Toast.Header>
              <Toast.Body style={{ color: "#FFFFFF" }}>{toastText}</Toast.Body>
            </Toast>
          </ToastContainer>
        }
      </div>
    </div>
  );
};

export default Department;
