import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Styles from "./DropZone.module.css";
import { MdOutlineDone } from "react-icons/md";

const UploadedImg = ({ uploadedImg }) => {
  return (
    <div>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        gap={"6px"}
        mb={"5px"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          height={"22px"}
          width={"22px"}
          borderRadius={"50%"}
          border={"3px solid white"}
          background={"#7AB800"}
          color={"white"}
        >
          <MdOutlineDone />
        </Flex>
        <span>image uploaded</span>
      </Flex>
      <Flex
        width={{ base: "130px", lg: "160px" }}
        height={{ base: "80px", md: "120px" }}
        alignItems="center"
        justifyContent="center"
        border="5px solid #E0E0E0"
        borderRadius="10px"
        overflow="hidden"
        mb="5px"
      >
        <Image
          src={uploadedImg}
          alt="img"
          objectFit="contain"
          width="100%"
          height="100%"
        />
      </Flex>
    </div>
  );
};

export default UploadedImg;
