import { Outlet, useNavigate } from "react-router-dom";
import styles from "./Layout.module.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import useCredit from "../../hooks/useCredit";
import Navbar from "../../reusable-components/header/Navbar";
import UploadComponent from "../upload/Upload";
import { Box } from "@chakra-ui/react";
import { OverlayContext } from "../../contexts/overlay/OverlayContextProvider";
import useCurrentRoute from "../../hooks/useCurrentRoute";
import GoTop from "../../reusable-components/GoToTop/GoTop";

const Layout = ({ isAdmin, signOutClickHandler }) => {
  const { creditBalance } = useCredit()
  const [showUserActions, setShowUserActions] = useState(false);
  const userActionsRef = useRef(null);
  const navigate = useNavigate();
  const msalResponse = localStorage.getItem('msalResponse')
  const currentRoute = useCurrentRoute();
  const {isBlur} = useContext(OverlayContext)
  const parentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userActionsRef.current && !userActionsRef.current.contains(event.target)) {
        setShowUserActions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const signOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/login');
  }

  const onClickHandler = msalResponse ? signOutClickHandler : signOut;

  return (
    <div className={styles.layout}>
      <Navbar onClickHandler={onClickHandler} creditBalance={creditBalance}/>
      <div className={styles.mainWrapper} ref={parentRef}>
        <Box position={"relative"}>
        {isBlur && (
                <Box display={isBlur ? "flex" : "none"} className={styles.overlayStyles}>
                </Box>
            )}
        </Box>
      {
        currentRoute === "/layout" && <UploadComponent/>
      }
        <Outlet />
      {currentRoute === '/layout' && <GoTop parentRef={parentRef}/>}
      </div>
    </div>
  );
};

export default Layout;