import React, { useContext, useEffect, useState } from "react";
import { Button,Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, useToast, Skeleton} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { CgRename } from "react-icons/cg";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiSquaresFourLight } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import { CiFolderOn } from "react-icons/ci";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LiaFileUploadSolid } from "react-icons/lia";
import { AiOutlineFileZip } from "react-icons/ai";
import { CiCamera } from "react-icons/ci";
import UploadModal from "./UploadModal";
import ToggleSwitchStyles from "./ToggleSwitch.module.css";
import useCurrentRoute from "../../hooks/useCurrentRoute";
import { interceptorsSetup } from "../../api/intreceptor";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { LuRefreshCcw } from "react-icons/lu";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { IoLockOpenOutline, IoPricetagsOutline } from "react-icons/io5";
import { RiEyeLine } from "react-icons/ri";
import { FaRegHeart, FaSortAmountDown } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io";
import { TbClearAll } from "react-icons/tb";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import RenameModal from "./components/RenameModal";
import uploadService from "../../api/uploadService";
import viewOutput from "../../api/viewOutput";
import generateTagService from "../../api/generateTagService";
import LoadingOverlay from "../../reusable-components/LoadingOverlay/LoadingOverlay";
import useCredit from "../../hooks/useCredit";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Filter from "./components/Filter";
import TagSearch from "./components/TagSearch";
import { CentralizeStore } from "../../contexts/CentralizeStoreProvider";
import CustomButton from "./components/CustomButton";
import '../../assets/global.css'
import configService from "../../api/configService";

interface UploadMenuProps {
  selectCounter: number;
  onClearSelection?: () => void;
  handleToggleView?: () => void;
  toggleView: boolean;
  selectedImage : any,
  handleReFetch?: () => void,
  handleResetSelectedImages?: () => void,
  handleSelectAll?: () => void,
  toggleFilter ? : () => void
  setUploadedFile ? : React.Dispatch<React.SetStateAction<any[]>>
  totalUploadedFile ? : any
}

const UploadMenu = ({
  selectCounter,
  onClearSelection,
  handleToggleView,
  toggleView,
  selectedImage,
  handleReFetch,
  handleResetSelectedImages,
  handleSelectAll,
  toggleFilter,
  setUploadedFile,totalUploadedFile
}: UploadMenuProps) => {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isRenameModalOpen, onOpen: onRenameModalOpen, onClose: onRenameModalClose } = useDisclosure();
  const { isOpen: isFilterDrawerOpen, onOpen: onFilterDrawerOpen, onClose: onFilterDrawerClose } = useDisclosure();
  const { isOpen: isTagSearchDrawerOpen, onOpen: onTagSearchDrawerOpen, onClose: onTagSearchDrawerClose } = useDisclosure();
  const [modalContent, setModalContent] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isScopeLoading, setIsScopeLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [isImageTagGenerating, setIsImageTagGenerating] = useState(false);
  const { setCreditBalance } = useCredit();
  const [isFilterSelect , setIsFilterSelect] = useState(false); 
  const {IsFilteredExistFalse, isFilteredExist , setFilterState,setSearchTagFormState} = useContext(CentralizeStore)
  const configSetting = JSON.parse(localStorage.getItem("configSetting"))
  const [newConfigSetting, setNewConfigSetting] = useState(null);
  const toast = useToast()

  useEffect(() => {
    interceptorsSetup(navigate);
  }, [navigate]);

  useEffect(() => {
    handleFetchLatestConfigSetting()
  },[])
  const handleOpen = (content) => {
    setModalContent(content);
    onOpen();
  };

  const handleRename = () => {
    if(!(selectCounter > 1 || selectCounter === 0)){
      onRenameModalOpen();
    }else{
      return toast({
        title: `Select only one image for rename !`,
        position: "top",
        status: "warning",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
    }
  }

  const handleAddToFav = async() => {
    if(!(selectCounter > 0 )) {
     return toast({
        title: `Image not select !`,
        position: "top",
        status: "warning",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
    }
    const ids = selectedImage?.map(image => image.id);
    const payload = {
      ids,
      is_favourite: true
    };
    try{
      setIsLoading(true)
      const response = await uploadService.addToFavourite(payload)
      if(response.status === 200){
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      handleReFetch()
      }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("add to fav err:", err)
    }finally{
      handleResetSelectedImages()
      setIsLoading(false)
      onClearSelection()
    }
  }

  const handleChangeScope = async(scope:number) => {
    if(!(selectCounter > 0 )) {
      return toast({
         title: `Image not select !`,
         position: "top",
         status: "warning",
         duration: 3000,
         variant: "left-accent",
         isClosable: true,
       })
     }
    const ids = selectedImage.map(image => image.id);
    const payload = {
      ids,
      scope: scope
    };

    try{
      setIsScopeLoading(true)
      const response = await uploadService.changeScope(payload)
      if(response.status === 200){
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      handleReFetch()
      }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("scope err:", err)
    }finally{
      handleResetSelectedImages()
      setIsScopeLoading(false)
      onClearSelection()
      setIsPopoverOpen(false);
    }
  }

  const handleViewModel = async() => {
    if(!(selectCounter > 0 )) {
      return toast({
         title: `Image not select !`,
         position: "top",
         status: "warning",
         duration: 3000,
         variant: "left-accent",
         isClosable: true,
       })
     }

    const ids = selectedImage?.map(image => image.id);
    const payload = {
      ids,
      email: localStorage.getItem("email")
    };

    let storedEmail =localStorage.getItem("email")
    const params = new URLSearchParams({
      ids: JSON.stringify(ids),
      email: storedEmail
    }).toString();
    
    navigate(`/layout/model-output?${params}`);
  }

  const handleGenerateImageTag = async() => {
    if(!(selectCounter > 0 )) {
     return toast({
        title: `Image not select !`,
        position: "top",
        status: "warning",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
    }
    
    const invalidImages = selectedImage.filter(image => !image.domain || image.domain.length === 0);
    if (invalidImages.length > 0) {
        const ids = invalidImages.map(image => image.id).join(', ');
        return toast({
            title: `Domain is not present for this id(s): ${ids}`,
            position: "top",
            status: "warning",
            duration: 5000,
            variant: "left-accent",
            isClosable: true,
        });
    }

    const ids = selectedImage?.map(image => image.id);
    const payload = {
      image_ids: ids,
      email: localStorage.getItem("email")
    };
    try{
      setIsImageTagGenerating(true)
      const response = await generateTagService.generateImageTag(payload)
      if(response.status === 200){
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 5000,
          variant: "left-accent",
          isClosable: true,
        })
        handleReFetch()
        setCreditBalance(response.data.balance)
      }
    }catch(err){
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("generate img tag err:", err)
    }finally{
      setIsImageTagGenerating(false)
    }
  }


  const handleDelteImage = async () => {
    if(!(selectCounter > 0 )) {
      return toast({
         title: `Image not select !`,
         position: "top",
         status: "warning",
         duration: 3000,
         variant: "left-accent",
         isClosable: true,
       })
     }
    
    const ids = selectedImage?.map(image => image.id);
    const payload = {ids}

    let confirmDelete = false;
    confirmDelete = window.confirm(
     "Are you sure you want to Delete ?"
   );
   if(confirmDelete) {
    try{
      setIsDeleting(true);
      const response = await uploadService.deleteImage(payload);
      if(response.status === 200) {
        toast({
          title: `${response.data.message}`,
          position: "top",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          isClosable: true,
        })
      handleReFetch()
      }

    }catch(err){
      console.log("image delete err:", err)
      toast({
        title: `${err.response.data.detail}`,
        position: "top",
        status: "error",
        duration: 3000,
        variant: "left-accent",
        isClosable: true,
      })
      console.log("image delete err:", err.response.data.detail)
    }finally{
      handleResetSelectedImages()
      setIsDeleting(false)
      onClearSelection()
    }
  }
  }

  const downloadImage = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadImg = () => {
    if(!(selectCounter > 0 )) {
      return toast({
         title: `Image not select !`,
         position: "top",
         status: "warning",
         duration: 3000,
         variant: "left-accent",
         isClosable: true,
       })
     }
      const imageLinks = selectedImage?.map(image => image.processed_image_url);
      imageLinks.forEach((url, index) => {
        setTimeout(() => {
          const filename = `image_${index + 1}.webp`;
          downloadImage(url, filename);
        }, index * 500);
      });
  }

  const HandleRefresh = () => {
    IsFilteredExistFalse()
    setIsRotating(true);
    handleReFetch()
    if(isFilteredExist === true) {
      setFilterState({
        fromDate: "",
        toDate: "",
        selectedDomains: [],
        selectedScopes: [],
        selectedStatus: [],
      });
      setSearchTagFormState({
        selectedDomain: '',
        selectedCategory: '',
        attributes: {},
        selectedAttributes: {},
        selectMatchType: ''
      })
    }
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  }

  const handleSelectFilter = () => {
    onFilterDrawerOpen()
    // setIsFilterSelect(!isFilterSelect)
    // toggleFilter()
  }

  const handleTagSearch = () => {
    onTagSearchDrawerOpen()
  }

  const handleFetchLatestConfigSetting = async () => {
    try{
      const response = await configService.getConfigSetting();
      setNewConfigSetting(response.configuration_data)
    }catch(err){
      console.log("err while getting config setting: ", err)
    }
  }

  if(isImageTagGenerating) {
    return <LoadingOverlay message="Generating ..."/>
  }

  return (
    <div>
      <Flex
        flexDir={{ base: "column-reverse",sm:"row" ,md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        gap={{ base: "5px", md: "10px", lg: "17px" }}
        position={"fixed"}
        top={{base:"114px",sm:"135",md:"100px",lg:"96px"}}
        left={"0"}
        right={"0"}
        zIndex={'1'}
      >
        
        <Flex
          flexWrap={"wrap"}
          borderRadius={"8px"}
          alignItems={"center"}
          justifyContent={"space-around"}
          color={"#333333"}
          padding={"4px 10px"}
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
          bg={"#FFFFFF"}
          className={ToggleSwitchStyles.upLoadMenuCon}
          gap={"5px"}
        >
          {(currentRoute === "/layout" || currentRoute === "/layout/upload") && (
          <>
            <Menu isLazy>
              <MenuButton
                width="90px"
                height="32px"
                borderRadius="20px"
                bgGradient="linear(to-r, #F90A8B, #F90A8B)"
                color="#FFFFFF"
                _hover={{
                  color: "#FFFFFF",
                }}
                fontSize="25px"
              >
                <Flex alignItems={"center"} gap={"7px"} justifyContent={"center"}>
                  <FiPlus size={20}/> <Text fontWeight={600} mb={0}>Upload</Text>
                </Flex>
              </MenuButton>
              <MenuList color={"#4F4F4F"}>
                <MenuItem gap="5px" onClick={() => handleOpen("fileUpload")}>
                  <LiaFileUploadSolid size="18px" /> <span>File Upload</span>
                </MenuItem>
                <MenuItem gap="5px" onClick={() => handleOpen("zipUpload")}>
                  <AiOutlineFileZip size="18px" /> <span>Zip Upload</span>
                </MenuItem>
                {/* <MenuItem gap="5px" onClick={() => handleOpen("camera")}>
                  <CiCamera size="18px" /> <span>Camera</span>
                </MenuItem> */}
                {/* <MenuItem gap="5px" onClick={() => handleOpen("webScraping")}>
                <CiCamera size="18px" /> <span>Web Scraping</span>
                </MenuItem> */}
              </MenuList>
            </Menu>
            <UploadModal
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
              modalContent={modalContent}
              handleReFetch={handleReFetch}
            />
          </>
        )}

          {(configSetting?.Intelli_Tag ||newConfigSetting?.Intelli_Search === true) &&
          <Button
            borderRadius={"50px"}
            border={selectCounter > 0  ? "none" : "1px solid #F90A8B" }
            gap={"8px"}
            // bgGradient="linear(to-r, #F90A8B, #F90A8B)"
            background={selectCounter > 0 ? "#F90A8B" : "none"}
            color={selectCounter > 0  ? "#FFFFFF" : "#F90A8B"}
            variant="ghost"
            size={"sm"}
            _hover={{
              color: selectCounter > 0 ? "#FFFFFF" : "#F90A8B",
            }}
            onClick={handleGenerateImageTag}
            isDisabled={isImageTagGenerating}
          >
            Generate Tags
          </Button>}
          {/* <Tooltip label='Tags Search' display={{base:"block",md:"none"}}>
          <Button display={"flex"} flexDir={{md:"column", lg:"row"}} onClick={handleTagSearch} gap={"7px"} colorScheme="black.500" variant="ghost">
           <IoPricetagsOutline/> <Text m={'0px'} display={{base:"none",md:"block"}}>Tags Search</Text>
          </Button>
          </Tooltip> */}
          {/* <CustomButton label='Tags Search' onClick={handleTagSearch} icon={<IoPricetagsOutline/>}>
            Tags Search
          </CustomButton> */}
          <CustomButton label='Filter' onClick={handleSelectFilter} icon={<FaSortAmountDown />}>
             Filters
          </CustomButton>
          {/* <Button gap={"7px"} colorScheme="black.500" variant="ghost" onClick={handleSelectFilter}>
            <FaSortAmountDown/> Filter
          </Button> */}
          <CustomButton label={isFilteredExist ? "Reset Filter" : "Refresh"} onClick={HandleRefresh} icon={<LuRefreshCcw className={isRotating ? `${ToggleSwitchStyles.rotating}` : ''} />}>
           {isFilteredExist ? "Reset Filter" : "Refresh"}
          </CustomButton>
          {/* <Button onClick={HandleRefresh} gap={"7px"} colorScheme="black.500" variant="ghost">
            <LuRefreshCcw className={isRotating ? `${ToggleSwitchStyles.rotating}` : ''} /> {isFilteredExist ? "Reset Filter" : "Refresh"}
          </Button> */}
          <CustomButton label='View' onClick={handleViewModel} disabled={selectCounter === 0 || isLoading} color={selectCounter === 0 ? "#BDBDBD" : ""} icon={<RiEyeLine />}>
           View
          </CustomButton>
          {/* <Button onClick={handleViewModel} disabled={selectCounter === 0 || isLoading} color={selectCounter === 0 ? "#BDBDBD" : ""} gap={"7px"} colorScheme="black.500" variant="ghost">
            <RiEyeLine /> View
          </Button> */}
          <CustomButton label='Download' onClick={handleDownloadImg} color={selectCounter === 0 ? "#BDBDBD" : ""} icon={<BsDownload />}>
           Download
          </CustomButton>
          {/* <Button gap={"7px"} colorScheme="black.500" variant="ghost" color={selectCounter === 0 ? "#BDBDBD" : ""} onClick={handleDownloadImg}>
            <BsDownload /> Download
          </Button> */}
          {/*<Button gap={"7px"} colorScheme="black.500" variant="ghost">
            <GrShareOption /> Share
          </Button>*/}
          {/* <Button gap={"7px"} colorScheme="black.500" variant="ghost">
            <PiLinkSimpleHorizontalBold /> Copy link
          </Button> */}
          <CustomButton label='Rename' onClick={handleRename} disabled={selectCounter > 1 || selectCounter === 0} color={(selectCounter > 1 || selectCounter === 0) ? "#BDBDBD" : ""} icon={<CgRename />}>
           Rename
          </CustomButton>
          {/* <Button onClick={handleRename} disabled={selectCounter > 1 || selectCounter === 0 } color={(selectCounter > 1 || selectCounter === 0) ? "#BDBDBD" : ""} gap={"7px"} colorScheme="black.500" variant="ghost">
            <CgRename /> Rename
          </Button> */}

          {/* <Popover isOpen={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
            <PopoverTrigger>

              <Button onClick={() => setIsPopoverOpen(true)} gap={"7px"} colorScheme="black.500" variant="ghost" display={"flex"} flexDir={{md:"column", lg:"row"}}>
                <MdOutlinePrivacyTip /><Text m={"0px"} display={{base:"none",md:"block"}}>Scope</Text>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody display={"flex"} alignItems={"center"} justifyContent={"space-around"} gap={"8px"}>
                {
                  isScopeLoading ? <Skeleton height="17px" width="100px"></Skeleton> : <Text onClick={()=>handleChangeScope(1)} cursor={"pointer"} as={"span"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"5px"} color={selectCounter === 0 ? "#BDBDBD" : ""}> <IoLockOpenOutline/> Public</Text>
                }
                {
                  isScopeLoading ? <Skeleton height="17px" width="100px"></Skeleton> : <Text onClick={()=>handleChangeScope(0)} cursor={"pointer"} as={"span"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"5px"} color={selectCounter === 0 ? "#BDBDBD" : ""}> <HiOutlineLockClosed/> Private</Text>
                }
              </PopoverBody>
            </PopoverContent>
          </Popover> */}
          {
           isDeleting ? <Skeleton height="17px" width="100px"></Skeleton> :
           <CustomButton label='Delete' onClick={handleDelteImage} color={"red"} icon={<RiDeleteBinLine />}>
            Delete
          </CustomButton>
          // <Button
          //   color={"red"}
          //   gap={"7px"}
          //   colorScheme="black.500"
          //   variant="ghost"
          //   onClick={handleDelteImage}
          // >
          //   <RiDeleteBinLine /> Delete
          // </Button>
          }
          {/* <Button gap={"7px"} colorScheme="black.500" variant="ghost">
            <MdOutlineDriveFolderUpload /> Move to
          </Button> */}
          {
            isLoading ? <Skeleton height="17px" width="100px"></Skeleton> :
            <CustomButton label='Add to Favorite' onClick={handleAddToFav} disabled={selectCounter === 0 || isLoading} color={selectCounter === 0 ? "#BDBDBD" : ""} icon={<FaRegHeart />}>
             Add to Favorite
            </CustomButton>
          // <Button disabled={selectCounter === 0 || isLoading} color={selectCounter === 0 ? "#BDBDBD" : ""} gap={"7px"} colorScheme="black.500" variant="ghost" onClick={handleAddToFav}>
          //   <GoStar /> Add to Favorite
          // </Button>
          }
          {/*<Button colorScheme="black.500" variant="ghost">
            <LuUsers />
          </Button>*/}
          <CustomButton label='Select All' onClick={handleSelectAll} icon={<IoMdDoneAll />}>
          {totalUploadedFile?.length === selectedImage?.length && totalUploadedFile?.length !==0 ? "Unselect All" : "Select All"}
          </CustomButton>
          {/* <Button gap={"7px"} colorScheme="black.500" variant="ghost" onClick={handleSelectAll}>
            <IoMdDoneAll/> Select All
          </Button> */}
          <CustomButton label='Toggle View' onClick={handleToggleView} icon={!toggleView ? <PiSquaresFourLight fontSize={"25px"} /> : <AiOutlineUnorderedList fontSize={"25px"} />}>
           <Text display={"none"}>Toggle View</Text>
           </CustomButton>
          {/* <Button
            onClick={handleToggleView}
            gap={"7px"}
            colorScheme="black.500"
            variant="ghost"
          >
            {!toggleView ? (
              <PiSquaresFourLight fontSize={"25px"} />
            ) : (
              <AiOutlineUnorderedList fontSize={"25px"} />
            )}
          </Button> */}
    
          {selectCounter > 0 && (
            <Button
              borderRadius={"50px"}
              border={"1px solid grey"}
              gap={"8px"}
              colorScheme="black.500"
              variant="ghost"
              onClick={onClearSelection}
              size={"sm"}
            >
              <RxCross1 /> {selectCounter || 0} selected
            </Button>
          )}
        </Flex>
        {currentRoute === "/layout/users" && (
          <NavLink to="/layout">
            <Button
              width="42px"
              height="42px"
              borderRadius="52px"
              bgGradient="linear(to-r, #7ED0D8, #F90A8B)"
              color={"#FFFFFF"}
              _hover={{
                color: "#FFFFFF",
              }}
              fontSize={"25px"}
            >
              <RxCross1 />
            </Button>
          </NavLink>
        )}
      </Flex>
      
      {!(selectCounter > 1 || selectCounter === 0 && (!!selectedImage)) && <RenameModal isOpen={isRenameModalOpen} onOpen={onRenameModalOpen} selectedImage={selectedImage} onClose={onRenameModalClose} handleReFetch={handleReFetch}/>}

       <Filter setUploadedFile={setUploadedFile} isFilterDrawerOpen={isFilterDrawerOpen} onFilterDrawerClose={onFilterDrawerClose}/>

       {/* <TagSearch isTagSearchDrawerOpen={isTagSearchDrawerOpen} onTagSearchDrawerClose={onTagSearchDrawerClose}/> */}
      </div>
  );
};

export default UploadMenu;
